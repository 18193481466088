import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  pendingContainer: {
    marginTop: '4em',
    marginBottom: '4em',
  },
  mt1: {
    marginTop: '1em',
  },
  boxPadding: {
    padding: '1em 1em',
  },
  profileImage: {
    width: '60%',
    borderRadius: '50%',
  },
  nameText: {
    fontWeight: 'bolder',
  },
  dateText: {
    color: ' rgba(70, 79, 208, 1)',
  },
  header: {
    fontWeight: 'bolder',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
  },
  deleteButton: {
    border: '1px solid #E33D59',
    color: '#E33D59',
    borderRadius: '5px',
    padding: '0.6em 3em',
  },
}));
