import { useSelector } from "react-redux";
import { selectUser } from "redux/auth/selectors";
import { Message, User } from "types";
type Props = {
    message: Message;
};
const DontWantToSendMessage = ({ message }: Props) => {
    const user: User | null = useSelector(selectUser);
    if (message.sender === "SYSTEM") return null;
    return (
        <div>
            <span className="body-17 font-weight-bolder color-gulf-blue">
                {user?.id===message.sender.id ? "You" :message.sender.firstName} chose not to send a follow-up message. Thank you for your time.
            </span>
        </div>
    )
}
export default DontWantToSendMessage;