import React from 'react';
import { Grid, Toolbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';

const SecondaryNavbar = ({ orgName }: { orgName: string }) => {
  const classes = useStyles();

  return (
    <Toolbar className={classes.toolbar}>
      <Grid item sm={12}>
        <Typography variant='subtitle1' className={classes.TypographyText}>
          {orgName ? orgName : ''}
        </Typography>
      </Grid>
    </Toolbar>
  );
};

export default SecondaryNavbar;

const useStyles = makeStyles(() => ({
  toolbar: {
    backgroundColor: '#0084AA',
  },
  TypographyText: {
    color: '#fff',
    fontWeight: 'bolder',
    paddingLeft: '2em',
  },
}));
