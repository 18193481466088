import { call, put, takeLatest } from 'redux-saga/effects';
import { showToast, ToastMessage, ToastType } from 'app/components/Toast/slice';
import { UserType } from 'types';
import firebase from 'utils/firebase';

import { setOnLoginRedirectRoute, login, logout, setIsDashboardLoading } from './slice';
import { performOnLoginSync } from './utils';

const ERROR_MESSAGES_FIREBASE: any = {
  'auth/invalid-email': 'Please enter a valid email address',
  'auth/user-not-found':
    "We couldn't find a user with that email address. Please create an account.",
  'auth/wrong-password': 'The email/password entered was incorrect. Please try again.',
  'auth/PC-not-authorized':'Your account is not registered. Please contact Jamie Pitts to gain access to the Social Mobility Network Dashboards',
};

function* doLogin(action: any) {
  const { email, password } = action.payload;
  try {
    yield put(setIsDashboardLoading(true));
    yield firebase.auth().signInWithEmailAndPassword(email, password);
  } catch (error: any) {
    const toast: ToastMessage = {
      type: ToastType.ERROR,
      message: error.code === 'auth/user-not-found' ? email.split('@')[1] === 'upreach.org.uk' ? ERROR_MESSAGES_FIREBASE['auth/PC-not-authorized'] : ERROR_MESSAGES_FIREBASE[error.code] : ERROR_MESSAGES_FIREBASE[error.code], 
    };
    yield put(showToast(toast));
  } finally {
    yield put(setIsDashboardLoading(false));
  }
}

function* resetPassword(action: any) {
  try {
    const { email } = action.payload;
    var actionCodeSettings = {
      url: `${process.env.REACT_APP_SELF_URL}/login`,
    };
    const auth = firebase.auth();
    yield auth.sendPasswordResetEmail(email, actionCodeSettings);
    const toast: ToastMessage = {
      type: ToastType.SUCCESS,
      message: 'Reset link has been successfully sent.',
    };
    yield put(showToast(toast));
  } catch (error) {
    const toast: ToastMessage = {
      type: ToastType.ERROR,
      message: 'Failed to send the reset link. Please try again.',
    };
    yield put(showToast(toast));
  }
}

function* doLogout() {
  yield firebase.auth().signOut();
}

function* triggerOnLoginSync(action: any): any {
  try {
    const response = yield call(performOnLoginSync, action.payload.id, action.payload.email);
    if (response?.userProfile) {
      const userRoles: string = response.userProfile.roles || [];
      const isValidUser =
        userRoles.includes(UserType.EMPLOYER) ||
        userRoles.includes(UserType.UPREACH_ADMIN) ||
        userRoles.includes(UserType.UNIVERSITY_ADMIN) ||
        userRoles.includes(UserType.PROGRAM_COORDINATOR);

      if (isValidUser) {
        yield put(login({ ...response.userProfile }));
        yield put(setOnLoginRedirectRoute('/home'));

        const toast: ToastMessage = {
          type: ToastType.SUCCESS,
          message: 'Logged in successfully',
        };
        yield put(showToast(toast));
      } else throw new Error('Error setting the user');
    } else if (response?.emailRecognized) {
      yield doLogout();
      yield put(setOnLoginRedirectRoute('/login'));
    } else {
      throw new Error('Error setting the user');
    }
  } catch (error) {
    yield put(logout());
    yield firebase.auth().signOut();
    yield put(setOnLoginRedirectRoute(null));
    const toast: ToastMessage = {
      message: `We couldn't find a user with that email address.`,
      type: ToastType.ERROR,
    };
    yield put(showToast(toast));
  } finally {
    yield put(setIsDashboardLoading(false));
  }
}

export default function* authSaga() {
  yield takeLatest('LOGIN_ATTEMPT', doLogin);
  yield takeLatest('LOGOUT_ATTEMPT', doLogout);
  yield takeLatest('RESET_PASSWORD', resetPassword);
  yield takeLatest('TRIGGER_ON_LOGIN_SYNC', triggerOnLoginSync);
}
