import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  tableReplaceGrid: {
    height: "60vh",
    width: "100%",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
  noRecords: {
    marginTop: "6em",
  },
  error: {
    marginLeft: "45%",
    marginTop: "10%",
  },
  mt5: {
    marginTop: "5em",
  },
  mb: {
    marginBottom: "3em",
  },
  back: {
    textDecoration: "none !important",
    fontWeight: "bolder",
    marginTop: "2em",
  },
}));
