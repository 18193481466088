import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TextField } from 'app/components';
import Button from '@mui/material/Button';
import { useReactQuery } from 'utils/hooks/useReactQuery';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Skeleton } from '@mui/material';
import getAxiosInstance from 'utils/axios';
import { showToast, ToastMessage, ToastType } from 'app/components/Toast/slice';
import { InputType } from 'app/components/TextField';

const tableHeadings = [
    "Sr. No",
    "COMPANY NAME",
    "EMPLOYER QUOTA LIMIT",
]

const EditEmployerLimitTable = () => {

    const [employerLimit, setEmployerLimit] = useState<number[]>([]);
    const [disableTextField, setDisaleTextField] = useState<boolean[]>([]);
    const dispatch = useDispatch();
    const classes: any = useStyles();

    const { data, isLoading, error } = useReactQuery(
        ['allEmployers'],
        {
            url: '/dashboard/fetchEmployers',
        },
    );

    useEffect(() => {
        if (data) {
            const disableTextFieldArray: boolean[] = [];
            const employerQuotaLimit: number[] = [];

            data.allEmployers.forEach((el: any) => {
                employerQuotaLimit.push(el?.metadata?.professionalInviteLimit);
                disableTextFieldArray.push(true);
            })

            setEmployerLimit(employerQuotaLimit);
            setDisaleTextField(disableTextFieldArray);
        }
    }, [data])

    const changeEmployerLimit = (val: number, index: number) => {
        if (val < 0) return;
        let employerquotaLimit = [...employerLimit];
        employerquotaLimit[index] = val;
        setEmployerLimit(employerquotaLimit);
    }

    const handleEnablingTextField = (index: number) => {
        const enableTextFieldArray = [...disableTextField];
        enableTextFieldArray[index] = false;
        setDisaleTextField(enableTextFieldArray);
    }

    const handleEditEmployerLimit = async (id: string, i: number) => {
        try {

            const disableTextFieldArray = [...disableTextField];
            disableTextFieldArray[i] = true;
            setDisaleTextField(disableTextFieldArray);

            const axios = await getAxiosInstance();
            const response = await axios.post('/dashboard/updateEmployerInviteLimit', {
                employerId: id,
                newEmployerLimit: employerLimit[i]
            });
            if (response.status === 200) {
                const toast: ToastMessage = {
                    type: ToastType.SUCCESS,
                    message: response.data.message,
                };
                dispatch(showToast(toast));
            }
        } catch (error: any) {
            const toast: ToastMessage = {
                type: ToastType.ERROR,
                message: error?.response?.data?.message,
            };
            dispatch(showToast(toast));
        }
    }

    if (error)
        return (
            <Typography variant='h6' className={classes.error}>
                Error fetching records
            </Typography>
        );

    return (
        <TableContainer className={classes.referralTableContainer} component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {tableHeadings.map((heading, i) => (
                            <TableCell className={classes.tableHead} key={i} align="center">{heading}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading && [1, 2, 3, 4].map((el) => (
                        <TableRow
                            key={el}
                        >
                            <TableCell align="center">
                                <Skeleton variant="rectangular" />
                            </TableCell>
                            <TableCell align="center">
                                <Skeleton variant="rectangular" />
                            </TableCell>
                            <TableCell align="center">
                                <Skeleton variant="rectangular" />
                            </TableCell>
                        </TableRow>
                    ))}
                    {data && data.allEmployers.map((employer: any, i: number) => (
                        <TableRow
                            className={classes.tableRowForUsers}
                            key={employer.id}
                        >
                            <TableCell align="center">
                                <p className={classes.paragraphMargin}>{i + 1}</p>
                            </TableCell>
                            <TableCell align="center">
                                <p className={classes.paragraphMargin}>{employer.name}</p>
                            </TableCell>
                            <TableCell align="center" className={classes.btnDiv} >
                                <TextField
                                    type={InputType.NUMBER}
                                    fullWidth={false}
                                    size='small'
                                    disable={disableTextField[i]}
                                    value={employerLimit[i]}
                                    onChange={(e) => changeEmployerLimit(parseInt(e.target.value), i)}
                                />
                                <Button className={`${classes.editbtns} ${classes.btns__colored}`} onClick={() => handleEnablingTextField(i)} >Edit</Button>
                                <Button className={classes.savebtns} color='primary' variant='contained' onClick={() => { handleEditEmployerLimit(employer.id, i) }} >Save</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default EditEmployerLimitTable


const useStyles = makeStyles(() => ({
    editbtns: {
        marginTop: '30px',
        color : '#3f51b5'
    },
    savebtns: {
        marginTop: '30px',
        color: 'white',
        backgroundColor: '#3f51b5',
    },
    addEmployerButton: {
        marginTop: '30px',
        marginLeft: '50px',
        display: 'flex',
        justifyContent: 'center'
        
    }
}));
