import { Dispatch, SetStateAction, useEffect, useState, useRef } from 'react';
import { useReactQuery } from 'utils/hooks/useReactQuery';
import { Filter, Pagination } from 'app/components';
import NestedFilter from 'app/components/NestedFilter';
import {
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Switch,
  Button,
  SelectChangeEvent,
} from '@mui/material';
import useDebounce from 'utils/hooks/useDebounce';
import { TextField } from 'app/components';
import AssociatesTable from './AssociatesTable';
import getAxiosInstance from 'utils/axios';
import { University, User, UserType } from 'types';
import OverviewChart from 'app/containers/HomePage/OverviewChart';
import { useStyles } from './style';
import { sortColumnToAlgoliaFieldMapping } from 'utils/constants/sortColumnToAlgoliaFieldMapping';
import InteractionDetailCard from '../AlumniStudentInteractionDetailCard';
import { selectUser } from 'redux/auth/selectors';
import { useSelector } from 'react-redux';

import { CSVLink } from 'react-csv';
import { CSVDataType, DashboardDataType } from './types';
import { AiOutlineDownload } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { showToast, ToastType } from 'app/components/Toast/slice';
import { format } from 'date-fns';
type PropsType = {
  prevTabfilter: string;
  setPrevTabFilter: Dispatch<SetStateAction<string>>;
};

const AllAssociates = ({ prevTabfilter, setPrevTabFilter }: PropsType) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [query, setQuery] = useState<string>('');
  const [universities, setUniversities] = useState<University[]>([]);
  const [selectedUniversity, setSelectedUniversity] = useState<any>('');
  const [initialDataLoading, setInitialDataLoading] = useState<boolean>(false);
  const [chartData, setChartData] = useState<any>();
  const [sortColumn, setSortColumn] = useState('NAME');
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedPageSize, setSelectedPageSize] = useState<any>(10);
  const [programTypeLoading, setProgramTypeLoading] = useState<boolean>(true);
  const [programSubTypes, setProgramSubTypes] = useState<any>([]);
  const [programSelectedCount, setProgramSelectedCount] = useState<number>(0);
  const [programTypeFilterString, setProgramTypeFilterString] = useState<string>('');
  const [toggleFeedbackOption, setToggleFeedbackOption] = useState<boolean>(false);
  const [prevToggleFeedbackOption, setPrevToggleFeedbackOption] = useState<boolean>(false);
  const [showToggleSaveBtn, setShowToggleSaveBtn] = useState<boolean>(false);
  const [toggleFeedbackBtnText, setToggleFeedbackBtnText] = useState<string>('Save');
  const [associateStatus , setAssociateStatus] = useState<any>([{"id":"Associate", "name":"upReach Associate"},{"id":"On going", "name":"Not an upReach Associate"}])
  const [associateSelectedCount, setAssociateSelectedCount] = useState<number>(0);
  const [associateTypeFilterString, setAssociateTypeFilterString] = useState<string>('');
  const debouncedQuery = useDebounce(query, 300);
  const user: User | null = useSelector(selectUser);
  const classes = useStyles();

  const csvButtonRef: any = useRef();
  const dispatch = useDispatch();
  const [csvData, setCSVData] = useState<CSVDataType[]>([]);
  useEffect(() => {
    fetchAllUniversities();
    const tempUniversity = prevTabfilter ? { name: prevTabfilter } : '';
    setAssociateStatus([{"id":"Associate", "name":"upReach Associate"},{"id":"On going", "name":"Not an upReach Associate"}]);
    setSelectedUniversity(tempUniversity);
    setCurrentPage(1);
    setPrevTabFilter('');
    getAssociateFeedbackOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
        // getting values from session storage
        const getUniversityDetail = sessionStorage.getItem('selectedUniversity');
        getUniversityDetail && setUniversityDetail(JSON.parse(getUniversityDetail));

        const getSubType = sessionStorage.getItem('changedProgramType');        
        getSubType && setProgramSubTypes(JSON.parse(getSubType));

        const getAssociateSubType = sessionStorage.getItem('changedAssociateType');
        getAssociateSubType && setAssociateStatus(JSON.parse(getAssociateSubType))
  }, [prevTabfilter,setPrevTabFilter]);

  useEffect(() => {
    (async () =>
      selectedUniversity?.name
        ? await getChartData(selectedUniversity?.name)
        : await getChartData(prevTabfilter))();
  }, [selectedUniversity, prevTabfilter]);

  const { data, isLoading, error } = useReactQuery(
    [
      'allAssociates',
      currentPage,
      debouncedQuery,
      selectedUniversity,
      sortColumn,
      sortOrder,
      selectedPageSize,
      programTypeFilterString,
      associateTypeFilterString,
    ],
    {
      url: '/dashboard/fetchStudents',
      params: {
        query: debouncedQuery,
        pageNo: currentPage,
        pageSize: selectedPageSize,
        filters:
          (selectedUniversity?.name && associateTypeFilterString==='' && programTypeFilterString==='')
            ? `(university.name:"${selectedUniversity.name}")`
            : (selectedUniversity?.name && associateTypeFilterString !=='' && programTypeFilterString==='')
            ? `(university.name:"${selectedUniversity.name}") AND ${associateTypeFilterString}`
            : (selectedUniversity?.name && associateTypeFilterString ==='' && programTypeFilterString!=='')
            ? `(university.name:"${selectedUniversity.name}") AND ${programTypeFilterString}`
            : (selectedUniversity?.name && associateTypeFilterString !=='' && programTypeFilterString!=='')
            ? `(university.name:"${selectedUniversity.name}") AND ${associateTypeFilterString} AND ${programTypeFilterString}`
            : (selectedUniversity ==='' && associateTypeFilterString !=='' && programTypeFilterString!=='')
            ? `${associateTypeFilterString} AND ${programTypeFilterString}`
            : (associateTypeFilterString ==='' && programTypeFilterString!=='')
            ? `${programTypeFilterString}`
            : `${associateTypeFilterString}`,
        sortColumn: sortColumnToAlgoliaFieldMapping[sortColumn as string],
        sortOrder: sortOrder.toUpperCase(),
      },
    },
  );

  const csvdata: DashboardDataType[] = data?.completeData;
  const handleDownloadCSVClicked = async () => {
    try {
     let CSVData: CSVDataType[] = [];
      csvdata.forEach((csv: DashboardDataType) => {
        CSVData.push({
          'Email': csv.email,
          'First Name': csv.firstName,
          'Last Name': csv.lastName,
          'Upreach Associate':csv.associateStatus && csv.associateStatus === 'On going' ? 'No' : "Yes",
          'Professionals Engaged': csv.engagedUsers,
          'University Name': csv.university,
          'createdAt/_seconds':csv.createdAt && csv?.createdAt?._seconds ? format(new Date(csv?.createdAt?._seconds * 1000), 'dd.MM.yyyy'):'--',
          'lastInteractionDate/_seconds': csv.lastInteractionDate && csv?.lastInteractionDate?._seconds ? format(new Date(csv?.lastInteractionDate?._seconds * 1000), 'dd.MM.yyyy')
          : '--', 
          'interactionStateInfo/TOTAL': csv.interactionStateInfo.TOTAL,
        })
      })

      setCSVData((prevData: CSVDataType[]) => {
        setTimeout(() => {
          csvButtonRef.current.link.click();
          dispatch(
            showToast({
              type: ToastType.SUCCESS,
              message: 'CSV Downloaded Successfully',
            }),
          );
        }, 500);
        return CSVData;
      });
    } catch (error) {
      console.log(error);
      dispatch(
        showToast({
          type: ToastType.ERROR,
          message: 'Error occurred while downloading csv',
        }),
      );
    }
  };

  const fetchAllUniversities = async () => {
    try {
      const axios = await getAxiosInstance();
      const { data: universitiesData } = await axios.get('/dashboard/fetchUniversities');
      setUniversities(universitiesData.allUniversities);
    } catch (error) {
      console.log(error);
    }
  };

  const getChartData = async (universityName: string) => {
    const axios = await getAxiosInstance();

    try {
      setInitialDataLoading(true);
      const { data } = await axios.get('/dashboard/getChartData', {
        params: {
          orgType: 'UNIVERSITY',
          universityName,
        },
      });
      setChartData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setInitialDataLoading(false);
    }
  };

  useEffect(() => {
    async function editProgramTypes() {
      setProgramTypeLoading(true);
      const allProgramTypes = await getProgramTypes();
      if (allProgramTypes) {
        const filteredProgramTypes = getProgramCategories(allProgramTypes);
        getProgramSubCategories(allProgramTypes, filteredProgramTypes);
        setProgramTypeLoading(false);
      }
      
      const getAssociateSubType = sessionStorage.getItem('changedAssociateType');
      if(getAssociateSubType){
      const ab = getAssociateSubType && JSON.parse(getAssociateSubType);
      changeAssociateFilterString(ab);
      }

    }
    editProgramTypes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClearFilters = () => {
    setSelectedUniversity('');
    setProgramTypeFilterString('');
    setAssociateTypeFilterString('');
    setProgramSelectedCount(0);
    setAssociateSelectedCount(0);
    //Session clear on individual filter
    sessionStorage.removeItem("selectedUniversity");
    sessionStorage.removeItem("changedProgramType");
    sessionStorage.removeItem("changedAssociateType");
    const clearProgramTypes = [...programSubTypes];
    clearProgramTypes.forEach((program) => {
      program.checked = false;
    });
    setProgramSubTypes(clearProgramTypes);

    const clearAssociateTypes = [...associateStatus];
    clearAssociateTypes.forEach((program) => {
      program.checked = false;
    });
    setAssociateStatus(clearAssociateTypes);
  };

  const getProgramTypes = async () => {
    const axios = await getAxiosInstance();

    try {
      setInitialDataLoading(true);
      const { data } = await axios.get('/dashboard/fetchProgramTypes');
      return data?.allProgramTypes;
    } catch (error) {
      console.log(error);
    } finally {
      setInitialDataLoading(false);
    }
  };

  const getAssociateFeedbackOption = async () => {
    const axios = await getAxiosInstance();

    try {
      const { data } = await axios.get('/dashboard/fetchFeedbackOptionSettings');
      setToggleFeedbackOption(data && data.data['TOGGLE_FEEDBACK_OPTION']);
      setPrevToggleFeedbackOption(data && data.data['TOGGLE_FEEDBACK_OPTION']);
      setToggleFeedbackBtnText('Save');
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveToggleFeedbackBtn = async () => {
    const axios = await getAxiosInstance();

    try {
      setToggleFeedbackBtnText('Saving...');
      await axios.post('/dashboard/toggleFeedbackOptionSettings', {
        showFeedbackOption: toggleFeedbackOption,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setToggleFeedbackBtnText('Saved');
      setTimeout(() => {
        setShowToggleSaveBtn(false);
        getAssociateFeedbackOption();
      }, 2000);
    }
  };

  const handleToggleFeedbackOptionChange = (currentValue: boolean) => {
    setToggleFeedbackOption(currentValue);
    if (prevToggleFeedbackOption === currentValue) setShowToggleSaveBtn(false);
    else setShowToggleSaveBtn(true);
  };

  const getProgramCategories = (allProgramTypes: string[]) => {
    let programCategories: string[] = [];
    allProgramTypes.forEach((program: any) => {
      const programKeys = Object.keys(program);
      programCategories = [...programCategories, ...programKeys];
    });
    const filteredProgramTypes = programCategories.filter((category) => category !== 'id');
    const programTypes = filteredProgramTypes?.map((type: string) => {
      return {
        name: type,
        checked: false,
      };
    });
    return programTypes;
  };

  const getProgramSubCategories = (allProgramTypes: any[], programTypes: any[]) => {
    const programSubCategories: any[] = [];
    allProgramTypes.forEach((program: any, idx: number) => {
      program[programTypes[idx].name]?.forEach((type: string) => {
        programSubCategories.push({
          name: type,
          checked: false,
        });
      });
    });
    programSubCategories.sort((a, b) => a.name.localeCompare(b.name));
    setProgramSubTypes(programSubCategories);
    
    const getSubType = sessionStorage.getItem('changedProgramType');
    if(getSubType){
      setProgramSubTypes(JSON.parse(getSubType));
      changeProgramFilterString(JSON.parse(getSubType));
    } else{
      setProgramSubTypes(programSubCategories);
    }

  };

  const handleProgramTypeSelected = (idx: number) => {
    const newProgramSubTypes = [...programSubTypes];
    const changedProgramType = { ...programSubTypes[idx] };
    const prevValue = changedProgramType.checked;
    changedProgramType.checked = !prevValue;
    newProgramSubTypes[idx] = changedProgramType;
    setProgramSubTypes(newProgramSubTypes);
    changeProgramFilterString(newProgramSubTypes);
    setSubtype(newProgramSubTypes);
  };

  const changeProgramFilterString = (programSubCategories: any[]) => {
    const filterStringsArr: string[] = [];
    let selectedCount = 0;
    programSubCategories.forEach((category) => {
      if (category.checked) {
        selectedCount += 1;
        filterStringsArr.push(`programType:"${category.name}"`);
      }

    });

    setProgramSelectedCount(selectedCount);
    const filterString = filterStringsArr.join(' OR ');
    setProgramTypeFilterString(filterString);
  };

  const handleAssociateTypeSelected = (idx: number) => {
    const newAssociateSubTypes = [...associateStatus];
    const changedAssociateType = { ...associateStatus[idx] };
    const prevValue = changedAssociateType.checked;
    changedAssociateType.checked = !prevValue;
    newAssociateSubTypes[idx] = changedAssociateType;
    setAssociateStatus(newAssociateSubTypes);
    changeAssociateFilterString(newAssociateSubTypes);
    setAssociateSubType(newAssociateSubTypes);
  };

  const changeAssociateFilterString = (associateSubCategories: any[]) => {
    const filterStringsArr: string[] = [];
    let selectedCount = 0;
    associateSubCategories.forEach((category) => {
      if (category.checked) {
        selectedCount += 1;
        filterStringsArr.push(`associateStatus:"${category.id}"`);
      }
    });
    setAssociateSelectedCount(selectedCount);
    const filterString = filterStringsArr.join(' OR ');
    setAssociateTypeFilterString(filterString);
  };

  const handlePageSizeClick = (event: SelectChangeEvent<string>) => {
    setSelectedPageSize(event.target.value as string);
  };

  const handlePageChange = (_event: React.ChangeEvent<unknown>, pageNo: number) => {
    setCurrentPage(pageNo);
  };

  const handleSorting = (column: string) => {
    if (sortColumn !== column) {
      setSortColumn(column);
      setSortOrder('asc');
    } else {
      const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
      setSortOrder(newSortOrder);
    }
  };

  // setting values to session storage for filter
    const setUniversityDetail = (university : any) => {
      sessionStorage.setItem('selectedUniversity',JSON.stringify(university))
      setSelectedUniversity(university)
    }
    const setSubtype = (changedProgramType: any) => {
      sessionStorage.setItem('changedProgramType', JSON.stringify(changedProgramType))
      setProgramSubTypes(changedProgramType);
    }

    const setAssociateSubType = (changedAssociateType: any) => {
      sessionStorage.setItem('changedAssociateType', JSON.stringify(changedAssociateType))
      setAssociateStatus(changedAssociateType);
    }
  if (error)
    return (
      <Typography variant='h6' className={classes.error}>
        Error fetching records
      </Typography>
    );

  return (
    <Grid item xs={10}>
      <Grid container style={{marginTop:"20px"}}>
        <Grid item xs={6}>
          <NestedFilter
            filterTitle = {"Filter students by"}
            placeholder={"Associate Status"}
            options={associateStatus}
            selectedCount={associateSelectedCount}
            handleOptionChange={(idx: number, itemText: string) => handleAssociateTypeSelected(idx)}
          />
          <Filter
            placeholder={'University'}
            // filterTitle='Filter students by'
            filterTitle='And / Or'
            options={universities}
            value={selectedUniversity} 
            onChange={(university) => (university ? setUniversityDetail(university) : undefined)}
            onClear={() => handleClearFilters()}
          />
          {programTypeLoading ? (
            <CircularProgress className='no-user-text'/>
          ) : (
            <div>
              <NestedFilter
                filterTitle={"And / Or"}
                placeholder={"Programme Type"}
                options={programSubTypes}
                selectedCount={programSelectedCount}
                handleOptionChange={(idx: number, itemText: string) => handleProgramTypeSelected(idx)}
              />
            </div>
          )}
          <TextField
            onChange={(e) => setQuery(e.target.value)}
            value={query}
            label={`Search Students`}
          />
        </Grid>
        <Grid item xs={6}>
          {data?.interactionNumbers && (
            <InteractionDetailCard interactionData={data?.interactionNumbers} />
          )}
        </Grid>
      </Grid>
      {!isLoading && !initialDataLoading && chartData?.chartDataset && (
        <Grid item xs={12} className={classes.mt2}>
          <OverviewChart chartData={chartData?.chartDataset} />
        </Grid>
      )}
      {(isLoading || initialDataLoading) && (
        <Grid container className={classes.tableReplaceGrid} item xs={12}>
          <Grid item xs={1}>
            <CircularProgress className='no-user-text'/>
          </Grid>
        </Grid>
      )}

      <div className={classes.toggleWrapper}>
        <div className={classes.recordsDiv}>
          <InputLabel id='demo-simple-select-outlined-label'>
            Number of records to display
          </InputLabel>
          <Select
            className={classes.pageSizeWidth}
            variant='outlined'
            labelId='demo-simple-select-outlined-label'
            id='demo-simple-select-outlined'
            defaultValue={selectedPageSize}
            onChange={handlePageSizeClick}
          >
            {[10, 25, 50, 75, 100].map((value) => (
              <MenuItem value={value}>{value}</MenuItem>
            ))}
          </Select>
        </div>
        {user?.roles?.includes(UserType.UPREACH_ADMIN) && (
          <div className={classes.toggleDiv}>
            <Typography variant='h6' align='center' className={classes.toggleHeading}>
              Show Feedback option on Associates profile ?
            </Typography>
            <Switch
              checked={toggleFeedbackOption}
              onChange={(e) => handleToggleFeedbackOptionChange(e.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
              color='primary'
            />
            {showToggleSaveBtn && (
              <Button color='primary' variant='outlined' className = "save_btn"onClick={handleSaveToggleFeedbackBtn}>
                {toggleFeedbackBtnText}
              </Button>
            )}
          </div>
        )}
      </div>
      {!isLoading &&
        !initialDataLoading &&
        data &&
        data.dashboardData &&
        data.dashboardData.length !== 0 && (
          <>
            <Grid container item xs={12} justifyContent='flex-end' >
              <CSVLink
                style={{ visibility: 'hidden' }}
                data={csvData}
                filename={'allAssociates.csv'}
                ref={csvButtonRef}
              ></CSVLink>
                  <Button className={classes.csvButton} onClick={handleDownloadCSVClicked}>
                    <AiOutlineDownload className={classes.downloadIcon} /> Download as CSV
                  </Button>
            </Grid>
            <Grid item xs={12} className={classes.mt3}>
              <AssociatesTable
                tableData={data ? data.dashboardData : []}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
                onSort={handleSorting}
              />
            </Grid>
            <Pagination
              onChange={handlePageChange}
              page={currentPage}
              count={data.noOfPagesAvailable}
            />
          </>
        )}

      {!isLoading && data && data.dashboardData && data.dashboardData.length === 0 && (
        <Grid item xs={12}>
          <Typography variant='h6' className={classes.noRecords} align='center'>
            {' '}
            No records present
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default AllAssociates;
