import { Dispatch, SetStateAction, useEffect, useState, useRef } from 'react';
import { useReactQuery } from 'utils/hooks/useReactQuery';
import makeStyles from '@mui/styles/makeStyles';
import { Pagination } from 'app/components';
import { CircularProgress, Grid, Typography } from '@mui/material';
import TopEngagedTable from '../../TopEngaged/TopEngagedTable';
import { UserType } from 'types';
import useDebounce from 'utils/hooks/useDebounce';
import { TextField } from 'app/components';
import { setActiveTab } from 'redux/navigation/slice';
import { useDispatch } from 'react-redux';
import { sortColumnToAlgoliaFieldMapping } from 'utils/constants/sortColumnToAlgoliaFieldMapping';
import { Button } from '@mui/material';
import { CSVLink } from 'react-csv';
import { CSVDataType, DashboardDataType } from './types';
import { AiOutlineDownload } from 'react-icons/ai';
import { showToast, ToastType } from 'app/components/Toast/slice';

const universityTableHead = [
  'UNIVERSITY NAME',
  'TOTAL INTERACTIONS',
  'NO. OF STUDENTS',
  'AVERAGE INTERACTIONS PER STUDENT',
];

type PropsType = {
  setPrevTabFilter: Dispatch<SetStateAction<string>>;
};

const UniversityOverview = ({ setPrevTabFilter }: PropsType) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [query, setQuery] = useState<string>('');
  const [sortColumn, setSortColumn] = useState('Total');
  const [sortOrder, setSortOrder] = useState('desc');
  const dispatch = useDispatch();
  const debouncedQuery = useDebounce(query, 300);
  const classes = useStyles();

  const csvButtonRef: any = useRef();
  const [csvData, setCSVData] = useState<CSVDataType[]>([]);

  useEffect(() => {
    setCurrentPage(1);
  }, []);

  const { data, isLoading, error } = useReactQuery(
    ['universityOverview', currentPage, debouncedQuery, sortColumn, sortOrder],
    {
      url: '/dashboard/fetchUniversitiesEngaged',
      params: {
        query: debouncedQuery,
        pageNo: currentPage,
        pageSize: 15,
        filters: '',
        forAdmin: true,
        sortColumn: sortColumnToAlgoliaFieldMapping[sortColumn as string],
        sortOrder: sortOrder.toUpperCase(),
      },
    },
  );

  const csvdata: DashboardDataType[] = data?.completeData;
  csvdata?.map((entry,index) => {
    csvdata[index].averageInteractionPerStudent = entry.totalInteractions/entry.totalNoOfStudents;
    if(entry.totalNoOfStudents === 0){
      csvdata[index].averageInteractionPerStudent = 0
    }
    return csvdata;
  });
  const handleDownloadCSVClicked = async () => {
    try {
      let CSVData: CSVDataType[] = [];
      csvdata.forEach((csv: DashboardDataType) => {
        CSVData.push({
          'University Name':csv.university,
          'Total Interaction':csv.totalInteractions,
          'Total No Of Students':csv.totalNoOfStudents,
          'Average Interacton Per Student':csv.averageInteractionPerStudent,
        })
      })
      setCSVData((prevData: CSVDataType[]) => {
        setTimeout(() => {
          csvButtonRef.current.link.click();
          dispatch(
            showToast({
              type: ToastType.SUCCESS,
              message: 'CSV Downloaded Successfully',
            }),
          );
        }, 500);
        return CSVData;
      });
    } catch (error) {
      console.log(error);
      dispatch(
        showToast({
          type: ToastType.ERROR,
          message: 'Error occurred while downloading csv',
        }),
      );
    }
  };

  const handlePageChange = (_event: React.ChangeEvent<unknown>, pageNo: number) => {
    setCurrentPage(pageNo);
  };

  const handleUniversityClicked = (university: string) => {
    setPrevTabFilter(university);
    dispatch(setActiveTab(2));
  };

  const handleSorting = (column: string) => {
    if (sortColumn !== column) {
      setSortColumn(column);
      setSortOrder('asc');
    } else {
      const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
      setSortOrder(newSortOrder);
    }
  };

  if (error)
    return (
      <Typography variant='h6' className={classes.error}>
        Error fetching records
      </Typography>
    );

  return (
    <Grid item xs={10} className={classes.mb}>
      <Grid item xs={3}>
        <TextField
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          value={query}
          label={`Search University`}
        />
      </Grid>
      <Grid item xs={12} className={classes.mt3}>
        {!isLoading  && data && data.dashboardData && data.dashboardData.length > 0 && (
          <>       
            <Grid container item xs={12} justifyContent='flex-end'>
              <CSVLink
                style={{ visibility: 'hidden' }}
                data={csvData}
                filename={'university.csv'}
                ref={csvButtonRef}
              ></CSVLink>
                <Button className={classes.csvButton} onClick={handleDownloadCSVClicked}>
                  <AiOutlineDownload className={classes.downloadIcon} /> Download as CSV
                </Button>
            </Grid>
            <TopEngagedTable
              userRoles={[UserType.EMPLOYER]}
              tableHeadTitles={universityTableHead}
              tableData={data ? data.dashboardData : []}
              showAverageInteractions={true}
              clickHandler={handleUniversityClicked}
              onSort={handleSorting}
            />
          </>
        )}
      </Grid>
      {data && (
        <Pagination
          onChange={handlePageChange}
          page={currentPage}
          count={data.noOfPagesAvailable}
        />
      )}
      {isLoading && (
        <Grid container className={classes.tableReplaceGrid} item xs={12}>
          <Grid item xs={1}>
            <CircularProgress className='no-user-text'/>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default UniversityOverview;

export const useStyles = makeStyles(() => ({
  mt2: {
    marginTop: '2em',
  },
  mt3: {
    marginTop: '3em',
  },
  mb: {
    marginBottom: '3em',
  },
  error: {
    marginLeft: '45%',
    marginTop: '10%',
  },
  tableReplaceGrid: {
    height: '60vh',
    width: '100%',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },

  downloadIcon: {
    fontSize: '1.5em',
    marginRight: '0.2em',
  },
  csvButton: {
    color: '#464FD0',
    textTransform: 'none',
    textDecoration: 'underline',
    fontFamily: 'Nunito Sans',
    fontSize: '1.1em',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  },
}));
