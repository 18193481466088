import { Grid } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { useReactQuery } from 'utils/hooks/useReactQuery';
import { useEffect, useState } from 'react';
import useDebounce from 'utils/hooks/useDebounce';
import { InteractionType } from 'types';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'redux/auth/selectors';
import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Skeleton } from '@mui/material';
import getAxiosInstance from 'utils/axios';
import { useParams } from 'react-router-dom';
import { TextField as ComponentTextField } from 'app/components';
import { showToast, ToastMessage, ToastType } from 'app/components/Toast/slice';

const tableHeadings = ['SELECTION', 'STUDENT DETAILS', 'INTERACTIONS'];

const InteractionTypes = [
  { value: InteractionType.CAREER_CHAT, title: 'Career Chats' },
  { value: InteractionType.EXPERT_ADVICE, title: 'Expert Advices' },
  { value: InteractionType.MENTORING, title: 'Mentorings' },
  { value: InteractionType.MOCK_INTERVIEW, title: 'Mock Interviews' },
];

const MyAssociatesTable = ({ onClose, modalOpen }: any) => {
  // const [currentPage, setCurrentPage] = useState<number>(1);
  // const [query, setQuery] = useState<string>('');
  // const [sortColumn, setSortColumn] = useState('NAME');
  // const [sortOrder, setSortOrder] = useState('asc');
  // const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  // const [selectedPageSize, setSelectedPageSize] = useState<any>(10);
  const [query, setQuery] = useState('');
  const [profile, setProfile] = useState<any>();
  const [rowValues, setRowValues] = useState<Array<any>>([]);
  const [selectedUserData, setSelectedUserData] = useState<Array<any>>([]);
  const [message, setMessage] = useState<string>('');
  const { id } = useParams<Record<string, string | undefined>>();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const debouncedQuery = useDebounce(query, 300);
  const classes: any = useStyles();
  // let selectedUniversity;

  const { data, isLoading } = useReactQuery(
    [
      'allAssociates',
      // currentPage,
      debouncedQuery,
      // selectedUniversity = '',
      // sortColumn,
      // sortOrder,
      // selectedPageSize
    ],
    {
      url: '/dashboard/fetchStudents',
      params: {
        query: debouncedQuery,
        pageNo: 1,
        pageSize: 50,
        filters: `programCoordinatorId:"${user.id}"`,
        sortColumn: 'TOTAL_INTERACTIONS',
        sortOrder: 'ASC',
      },
    },
  );

  function createData(studentDetails: Object, interactionData: Object) {
    return { studentDetails, interactionData };
  }

  const handleCheckboxClick = async (e: any, email: string, name: string) => {
    const checkEmailId = (obj: any) => obj.email === email;
    const checkIfEmailExist = await selectedUserData.some(checkEmailId);
    if (checkIfEmailExist) {
      setSelectedUserData(selectedUserData.filter((userEmail) => userEmail.email !== email));
    } else {
      setSelectedUserData([...selectedUserData, { email, name }]);
    }
  };

  const handleSendReferralClick = async () => {
    try {
      const axios = await getAxiosInstance();
      const response = await axios.post('/dashboard/getReferralData', {
        professionalData: {
          email: profile?.email,
          name: `${profile?.firstName} ${profile?.lastName}`,
          company: profile?.company,
          jobTitle: profile?.jobTitle,
          participatingInteractionTypes: profile?.participatingInteractionTypes,
        },
        message,
        associatesData: selectedUserData,
        pcName: `${user?.firstName} ${user?.lastName}`,
      });
      if (response.status === 200) {
        // for ticket SMN 8 the below code is commented
        // const toast: ToastMessage = {
        //   type: ToastType.SUCCESS,
        //   message: response.data.message,
             modalOpen()
        };
        // dispatch(showToast(toast));
        // }

    } catch (error: any) {
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: error?.response?.data?.message,
      };
      dispatch(showToast(toast));
    }
  };

  useEffect(() => {
    const fetchUserProfile = async (id: string) => {
      const axios = await getAxiosInstance();
      try {
        const { data } = await axios.get(`/users/fetchUserProfile?id=${id}`);
        setProfile({ ...data });
      } catch (error: any) {
        console.log(error);
      }
    };
    if (id) fetchUserProfile(id);
  }, [id]);

  useEffect(() => {
    const convertStudentDataToTableData = async () => {
      const rows: any[] = [];
      await data?.dashboardData?.forEach((user: any) => {
        const studentDetails = {
          name: `${user.firstName} ${user.lastName}`,
          universityName: user.university,
          email: user.email,
          preferredEmail: user?.preferredEmail,
        };
        const interactionData = user.interactionTypeInfo;
        rows.push(createData(studentDetails, interactionData));
      });
      await setRowValues(rows);
    };
    convertStudentDataToTableData();
  }, [data]);

  // to add red atrick
  const Message = <p>Include a message to all Associates selected - you may want to tell them why you have made this referral <span style={{color: "red"}}>*</span></p>
  return <>
    <Grid style={{ marginTop: 0 }}>
      <ComponentTextField
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        value={query}
        label={`Search for an Associate`}
        formStyle={{ margin: '1.2em 0' }}
      />
    </Grid>
    <TableContainer className={classes.referralTableContainer} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeadings.map((heading, i) => (
              <TableCell className={classes.tableHead} key={i} align='center'>
                {heading}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading &&
            [1, 2, 3, 4].map(() => (
              <TableRow>
                <TableCell align='center'>
                  <Skeleton variant="rectangular" />
                </TableCell>
                <TableCell align='center'>
                  <Skeleton variant="rectangular" />
                </TableCell>
                <TableCell align='center'>
                  <Skeleton variant="rectangular" />
                </TableCell>
              </TableRow>
            ))}
          {rowValues.map((user) => (
            <TableRow className={classes.tableRowForUsers} key={user.studentDetails.name}>
              <TableCell align='center'>
                <Checkbox
                  color='primary'
                  onChange={(e) =>
                    handleCheckboxClick(
                      e,
                      user.studentDetails.preferredEmail
                        ? user.studentDetails.preferredEmail
                        : user.studentDetails.email,
                      user.studentDetails.name,
                    )
                  }
                />
              </TableCell>
              <TableCell align='center'>
                <p className={classes.paragraphMargin}>{user.studentDetails.name}</p>
                <p className={classes.paragraphMargin}>{user.studentDetails.universityName}</p>
              </TableCell>
              <TableCell align='center'>
                {InteractionTypes.map((interaction: any) => (
                  <p className={classes.paragraphMargin}>
                    {' '}
                    <span className={classes.interactionValue}>
                      {user.interactionData[interaction.value]}
                    </span>{' '}
                    {interaction.title}{' '}
                  </p>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TextField
        onChange={(e) => {
          setMessage(e.target.value);
        }}
        value={message}
        className={classes.messageInputField}
        fullWidth
        variant='outlined'
        multiline
        rows='6'
        label = {Message}
      />
      <div className={classes.floatingTableRow}>
        <Button color='primary' onClick={onClose} className={classes.buttons} variant='outlined'>
          Cancel
        </Button>
        <Button
          onClick={handleSendReferralClick}
          disabled={selectedUserData.length === 0 || message.length === 0}
          color='primary'
          className={classes.buttons}
          variant='contained'
        >
          Send Referral
        </Button>
      </div>
    </TableContainer>
  </>;
};

export default MyAssociatesTable;

const useStyles = makeStyles(() => ({
  tableHead: {
    fontWeight: 'bold',
  },
  interactionValue: {
    color: '#464FD0',
    fontWeight: 'bold',
  },
  paragraphMargin: {
    marginTop: '5px',
    marginBottom: '5px',
  },
  floatingTableRow: {
    position: 'absolute',
    bottom: '0',
    width: 'calc(100% - 50px)',
    background: 'white',
    boxShadow: '1px -1px 5px 0px rgba(0,0,0,0.75)',
  },
  messageInputField: {
    display: 'block',
    position: 'sticky',
    background: 'white',
    width: 'calc(100% - 50px)',
    margin: '20px 20px 15px 20px',
  },
  buttons: {
    marginTop: '12px',
    marginBottom: '15px',
    margin: '12px 0 15px 20px',
  },
  referralTableContainer: {
    paddingBottom: '60px',
    height: '70vh',
  },
  tableRowForUsers: {
    '&:hover': {
      backgroundColor: 'rgba(63, 81, 181, 0.04)',
    },
  },
}));