import { useSelector } from 'react-redux';
import { selectUser } from 'redux/auth/selectors';
import { UserType } from 'types';

import EntityEngagedTable from '../TopEngaged';

const universityTableHead = ['UNIVERSITY NAME', 'TOTAL INTERACTIONS', 'NO. OF STUDENTS'];

const UniversitiesEngagedTable = () => {
  const user = useSelector(selectUser);

  if (!user) return null;
  return (
    <EntityEngagedTable
      tableHead={universityTableHead}
      backLinkTitle={'TOP UNIVERSITIES ENGAGED'}
      userRoles={[UserType.EMPLOYER]}
      url={'/dashboard/fetchUniversitiesEngaged'}
      organizationType={'COMPANY'}
      organizationId={user.company.id}
    />
  );
};

export default UniversitiesEngagedTable;
