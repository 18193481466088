import React, { Children } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import MaterialTableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import TableDataCell from './TableDataCell';
import { FaSort } from 'react-icons/fa';

type PropsType = {
  tableData: any[];
  sortOrder?: string;
  sortColumn?: string;
  onSort: (c: string) => void;
};

const tableHeadTitles = [
  'NAME',
  'UPREACH ASSOCIATE',
  'UNIVERSITY',
  'PROFESSIONALS ENGAGED',
  'DATE REGISTERED',
  'LAST INTERACTION',
  'TOTAL INTERACTIONS',
];

function TopEngagedTable({ tableData = [], sortColumn, sortOrder, onSort }: PropsType) {
  const classes: any = useStyles();

  return (
    <MaterialTableContainer component={Paper}>
      <Table aria-label='simple table'>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {React.Children.toArray(
              tableHeadTitles.map((title: string, index: number) => (
                <TableCell
                  align={index === 0 ? 'left' : 'center'}
                  className={classes.state}
                  onClick={
                    title === 'NAME' ||
                    title === 'TOTAL INTERACTIONS' ||
                    title === 'DATE REGISTERED'
                      ? () => onSort && onSort(title)
                      : undefined
                  }
                >
                  <strong className='body-13'>{title}</strong>
                  { (title === 'TOTAL INTERACTIONS' || title === 'NAME' || title === 'DATE REGISTERED') && <FaSort />}
                </TableCell>
              )),
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {Children.toArray(
            tableData.map((data: any, index: number) => (
              <TableDataCell index={index} data={data} />
            )),
          )}
        </TableBody>
      </Table>
    </MaterialTableContainer>
  );
}

export default TopEngagedTable;

const useStyles = makeStyles(() => ({
  tableHead: {
    background: '#DEE1FF',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  topTableHeader: {
    '& .MuiTableCell-head': {
      borderBottom: '0px',
      borderRight: '2px solid white',
    },
  },
}));
