import { Avatar, Button, Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import avatar from 'assets/avatar.svg';
import getAxiosInstance from 'utils/axios';
import { useReactQuery } from 'utils/hooks/useReactQuery';
import useDebounce from 'utils/hooks/useDebounce';
import { GenderChoices, UserType } from 'types';
import Loading from 'app/components/Loader';
import InitiateInteraction from './RequestInteraction';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DeactivateModal from './DeactivateModal';
import ReferralSystem from '../ProgramCoordinatorView/Referral/ReferralSystem';
import { selectAuthUser, selectUser } from 'redux/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveInteractionId, setInteractions, setModal } from 'redux/interactions/slice';
import InteractionList from 'app/components/Interaction/InteractionList';
import { selectInteractions } from 'redux/interactions/selectors';
import { sortColumnToAlgoliaFieldMapping } from 'utils/constants/sortColumnToAlgoliaFieldMapping';

const UserProfile = () => {
  const { id } = useParams<Record<string, string | undefined>>();
  const user = useSelector(selectUser);
  const userInteractions = useSelector(selectInteractions);
  let location = useLocation();
  const [profile, setProfile] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [myPcAssociates, setMyPcAssociates] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>('');
  const [isReferralModalOpen, setIsReferralModalOpen] = useState<boolean>(false);
  const urlId = location.pathname.split('/').pop();
  const debouncedQuery = useDebounce('', 300);
  const authUser = useSelector(selectAuthUser);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleInteractionClick = (interactionId: string) => {
    dispatch(setActiveInteractionId(interactionId));
    dispatch(setModal(true));
  };

  useEffect(() => {
    const fetchUserProfile = async (id: string) => {
      const axios = await getAxiosInstance();
      try {
        setIsLoading(true);
        const { data } = await axios.get(`/users/fetchUserProfile?id=${id}`);
        setProfile({ ...data });
      } catch (error: any) {
        setError(error);
        if (error.response.status === 404) setError('User does not exist');
      } finally {
        setIsLoading(false);
      }
    };
    if (id) fetchUserProfile(id);
  }, [id]);

  const { data } = useReactQuery(['myAssociates', 1, debouncedQuery, 'NAME', 'asc', 1000], {
    url: '/dashboard/fetchStudents',
    params: {
      query: debouncedQuery,
      pageNo: 1,
      pageSize: 1000,
      filters: `programCoordinatorId:"${user.id}"`,
      sortColumn: sortColumnToAlgoliaFieldMapping['NAME' as string],
      sortOrder: 'asc'.toUpperCase(),
    },
  });

  useEffect(() => {
    const myAssociates = data?.dashboardData?.map((user: any) => user.id);
    setMyPcAssociates(myAssociates);
  }, [data]);

  const handleModalClose = () => {
    setIsModalOpen(false);
    fetchProfileDetails(id);
  };

  const handleReferralModalClose = () => {
    setIsReferralModalOpen(false);
  };

  useEffect(() => {
    (async () => {
      const axios = await getAxiosInstance();
      const { data } = await axios.get(`/interactions/fetchUserInteractions?id=${id}`);
      dispatch(setInteractions(data));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchProfileDetails = async (id: string | undefined) => {
    const axios = await getAxiosInstance();
    try {
      setIsLoading(true);
      const { data } = await axios.get(`/users/fetchUserProfile?id=${id}`);
      setProfile({ ...data });
    } catch (error: any) {
      setError(error);
      if (error.response.status === 404) setError('User does not exist');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loading />;
  if (error)
    return (
      <Grid container justifyContent='center' className={classes.mt}>
        <Grid item xs={10} alignItems='stretch' justifyContent='flex-start' className={classes.back}>
          <ArrowBackIosIcon fontSize='small' />
          <Button className={classes.backButton} onClick={() => history.goBack()}>
            BACK
          </Button>
        </Grid>
        <p>Something went wrong while fetching the user profile</p>
      </Grid>
    );
  return (
    <StyledWrapper>
      <Grid item xs={12}></Grid>
      {profile && (
        <Container className='profile-container'>
          <Grid item xs={12} alignItems='stretch' justifyContent='flex-start' className={classes.back}>
            <ArrowBackIosIcon fontSize='small' />
            <Button className={classes.backButton} onClick={() => history.goBack()}>
              BACK
            </Button>
          </Grid>
          <Grid container className='user-info background-white border-radius-5' xs={12}>
            <Grid item xs={12} md={2}>
              <Avatar
                className='profile-avatar'
                src={
                  profile.profilePicture ||
                  (profile.gender === GenderChoices.MALE && avatar) ||
                  undefined
                }
              />
              <div className='interaction-desktop'>
                {(profile.roles.includes(UserType.PROFESSIONAL) ||
                  profile.roles.includes(UserType.ALUMNI)) && (
                  <InitiateInteraction profile={profile} />
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={6} className='profile-info user-description'>
              <h1 className='body-22 mb-0 mt-0 text-center-sm'>
                {profile.firstName} {profile.lastName}
              </h1>

              {(profile.roles.includes(UserType.PROFESSIONAL) ||
                profile.roles.includes(UserType.ALUMNI)) && (
                <div>
                  <p className='body-15 mt-5px text-center-sm'>{profile?.company?.name}</p>
                  <p className='body-16 font-weight-bolder mt-2 mb-0 text-center-sm'>
                    {profile.jobTitle}
                  </p>
                  <p className='body-16 spacing'>{profile?.division?.name}</p>
                  {profile?.region?.name !== '' && (
                    <p className='body-16 font-weight-bolder mt-2'>
                      HOMETOWN:{' '}
                      <span className='font-weight-lighter color-gulf-blue'>
                        {profile?.region?.name}
                      </span>
                    </p>
                  )}
                  <p className='body-16 mt-2 color-gulf-blue user-bio'>{profile?.bio}</p>
                  {profile?.employmentHistory?.company && (
                    <p className='body-16 font-weight-bolder mt-3'>
                      PREVIOUS EXPERIENCE:{' '}
                      <span className='font-weight-lighter color-gulf-blue'>
                        {profile?.employmentHistory?.jobTitle} -{' '}
                        {profile?.employmentHistory?.company}
                      </span>
                    </p>
                  )}
                </div>
              )}

              <Grid className='work-edu interaction-sm' item xs={12} md={3}>
                {(profile.roles.includes(UserType.PROFESSIONAL) ||
                  profile.roles.includes(UserType.ALUMNI)) && (
                  <InitiateInteraction profile={profile} />
                )}
              </Grid>

              {profile.roles.includes(UserType.STUDENT) && (
                <div>
                  <p className='body-16 spacing'>{profile.university?.name}</p>
                  {profile.preferredName && (
                    <p className='body-16 font-weight-bolder mt-1'>
                      PREFERRED NAME:{' '}
                      <span className='color-dark-blue text-uppercase'>
                        {profile.preferredName}
                      </span>
                    </p>
                  )}

                  {profile.region?.name && (
                    <p className='body-16 font-weight-bolder mt-1'>
                      HOMETOWN:{' '}
                      <span className='font-weight-lighter color-gulf-blue'>
                        {profile.region?.name}
                      </span>
                    </p>
                  )}
                  <p className='body-16 mt-2 color-gulf-blue user-bio'>{profile?.bio}</p>
                </div>
              )}
            </Grid>
            {(profile.roles.includes(UserType.PROFESSIONAL) ||
              profile.roles.includes(UserType.ALUMNI)) && (
              <Grid className='work-edu' item xs={12} md={4}>
                {profile?.employmentHistory?.length > 0 && (
                  <div className='mb-3'>
                    <span className='body-16 font-weight-bolder'>EMPLOYMENT HISTORY:</span>
                    <div className='d-flex'>
                      <div>
                        {profile?.employmentHistory.map((data: any) => (
                          <>
                            <p className='body-15 mb-0 mt-1'>{data?.company}</p>
                            <p className='body-15 mt-5px'>{data?.jobTitle}</p>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  {profile.university?.name && (
                    <>
                      <span className='body-16 font-weight-bolder'>EDUCATION:</span>
                      <div className='d-flex'>
                        <div>
                          <p className='body-15 mb-0 mt-1'>{profile.university?.name}</p>
                          <p className='body-15 mt-5px'>
                            {profile.degreeType?.name && `${profile.degreeType?.name},`}{' '}
                            {profile.universityCourse?.name}{' '}
                          </p>
                        </div>
                      </div>{' '}
                    </>
                  )}
                </div>
              </Grid>
            )}
            {(profile.roles.includes(UserType.STUDENT) ||
              profile.roles.includes(UserType.ALUMNI)) && (
              <>
                <Grid item xs={12}>
                  <p className='about-user-header'> About {profile?.firstName} </p>
                </Grid>
                <Grid className='about-container-spacing' item xs={12} md={4}>
                  <div className='light-background-container-color'>
                    <div className='dark-background-header-color padding-space body-15 font-weight-bold'>
                      Personal Information
                    </div>
                    <div className='padding-space inner-padding'>
                      {profile?.gender && (
                        <div className='d-flex'>
                          <span className='body-13 font-weight-bold key-width'>GENDER:</span>{' '}
                          <span className='value-left-spacing text-capitalize'>
                            {profile?.gender.toLowerCase()}
                          </span>
                        </div>
                      )}
                       {profile && (
                        <div className='d-flex'>
                          <span className='body-13 font-weight-bold key-width'>{profile?.associateStatus ==='On going' ? 'NOT AN UPREACH ASSOCIATE' : "UPREACH ASSOCIATE"}</span>{''}
                        </div>
                      )}
                      {profile?.careerInterests?.length > 0 && (
                        <div className='d-flex'>
                          <span className='body-13 font-weight-bold key-width'>
                            CAREER INTERESTS:
                          </span>
                          <ul className='careerInterests-list value-left-spacing'>
                            {profile.careerInterests.map((data: any) => (
                              <li>{data.name}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid className='about-container about-container-spacing' item xs={12} md={4}>
                  <div className='light-background-container-color'>
                    <div className='dark-background-header-color padding-space body-15 font-weight-bold'>
                      University Information
                    </div>
                    <div className='padding-space'>
                      {profile?.university?.name !== '' && (
                        <div className='d-flex'>
                          <span className='body-13 font-weight-bold key-width'>UNIVERSITY:</span>{' '}
                          <span className='value-left-spacing'>{profile?.university?.name}</span>
                        </div>
                      )}
                      {profile?.universityCourse?.name !== '' && (
                        <div className='d-flex'>
                          <span className='body-13 font-weight-bold key-width'>COURSE:</span>{' '}
                          <span className='value-left-spacing'>
                            {profile?.universityCourse?.name}
                          </span>
                        </div>
                      )}
                      {profile?.yearOfStudy && (
                        <div className='d-flex'>
                          <span className='body-13 font-weight-bold key-width'>
                            YEAR AT UNIVERSITY (AS OF SEP {new Date().getFullYear() - 1}):
                          </span>{' '}
                          <span className='value-left-spacing'>{profile?.yearOfStudy}</span>
                        </div>
                      )}
                      {profile?.graduationYear !== '' && (
                        <div className='d-flex'>
                          <span className='body-13 font-weight-bold key-width'>
                            GRADUATION YEAR:
                          </span>
                          <span className='value-left-spacing'>{profile?.graduationYear}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid className='about-container-spacing' item xs={12} md={4}>
                  <div className='light-background-container-color'>
                    <div className='dark-background-header-color padding-space body-15 font-weight-bold'>
                      Academic & Contextual Information
                    </div>
                    <div className='padding-space'>
                      {profile?.aLevelGrades && (
                        <div className='d-flex'>
                          <span className='body-13 font-weight-bold key-width'>
                            A-LEVEL GRADES:
                          </span>{' '}
                          <span className='value-left-spacing'>{profile?.aLevelGrades}</span>
                        </div>
                      )}
                      {profile?.disadvantageIndicators &&
                        profile?.disadvantageIndicators?.length > 0 && (
                          <div className='d-flex'>
                            <span className='body-13 font-weight-bold key-width'>
                              DISADVANTAGE INDICATORS:
                            </span>{' '}
                            <ul className='careerInterests-list value-left-spacing'>
                              {profile.disadvantageIndicators.map((data: any) => (
                                <li>{data.name}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                      {profile?.householdIncome && (
                        <div className='d-flex'>
                          <span className='body-13 font-weight-bold key-width'>
                            HOUSEHOLD INCOME:
                          </span>{' '}
                          <span className='value-left-spacing'>{profile?.householdIncome}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>
              </>
            )}
            <Grid container className={classes.btnGrid}>
              {user.roles.includes('UPREACH_ADMIN') &&
                profile &&
                profile.roles.includes(UserType.PROFESSIONAL) && (
                  <Button
                    className={classes.deactivateButton}
                    onClick={() => {
                      setIsModalOpen(true);
                      setModalType('DEACTIVATE');
                    }}
                  >
                    Deactivate Professional
                  </Button>
                )}
              {user.roles.includes('UPREACH_ADMIN') &&
                profile.roles.includes(UserType.PROFESSIONAL) && (
                  <Button
                    className={classes.deactivateButton}
                    onClick={() => {
                      setIsModalOpen(true);
                      setModalType('GHOST');
                    }}
                  >
                    Make professional Ghost
                  </Button>
                )}
            </Grid>
          </Grid>
          {user &&
            user.roles.includes(UserType.PROGRAM_COORDINATOR) &&
            profile.roles.includes(UserType.PROFESSIONAL) && (
              <Grid container xs={12}>
                <div className='referral-card'>
                  <div>
                    <p className='body-18 color-white'>Refer a Professional</p>
                  </div>
                  <Button
                    onClick={() => setIsReferralModalOpen(true)}
                    className='referral-btn'
                    variant='text'
                  >
                    Refer the students to the right professional.
                  </Button>
                </div>
              </Grid>
            )}
          {profile && profile.roles.includes(UserType.PROFESSIONAL) && (
            <DeactivateModal
              userEmail={profile.email}
              isOpen={isModalOpen}
              onClose={handleModalClose}
              userId={profile.id}
              modalType={modalType}
              isProfileDisabled={profile?.metadata?.isDisabled}
              isProfileGhost={profile?.metadata?.isGhost}
            />
          )}
          {profile && (
            <ReferralSystem
              isOpen={isReferralModalOpen}
              title='Select Associate for referral'
              onClose={handleReferralModalClose}
            />
          )}
          {urlId !== authUser?.id &&
            (user?.roles.includes(UserType.UPREACH_ADMIN) ||
              (user?.roles.includes(UserType.PROGRAM_COORDINATOR) &&
                myPcAssociates?.includes(profile?.id))) && (
              <Grid className='interaction-section' container spacing={5}>
                <Grid item xs={12} md={9}>
                  <InteractionList
                    username={profile.firstName}
                    interactions={userInteractions}
                    handleInteractionOpen={handleInteractionClick}
                  />
                </Grid>
              </Grid>
            )}
        </Container>
      )}
    </StyledWrapper>
  );
};

export default UserProfile;

const StyledWrapper = styled.div`
  margin-top: 50px;

  .user-info {
    padding: 20px 55px 35px 55px;

    @media (max-width: 960px) {
      padding: 20px;
    }
  }

  .mt-1 {
    margin-top: 10px;
  }

  .mt-5px {
    margin-top: 5px;
  }

  .mt-2 {
    margin-top: 20px;
  }

  .mt-3 {
    margin-top: 30px;
  }

  .mb-3 {
    margin-bottom: 30px;
  }

  .profile-avatar {
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
    @media (max-width: 960px) {
      margin: auto;
    }
  }

  .profile-container {
    max-width: 1400px;
  }

  .spacing {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .user-description {
    padding-left: 90px;
    @media (max-width: 960px) {
      padding-left: unset;
    }
  }

  .work-edu {
    padding-left: 90px;
    @media (max-width: 960px) {
      padding-left: unset;
      order: 1;
    }
  }

  .interaction-section {
    margin-top: 20px;
  }

  .dummy-img {
    margin-right: 20px;
  }

  .MuiGrid-spacing-xs-5 > .MuiGrid-item {
    @media (max-width: 960px) {
      padding-top: 0px;
    }
  }

  .interaction-desktop {
    @media (max-width: 960px) {
      display: none;
    }
  }

  .interaction-sm {
    @media (min-width: 960px) {
      display: none;
    }
  }

  .text-center-sm {
    line-height: normal;
    @media (max-width: 960px) {
      text-align: center;
    }
  }

  .edit-profile {
    margin-right: 44px;
    margin-bottom: 20px;
    font-weight: 500;
    float: right;
    color: #304563;
    text-transform: capitalize;
    font-size: 16px;
  }
  .activity-remaining-card {
    max-width: 400px;
    margin-bottom: 30px;
    float: right;
    margin-right: 40px;
  }

  .tracking-numbers {
    font-size: 18px;
    line-height: 25px;
    color: #464fd0;
    position: relative;
    top: 2px;
    margin-right: 10px;
  }

  .options {
    font-weight: 600;
    font-size: 11px;
    line-height: 25px;
    color: #000000;
    text-transform: capitalize;
    margin-bottom: 5px;
  }

  .activity-tracker {
    position: relative;
    background: #f1f2ff;
    border: 1px solid rgba(70, 79, 208, 0.1);
    box-sizing: border-box;
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #000000;
    padding: 10px 20px 20px 20px;
  }

  .user-bio {
    white-space: break-spaces;
  }

  .dark-background-header-color {
    background-color: #ecedfc;
    min-height: 25px;
    display: flex;
    align-items: center;
  }

  .light-background-container-color {
    background-color: #f8f9ff;
    height: 100%;
  }

  .about-container {
    @media (min-width: 960px) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  .padding-space {
    padding-left: 20px;
    padding-right: 20px;
  }

  .careerInterests-list {
    margin-top: 0;
    padding-left: 18px;
    list-style-type: decimal;
  }

  .value-left-spacing {
    margin-left: 10%;
    font-size: 15px;
    color: #304563;
  }

  .key-width {
    width: 150px;
  }

  .inner-padding {
    padding-bottom: 30px;
  }

  .d-flex {
    margin-top: 20px;
  }

  .about-container-spacing {
    margin-top: 20px;
    margin-bottom: 20px;
    min-height: 220px;
  }

  .about-user-header {
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
  }

  .referral-card {
    margin: 30px 0 0 auto;
    height: 186px;
    width: 284px;
    background: #464fd0;
    border: 1px solid #464fd0;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px;
  }

  .referral-btn {
    color: white;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: none;
    position: relative;
    left: -6px;

    :hover {
      text-decoration: underline;
    }
  }
`;

const useStyles = makeStyles(() => ({
  backButton: {
    textDecoration: 'none !important',
    fontWeight: 'bolder',
    marginTop: '-0.9em',
    color: 'rgba(70, 79, 208, 1)',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  back: {
    marginBottom: '3em',
    color: 'rgba(70, 79, 208, 1)',
  },
  mt: {
    marginTop: '4em',
  },
  deactivateButton: {
    backgroundColor: 'transparent',
    color: 'rgba(48, 69, 99, 1)',
    textDecoration: 'underline',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  btnGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
}));
