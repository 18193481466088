import { Grid } from '@mui/material';
import { ChangeEvent } from 'react';
import { Pagination } from 'app/components';
import { useStyles } from './styles';
import InteractionStatsTable from '../../InteractionStatsTable';

type PropsType = {
  data: any;
  currentPage: number;
  handlePageChange: (event: ChangeEvent<unknown>, pageNo: number) => void;
  sortOrder?: string;
  sortColumn?: string;
  onSort: (c: string) => void;
};

const TableContainer = ({
  data,
  currentPage,
  handlePageChange,
  sortOrder,
  sortColumn,
  onSort,
}: PropsType) => {
  const classes = useStyles();
  if (!data || !data.dashboardData || !(data.dashboardData.length > 0)) return null;
  return (
    <Grid item xs={10} className={classes.mt2}>
      <InteractionStatsTable
        firstColumnTitle={'EMPLOYEE DETAILS'}
        engagedColumnTitle={'STUDENTS ENGAGED'}
        tableData={data && data.dashboardData ? data.dashboardData : []}
        studentTable={false}
        sortOrder={sortOrder}
        sortColumn={sortColumn}
        onSort={onSort}
      />
      <Pagination onChange={handlePageChange} page={currentPage} count={data.noOfPagesAvailable} />
    </Grid>
  );
};

export default TableContainer;
