import styled from "styled-components";
import InteractionTile from "./InteractionTile";

type Props = {
  username: string;
  handleInteractionOpen: any;
  interactions: any;
};

const InteractionList = ({ handleInteractionOpen, interactions }: Props) => {
  return (
    <StyledWrapper>
      {interactions.length === 0 &&
        <div className="no-interaction-message interaction-message-spacing">This user does not have any interactions yet.</div>
      }
      {interactions.length !== 0 &&
        <div className="active">
          <h3>Interactions</h3>
          {interactions.map((interaction: any) => (
            <InteractionTile
              key={interaction.id}
              interaction={interaction}
              handleClick={() => handleInteractionOpen(interaction.id)}
            />
          ))}
        </div>}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div` 
  
  .past-interaction-spacing {
    margin-top: 80px;
  }

  .no-active-interaction {
    font-size: 16px;
  }

  .interaction-message-spacing {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .past-interactions {
    margin-top: 80px;
    margin-bottom: 40px;
  }

`;

export default InteractionList;
