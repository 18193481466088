import { Grid } from '@mui/material';
import { ChangeEvent } from 'react';
import { Pagination } from 'app/components';
import InteractionStatsTable from '../InteractionStatsTable';
import { useStyles } from './styles';

type PropsType = {
  data: any;
  currentPage: number;
  handlePageChange: (event: ChangeEvent<unknown>, pageNo: number) => void;
  routeParam: string;
  sortOrder?: string;
  sortColumn?: string;
  onSort: (c: string) => void;
};

const TableContainer = ({
  data,
  currentPage,
  handlePageChange,
  routeParam,
  sortOrder,
  sortColumn,
  onSort,
}: PropsType) => {
  const classes = useStyles();
  const firstColumnTitle = routeParam === 'students' ? 'STUDENT DETAILS' : routeParam === 'alumni' ? 'ALUMNI DETAILS' : 'EMPLOYEE DETAILS';
  if (!data || !data.dashboardData || !(data.dashboardData.length > 0)) return null;
  return (
    <Grid item xs={11} className={classes.mt}>
      <InteractionStatsTable
        firstColumnTitle={firstColumnTitle}
        studentTable={routeParam === 'students' || routeParam === 'alumni'}
        engagedColumnTitle={
          routeParam === 'students' || routeParam === 'alumni' ? 'Professionals engaged' : 'Students Engaged'
        }
        tableData={data && data.dashboardData ? data.dashboardData : []}
        sortOrder={sortOrder}
        sortColumn={sortColumn}
        onSort={onSort}
      />
      <Pagination onChange={handlePageChange} page={currentPage} count={data.noOfPagesAvailable} />
    </Grid>
  );
};

export default TableContainer;
