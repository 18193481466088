import firebase from "../firebase";
export const getToken = async (): Promise<string | undefined> => {
    try {
        const idToken:
            | string
            | undefined = await firebase.auth().currentUser?.getIdToken(true);
        return idToken;
    } catch (error) {
        console.log(error);
        return undefined;
    }
};
