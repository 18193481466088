import { Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { selectUser } from 'redux/auth/selectors';
import { UserType } from 'types';
import { useReactQuery } from 'utils/hooks/useReactQuery';
import Loader from 'app/components/Loader';
import TopEngagedTable from '../TopEngaged/TopEngagedTable';
import ImpactDataCard from './ImpactData';
import OverviewChart from './OverviewChart';
import OverviewSection from './OverviewTab';
import WelcomeText from './WelcomeText';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { Dayjs } from 'dayjs';

import {
  employerTableHeaderHomePage,
  universityTableHeaderHomePage,
} from 'utils/constants/tableHeaders';
import { useState } from 'react';
import { sub } from 'date-fns';
import { setDateIntervalFrom, setDateIntervalTo } from 'redux/graph/slice';
import { selectDateIntervalFrom, selectDateIntervalTo } from 'redux/graph/selectors';
// import { DateRange, DateRangeDelimiter, DateRangePicker, LocalizationProvider } from "@material-ui/pickers";
// import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { useParamsForHomePageData } from 'utils/hooks/getParamsForDataFetching';
import TableSnippet from './TableSnippet';
import { DateRange } from '@mui/lab';

const HomePage = () => {

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const location = useLocation();
  const [isCustomDate, setIsCustomDate] = useState<boolean>(false);
  const classes = useStyle();
  const dateIntervalFrom = useSelector(selectDateIntervalFrom);
  const dateIntervalTo = useSelector(selectDateIntervalTo);
  enum GraphInterval {
    SINCE_LAUNCH = 'Since Launch',
    _30_DAYS = '30 days',
    _60_DAYS = '60 days',
    _90_DAYS = '90 days',
    CUSTOM = 'Custom',
  }
  const [filterDuration, setFilterDuration] = useState<string>(GraphInterval.SINCE_LAUNCH);
  const [dateRange, setDateRange] = useState<DateRange<Date>>([null, null]);
  
  const { data, isLoading, error } = useReactQuery(['homePageData', user, location.key, dateIntervalFrom, dateIntervalTo], {
    url: '/dashboard/homePageData',
    params: useParamsForHomePageData(user),
  });

  const handleTimeIntervalChange = (event: SelectChangeEvent<string>) => {
    const duration = event.target.value;
    setFilterDuration(duration as string);

    if (duration === GraphInterval.SINCE_LAUNCH) {
      dispatch(setDateIntervalFrom(null));
      dispatch(setDateIntervalTo(new Date()));
      setIsCustomDate(false);
    }
    else if (duration === GraphInterval._30_DAYS) {
      dispatch(setDateIntervalFrom(sub(new Date(), { days: 30 })));
      dispatch(setDateIntervalTo(new Date()));
      setIsCustomDate(false);
    }
    else if (duration === GraphInterval._60_DAYS) {
      dispatch(setDateIntervalFrom(sub(new Date(), { days: 60 })));
      dispatch(setDateIntervalTo(new Date()));
      setIsCustomDate(false);
    }
    else if (duration === GraphInterval._90_DAYS) {
      dispatch(setDateIntervalFrom(sub(new Date(), { days: 90 })));
      dispatch(setDateIntervalTo(new Date()));
      setIsCustomDate(false);
    }
    else if (duration === GraphInterval.CUSTOM) {
      setIsCustomDate(true);
    }

  };


  const handleDateRangeChange = () => {
    dispatch(setDateIntervalFrom(new Date(dateRange[0] as Date)));
    dispatch(setDateIntervalTo(new Date(dateRange[1] as Date)));
  };

  if (isLoading) return <Loader />;
  if (error) return <p>Error occured while fetching the data</p>;

  return (
    <Grid container justifyContent='center'>
      <Grid container item xs={11} justifyContent='space-between' className={classes.welcome}>
        <Grid item xs={7}>
          <WelcomeText user={user} />
        </Grid>
        <Grid item xs={4}>
          <ImpactDataCard data={data.impactData} user={user} />
        </Grid>
      </Grid>
      <Grid container item xs={11} justifyContent='space-between'>
        <Grid item xs={11} lg={3} md={4}>
          <InputLabel id="select-range">Select Range</InputLabel>
          <Select
            className={classes.pageSizeWidth}
            variant="outlined"
            labelId="select-range"
            defaultValue={filterDuration}
            onChange={handleTimeIntervalChange}
          >
            {[GraphInterval.SINCE_LAUNCH, GraphInterval._30_DAYS, GraphInterval._60_DAYS, GraphInterval._90_DAYS, GraphInterval.CUSTOM].map((value) => (
              <MenuItem value={value}>{value}</MenuItem>
            ))}
          </Select>
        </Grid>
        {isCustomDate &&
          <Grid item xs={11} lg={8} md={6} >
            <InputLabel className={classes.datePickerInputlabel}>Custom Range</InputLabel>
            <Grid item xs={11} lg={11} md={6} className={classes.datePickerContainer}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <FormControl>
                      <DatePicker
                        label="Start"
                        value={dateRange[0]}
                        disableFuture={true}
                        onChange={(newValue) => {
                          setDateRange([newValue, dateRange[1]]);
                        }}
                        ref={(startProps: any) => (
                          <TextField {...startProps}  />
                        )}
                      />
                      <FormHelperText>mm/dd/yyyy</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Box sx={{ mx: 2 }}> to </Box>
                  </Grid>
                  <Grid item>
                    <FormControl>
                      <DatePicker
                        label="End"
                        value={dateRange[1]}
                        disableFuture={true}
                        minDate={dateRange[0]}
                        onChange={(newValue) => {
                          setDateRange([dateRange[0], newValue]);
                        }}
                        ref={(endProps: any) => (
                          <TextField {...endProps} />
                        )}
                      />
                      <FormHelperText>mm/dd/yyyy</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </LocalizationProvider>

              <Button
                color='primary'
                variant='contained'
                className={classes.setRangeButton}
                onClick={() => handleDateRangeChange()}
                disabled={dateRange.includes(null)}
              >
                Set Range
              </Button>
            </Grid>
          </Grid>
        }
      </Grid>
      <Grid item xs={11} className={classes.mt2}>
        <Box boxShadow={0} bgcolor='background.paper' className={classes.boxPadding}>
          {data && data.chartDataset && (
            <Grid container item xs={12} justifyContent='space-between' alignItems='flex-end'>
              <OverviewChart chartData={data.chartDataset} />
            </Grid>
          )}
          {user.roles.includes(UserType.EMPLOYER) && data && data.overview && (
            <Grid container item xs={11} justifyContent='space-between' alignItems='flex-end'>
              <OverviewSection data={data.overview.professional} />
            </Grid>
          )}
          {data && (data.mostActiveEmployees?.length > 0 || data.mostActiveStudents?.length > 0) && (
            <>
              <TableSnippet className="mt-2" data={data} user={user} classes={classes} dateIntervalFrom={dateIntervalFrom} isAlumni />
            </>
          )}
        </Box>
      </Grid>

      <Grid item xs={11} className={classes.marginForTableGrid}>
        <Box className={classes.boxPadding} boxShadow={0} bgcolor='background.paper'>
          <Typography variant='subtitle1'>
            <strong>
              {user.roles.includes(UserType.EMPLOYER)
                ? 'Top Universities engaged'
                : user.roles.includes(UserType.UNIVERSITY_ADMIN)
                  ? 'Top Employers engaged'
                  : ''}
            </strong>
          </Typography>

          <TopEngagedTable
            userRoles={user.roles}
            tableHeadTitles={
              user.roles.includes(UserType.EMPLOYER)
                ? universityTableHeaderHomePage
                : employerTableHeaderHomePage
            }
            tableData={
              data && user.roles.includes(UserType.EMPLOYER)
                ? data.topEngagedUniversity
                : data.topEngagedEmployers
            }
          />

          <Typography className={classes.linkMargins}>
            <Link
              to={
                user.roles.includes(UserType.EMPLOYER)
                  ? '/universitiesEngaged'
                  : '/employersEngaged'
              }
            >
              View All
            </Link>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HomePage;

const useStyle = makeStyles(() => ({
  marginForTableGrid: {
    marginTop: '3em',
    marginBottom: '2em',
  },
  mt2: {
    marginTop: '2em',
  },
  boxPadding: {
    padding: '2em 1em',
    paddingLeft: '3em',
  },
  welcome: {
    marginTop: '2em',
    marginBottom: '1em',
  },
  linkMargins: {
    marginTop: '1.5em',
    marginLeft: '0.5em',
  },
  pageSizeWidth: {
    width: "100%",
    marginTop: "0.5em"
  },
  pageSizeWidthBy2: {
    width: "45%",
    marginTop: "0.5em",
    marginLeft: "11px",
  },
  datePickerInputlabel: {
    marginBottom: "0.5em"
  },
  datePickerContainer: {
    display: "inline-flex",
  },
  datePicker: {
    '& .MuiInputLabel-root': {
      color: "red !important",
    },
    '& .MuiInputBase-root': {
      borderColor: "red ! important",
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: "red !important",
    },
  },
  setRangeButton: {
    padding :"16px",
    height: "57px !important",
    width: "130px !important",
    backgroundColor: "#3f51b5 !important",
    '&:disabled': {
    backgroundColor:"rgba(0, 0, 0, 0.12) !important",
    }
  },
  toolbar: {
    paddingTop: '0.62em',
    paddingBottom: '0.62em',
    backgroundColor: '#4495B5',
  },
  TypographyText: {
    color: '#fff',
    fontWeight: 'bolder',
    paddingLeft: '2em',
    textAlign: 'center',
  },
}));
