import { useSelector } from "react-redux";
import { selectUser } from "redux/auth/selectors";
import { Message, User } from "types";

type Props = {
    message: Message;
};
const RemindMeLater = ({ message }: Props) => {
    const user: User | null = useSelector(selectUser);
    if (message.sender === "SYSTEM") return null;
    return (
        <div>
            <span className="body-17 font-weight-bolder color-gulf-blue">
                We will remind<span>
                     &nbsp;{user?.id===message.sender.id ? "you" :message.sender.firstName} 
                </span> 
                
                <span>
                    &nbsp;to send a follow-up message at a later date
                </span>
            </span>
        </div>
    )
}
export default RemindMeLater;