import { Checkbox } from '@mui/material';

type Props = {
  itemText?: string;
  idx?: number;
  checked?: boolean;
  handleOptionChecked?: (idx: any, name: any) => void;
};

const DropDownItem = ({ itemText, idx, checked, handleOptionChecked }: Props) => {
  return (
    <div className='dropdown-item'>
      <Checkbox
        color='primary'
        className='dropdown-checkbox'
        checked={checked}
        onChange={() => handleOptionChecked && handleOptionChecked(idx, itemText)}
      />
      <span className='dropdown-text'>{itemText}</span>
    </div>
  );
};

export default DropDownItem;
