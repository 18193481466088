import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import Avatar from '@mui/material/Avatar';
import avatar from 'assets/avatar.svg';

type Props = {
  index: number;
  data: any;
};

function TableDataCell({ index, data }: Props) {
  const classes = useStyles();
  return (
    <TableRow key={+index} className={index % 2 !== 0 ? classes.bgBlue : ''}>
      <TableCell>
        <div className={classes.userNameLink}>
          <Avatar
            className='cursor-pointer avatar-white'
            alt={data.firstName}
            src={data.profilePicture || avatar}
          />
          <Link to={`/users/${data.id}`} className={classes.userName}>
            {data.firstName ? `${data.firstName} ${data.lastName ? data.lastName : ''}` : '--'}
          </Link>
        </div>
      </TableCell>
      <TableCell align='center'>{data?.associateStatus ==='On going' ? 'No' : "Yes"}</TableCell>
      <TableCell align='center'>{data.university ? data.university : '--'}</TableCell>
      <TableCell align='center'>
        {data.engagedUsers && isNaN(Number(data.engagedUsers)) ? '--' : data.engagedUsers}
      </TableCell>
      <TableCell align='center'>
        {data.createdAt ? format(new Date(data.createdAt._seconds * 1000), 'dd.MM.yyyy') : '--'}
      </TableCell>
      <TableCell align='center'>
        {data.lastInteractionDate
          ? format(new Date(data.lastInteractionDate._seconds * 1000), 'dd.MM.yyyy')
          : '--'}
      </TableCell>
      <TableCell align='center'>
        {data.interactionStateInfo && isNaN(Number(data.interactionStateInfo.TOTAL))
          ? '--'
          : data.interactionStateInfo.TOTAL}
      </TableCell>
    </TableRow>
  );
}

export default TableDataCell;

const useStyles = makeStyles(() => ({
  bgBlue: {
    backgroundColor: '#F2F3FF',
  },
  userNameLink: {
    display: 'flex',
    alignItems: 'center',
  },
  userName: {
    marginLeft: '10px',
    textDecoration: 'none'
  }
}));
