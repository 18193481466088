import { useState, Children } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { selectUser } from 'redux/auth/selectors';
import { Grid, GridSize, Toolbar } from '@mui/material';
import ProfileDropdown from './ProfileDropdown';
import ProfileImage from './ProfileImage';
import { NavigationLinks } from 'utils/constants/navigationLinks';
import { StyledWrapper, useStyles } from './style';
import Logo from 'assets/logo.svg';

type LinkType = {
  to: string;
  navigationText: string;
  gridSize: boolean | GridSize | undefined;
};

const Navbar = () => {
  const classes = useStyles();
  const { roles, email }: { roles: string[]; email: string } = useSelector(selectUser);
  const [profileDropdownOpen, setProfileDropdownOpen] = useState<boolean>(false);

  const getNavLinks = () => {
    const links: any = [];

    roles.forEach((role: string) => {
      const data = NavigationLinks[role];
      if (data) links.push(...data);
    });

    return links;
  };
  const navLinks: LinkType[] = getNavLinks();

  return (
    <StyledWrapper>
      <Toolbar className={classes.toolbar}>
        <Grid container justifyContent='flex-start' alignItems='center'>
          <Grid item md={3} sm={4}>
            <Link to='/home'>
              <img className={classes.image} src={Logo} alt='' />
            </Link>
          </Grid>
          <Grid container item xs={8}>
            {Children.toArray(
              navLinks.map((link: LinkType) => (
                <Grid item sm={link.gridSize}>
                  <NavLink to={link.to} activeClassName={classes.activeLink}>
                    {link.navigationText}
                  </NavLink>
                </Grid>
              )),
            )}
          </Grid>
          <ProfileImage setProfileDropdownOpen={setProfileDropdownOpen} />
        </Grid>
      </Toolbar>
      <ProfileDropdown
        isDropdownOpen={profileDropdownOpen}
        setDropdownOpen={setProfileDropdownOpen}
        email={email}
      />
    </StyledWrapper>
  );
};

export default Navbar;
