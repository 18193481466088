import { Message } from "types";

type Props = {
  message: Message;
};

const TextMessage = ({ message }: Props) => {
  return (
    <div className="interaction-texts">{message.payload}</div>
  )
};

export default TextMessage;