import { Button, Grid, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Modal from "app/components/Modal";
import { showToast, ToastType } from "app/components/Toast/slice";
import getAxiosInstance from "utils/axios";
import { useContext } from "react";
import professionalContext from "../AdminView/Professionals/DeactivatedEmployees/professionalContext";
type PropsType = {
  isOpen: boolean;
  onClose: any;
  userEmail: string;
  userId: string;
  modalType: string;
  isProfileDisabled: boolean;
  isProfileGhost: boolean;
};
const DeactivateModal = ({ isOpen, onClose, userEmail, userId, modalType, isProfileDisabled ,isProfileGhost}: PropsType) => {
  // const [email, setEmail] = useState<string>("");
  // const [disabled, setDisabled] = useState<boolean>(true);
  const dispatch = useDispatch();
  const classes = useStyles();
  const context: any = useContext(professionalContext);
  const deactivateDate = async () => {
    try {
      const axios = await getAxiosInstance();
      await axios.post("/dashboard/deactivateDate", {
        employeeId: userId,
      })
    } catch (error) {
    }
  }
  useEffect(() => {
    fetchApiData(
      '/dashboard/fetchDeactivateEmployees',
      {
        query: "",
        pageNo: 1,
        filters: `(metadata.isDisabled:true)`,
        pageSize: 10,
        sortColumn: "",
        sortOrder:"",
        status: 'onboarded',
      })
      .then(data1 =>{
      // setData(data1.data)
      // setIsLoading(false)
      })
  },[])
const fetchApiData = async (url: string, params: any) => {
  const axios = await getAxiosInstance();
  const  data  = await axios.get(url, {
    params,
  });
  return data;
};
  const sendToggleRequest = async () => {
    try {
      const axios = await getAxiosInstance();
      await axios.post("/dashboard/toggleEmployeeAccess", {
        employeeId: userId,
        property: modalType === 'DEACTIVATE' ? 'isDisabled' : modalType ==="GHOST" ? 'isGhost' : 'isEnabled'
      });
      dispatch(
        showToast({
          message: `User Profile ${modalType === 'DEACTIVATE' ? 'disabled' : modalType === "GHOST" ? "ghosted" : "enabled"} successfully`,
          type: ToastType.SUCCESS,
        })
      );
    } catch (error) {
      dispatch(
        showToast({
          message: `Something went wrong while ${modalType === 'DEACTIVATE' ? 'disabling' : modalType === "GHOST" ? "ghosting" : "enabling"} user profile`,
          type: ToastType.ERROR,
        })
      );
    } finally {
      onClose();
    }
  }
  const disableProfile = async () => {
    if (modalType === 'DEACTIVATE') {
      if (isProfileDisabled) {
        dispatch(
          showToast({
            message: `
            User Profile already disabled`,
            type: ToastType.INFO,
          })
        )
      } 
      else {
        await sendToggleRequest();
        await deactivateDate();
        fetchApiData(
          '/dashboard/fetchDeactivateEmployees',
          {
            query: "",
            pageNo: 1,
            filters: `(metadata.isDisabled:true)`,
            pageSize: 10,
            sortColumn: "",
            sortOrder: "",
            status: 'onboarded',
          })
          .then(data1 =>{
          })
      }
    }
    else if(modalType==='GHOST'){
      if(isProfileGhost)
      {
        dispatch(
          showToast({
            message: `User Profile already ghosted`,
            type: ToastType.INFO,
          })
        );
      }
     else {
        await sendToggleRequest()
      }
    }
    else {
      if (isProfileDisabled) {
        dispatch(
          showToast({
            message: `User Profile already enabled`,
            type: ToastType.INFO,
          })
        );
      } else {
        await sendToggleRequest();
        fetchApiData(
          '/dashboard/fetchDeactivateEmployees',
          {
            query: "",
            pageNo: 1,
            filters: `(metadata.isDisabled:true)`,
            pageSize: 10,
            sortColumn: "",
            sortOrder: "",
            status: 'onboarded',
          })
          .then(data1 => {
            context.onEmployeAccessToggle();
          })
      }
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Grid container justifyContent="center">
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.titleText}>
            {`Are you sure you want to ${modalType === 'DEACTIVATE' ? 'deactivate' : modalType === 'GHOST' ? "ghost" : "enabled"} this employee’s profile?`}
          </Typography>          
          {/* <Typography variant="subtitle1" className={classes.secondaryText}>            
            Enter "{userEmail}" {modalType === 'DEACTIVATE' ? "to deactivate this profile" : modalType === 'GHOST' ? "to ghost this profile" : "to enable this profile"}
          </Typography> */}
        </Grid>        
        {/* <Grid item xs={7}>          
          <TextField
            variant="outlined"
            className={classes.textBox}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />      
            </Grid> */}
        <Grid item xs={10} className={classes.buttons}>
          <Button variant="outlined" className={classes.cancelButton} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.confirmButton}
            onClick={disableProfile}
          >
            Confirm
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
export default DeactivateModal;
const useStyles = makeStyles(() => ({
  titleText: {
    fontSize: "1.5em",
    fontWeight: 400,
    color: "rgba(48, 69, 99, 1)",
  },
  secondaryText: {
    color: "rgba(48, 69, 99, 1)",
    marginTop: "1em",
  },
  textBox: {
    width: "80%",
    backgroundColor: "rgba(247, 247, 247, 1)",
    marginTop: "0em",
    "& input": {
      border: "0px solid black",
      outline: "none !important",
      outlineWidth: 0,
    },
  },
  confirmButton: {
    background: "rgba(70, 79, 208, 1)",
    borderRadius: "5px",
    color: "#ffffff",
    padding: "0.7em 5em",
    "&:hover": {
      background: "rgba(70, 79, 208, 1)",
    },
  },
  cancelButton: {
    border: "1px solid #464FD0",
    borderRadius: "5px",
    marginRight: "1.5em",
    padding: "0.7em 5em",
    "&:hover": {
      background: "#ffffff",
    },
  },
  buttons: {
    marginTop: "2em",
    paddingBottom: "2em",
  },
}));