import React, { Children, useEffect, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// import TableDataCell from './TableDataCell';
import { useStyles } from '../../InteractionStatsTable/styles';
import { Table } from 'app/components';
import SupportReceivedTableDataCell from './SupportReceivedTableDataCell';

const headings: string[] = [
  'Name',
  'University',
  'Number of professionals engaged',
  'Total interactions',
  'Program coordinator',
];

type PropsType = {
  tableData: any[];
  studentTable: boolean;
  sortOrder?: string;
  sortColumn?: string;
  onSort?: (c: string) => void;
};

function SupportReceivedTable({ tableData = [], sortColumn, onSort }: PropsType) {
  const classes: any = useStyles();

  const [sortedData, setSortedData] = useState<any[]>([]);

  useEffect(() => {
    const sortedTableData =
      tableData &&
      tableData.sort(function (a: any, b: any) {
        return (
          b?.interactionStateInfo_ASSOCIATE['TOTAL'] - a?.interactionStateInfo_ASSOCIATE['TOTAL']
        );
      });
    setSortedData(sortedTableData);
  }, [tableData]);

  return (
    <>
      <Table
        tableHeader={
          <>
            <TableRow>
              {React.Children.toArray(
                headings.map((interactionState) => (
                  <TableCell
                    align='center'
                    className={`${classes.state}  ${
                      sortColumn === 'Total'
                        ? interactionState === 'Total' && classes.CursorPointerUnderlined
                        : sortColumn && interactionState === 'Total'
                        ? classes.CursorPointer
                        : ''
                    } `}
                  >
                    {interactionState}
                  </TableCell>
                )),
              )}
            </TableRow>
          </>
        }
        tableBody={
          <>
            {Children.toArray(
              sortedData.map((data: any, index: number) => (
                <>
                  <SupportReceivedTableDataCell data={data} index={index} />
                </>
              )),
            )}
          </>
        }
      />
    </>
  );
}

export default SupportReceivedTable;
