import { useEffect } from 'react';
// import styled from 'styled-components';
import { Grid, Button, Typography } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { selectRegistrationFormWorking, selectUser } from 'redux/auth/selectors';
import { toggleRegistrationFormWorking } from 'redux/auth/slice';
import { PreLoginHeader, TextField } from 'app/components';
import { InputType } from 'app/components/TextField';
import styled from '@emotion/styled';

type TypeformUserRegistrationFormType = {
  email: string;
  password: string;
  confirmPassword: string;
};

const AccountCreationForm = () => {
  const registrationFormWorking = useSelector(selectRegistrationFormWorking);
  const { register, handleSubmit, errors, watch } = useForm<TypeformUserRegistrationFormType>();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUser);

  useEffect(() => {
    if (user) history.push('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onSubmit = (data: TypeformUserRegistrationFormType) => {
    const { email, password } = data;
    dispatch(toggleRegistrationFormWorking());
    dispatch({
      type: 'TYPEFORM_REGISTRATION_ATTEMPT',
      payload: { email, password },
    });
  };

  return <>
    <PreLoginHeader />
    <StyledWrapper
      onSubmit={handleSubmit(onSubmit)}
      onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
    >
      <div className='register-form'>
        <Grid container item xs={4} alignItems='center' justifyContent='center'>
          <Grid item xs={12} md={8}>
            <TextField
              size='medium'
              name='email'
              type={InputType.EMAIL}
              label='email'
              inputRef={register({
                required: 'This field is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Please enter a valid email address',
                },
              })}
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              name='password'
              type={InputType.PASSWORD}
              label='create password'
              inputRef={register({
                required: 'This field is required',
                minLength: {
                  value: 6,
                  message: 'Password should contain atleast 6 characters',
                },
              })}
              error={!!errors.password}
              helperText={errors.password?.message}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              name='confirmPassword'
              type={InputType.PASSWORD}
              label='confirm password'
              inputRef={register({
                required: 'This field is required',
                validate: (value) => value === watch('password') || "Passwords don't match",
              })}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword?.message}
            />
          </Grid>
          <Grid container item xs={12} md={8}>
            <Button
              variant='contained'
              size='large'
              color='primary'
              type='submit'
              disabled={registrationFormWorking}
              fullWidth
            >
              {registrationFormWorking ? 'Registering...' : 'Register'}
            </Button>
          </Grid>

          <Grid container item justifyContent='space-evenly'>
            <Typography variant='body1'>
              Already have an account? <Link to='/login'>Login</Link>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </StyledWrapper>
  </>;
};

const StyledWrapper = styled.form`
  .register-form {
    min-height: calc(100vh - 87px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    padding: 0.75rem;
    margin: 3rem 3rem;
  }
  img {
    margin: 1rem 3rem;
  }
`;

export default AccountCreationForm;
