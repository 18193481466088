import professionalContext from "./professionalContext"
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
// import useDebounce from 'utils/hooks/useDebounce';
import { AiOutlineDownload } from 'react-icons/ai';
import { useStyles } from '../styles';
import { showToast, ToastType } from 'app/components/Toast/slice';
import { useDispatch } from 'react-redux';
// import { sortColumnToAlgoliaFieldMapping } from 'utils/constants/sortColumnToAlgoliaFieldMapping';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from "react-router-dom";
import ActiveTableContainer from "./tableContainer";
import { CSVDataType, DeactivateDataType } from './types';
import { format } from 'date-fns';
import getAxiosInstance from 'utils/axios';
import React from 'react';
const Active = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [csvData, setCSVData] = useState<CSVDataType[]>([]);
  const [selectedPageSize, setSelectedPageSize] = useState<any>(10);
  const [data, setData] = useState<any>([]);
  const csvButtonRef: any = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  // const debouncedQuery = useDebounce(query, 300);
  const classes = useStyles();
  const fetchApiData = async (url: string, params: any) => {
    const axios = await getAxiosInstance();
    const data = await axios.get(url, {
      params,
    });
    return data;
  };
  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [])
  const reloadData = (() => {
    setIsLoading(true)
    fetchApiData(
      '/dashboard/fetchDeactivateEmployees',
      {
        query: "",
        pageNo: currentPage,
        filters: `(metadata.isDisabled:true)`,
        pageSize: selectedPageSize,
        sortColumn: "",
        sortOrder: "",
        status: 'onboarded',
      })
      .then(data1 => {
        setData(data1.data)
        setIsLoading(false)
      })
  })
  const onEmployeAccessToggle = () => {
    setTimeout(() => {
      reloadData();
    }, 1000);
  }
  const providerValue = {
    onEmployeAccessToggle
  }
  const csvdata: DeactivateDataType[] = data?.completeData;
  const handlePageChange = (event: React.ChangeEvent<unknown>, pageNo: number) => {
    setCurrentPage(pageNo);
  };
  const handleDownloadCSVClicked = async () => {
    try {
      let CSVData: CSVDataType[] = [];
      csvdata.forEach((csv: DeactivateDataType) => {
        CSVData.push({
          'First Name': csv.firstName,
          'Last Name': csv.lastName,
          'Company': csv.company,
          'Job Title': csv.jobTitle,
          'deactivatedAt/_seconds':csv.deactivatedAt && csv?.deactivatedAt?._seconds ? format(new Date(csv?.deactivatedAt?._seconds * 1000), 'dd.MM.yyyy') : '--',
        })
      })
      setCSVData((prevData: CSVDataType[]) => {
        setTimeout(() => {
          csvButtonRef.current.link.click();
          dispatch(
            showToast({
              type: ToastType.SUCCESS,
              message: 'CSV Downloaded Successfully',
            }),
          );
        }, 500);
        return CSVData;
      });
    } catch (error) {
      console.log(error);
      dispatch(
        showToast({
          type: ToastType.ERROR,
          message: 'Error occurred while downloading csv',
        }),
      );
    }
  };
  const handlePageSizeClick = (event: SelectChangeEvent<string>) => {
    setSelectedPageSize(event.target.value as string);
  };
  useEffect(()=>{
    reloadData();
    // eslint-disable-next-line
  },[currentPage,selectedPageSize])
  return (
    <professionalContext.Provider value={providerValue}>
      <Grid container justifyContent='center' className={classes.pd5}>
        <Grid container item xs={10} justifyContent='space-between' alignItems='flex-end'>
          <Grid item xs={3}>
            <Grid item xs={12} alignItems='stretch' justifyContent='flex-start' className={classes.back}>
              <ArrowBackIosIcon fontSize='small' />
              <Button className={classes.backButton} onClick={() => history.goBack()}>
                BACK
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {!isLoading && data && data.dashboardData && data.dashboardData.length > 0
          && (
            <>
              <Grid container item xs={10} justifyContent='flex-end'>
                <CSVLink
                  style={{ visibility: 'hidden' }}
                  data={csvData}
                  filename={'DeactivatedProfessionals.csv'}
                  ref={csvButtonRef}
                ></CSVLink>
                <div className={classes.buttonPageSizeContainer}>
                  <div>
                    <InputLabel id="demo-simple-select-outlined-label">Number of records to display</InputLabel>
                    <Select
                      className={classes.pageSizeWidth}
                      variant="outlined"
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      defaultValue={selectedPageSize}
                      onChange={handlePageSizeClick}
                    >
                      {[10, 25, 50, 75, 100].map((value) => (
                        <MenuItem value={value}>{value}</MenuItem>
                      ))}
                    </Select>
                  </div>
                  <Button className={classes.csvButton} onClick={handleDownloadCSVClicked}>
                    <AiOutlineDownload className={classes.downloadIcon} /> Download as CSV
                  </Button>
                </div>
              </Grid>
              <ActiveTableContainer
                data={data}
                handlePageChange={handlePageChange}
                currentPage={currentPage}
              />
            </>
          )}
        {(isLoading) && (
          <Grid container className={classes.tableReplaceGrid} item xs={12}>
            <Grid item xs={1}>
              <CircularProgress className="no-user-text" />
            </Grid>
          </Grid>
        )}
        {data && data.dashboardData && data.dashboardData.length === 0 && (
          <Grid item xs={12}>
            <Typography variant='h6' className={classes.noRecords} align='center'>
              {' '}
              No records present
            </Typography>
          </Grid>
        )}
      </Grid>
    </professionalContext.Provider>
  );
};
export default Active;