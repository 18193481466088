import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { UserType } from 'types';

type PropsType = {
  user: any;
};

const WelcomeText = ({ user }: PropsType) => {
  const classes = useStyle();
  const { type, firstName: name, company } = user;

  const text =
    type === UserType.EMPLOYER
      ? `Thank you and the team at ${company?.name} for your ongoing support to students on the
  Social Mobility Network!`
      : `Thank you for supporting the Social Mobility Network, helping to provide the network for
  those without a network.`;

  return (
    <>
      <Typography variant='subtitle1' className={classes.welcomeText}>
        Hello {name}
      </Typography>
      <Typography className={classes.welcomeText}>{text}</Typography>
    </>
  );
};

export default WelcomeText;

const useStyle = makeStyles(() => ({
  welcomeText: {
    fontSize: '1.5em',
    fontWeight: 'lighter',
  },
}));
