import { Grid } from "@mui/material";
import { useState } from "react";
import MyAssociates from "./Associates";

import Tabs from "./Tabs";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "redux/navigation/slice";
import { selectActiveTab } from "redux/navigation/selectors";
import UniversityOverview from "../AdminView/UniversityOverview";
import EmployerOverview from "../AdminView/EmployerOverview";
import AllProfessionals from "../AdminView/Professionals";
import AllAssociates from "../AdminView/Associates";

const ProgramCoordinatorView = () => {
  const [prevTabfilter, setPrevTabFilter] = useState<string>("");
  const activeTab = useSelector(selectActiveTab);
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<{}>, index: number) => {
    dispatch(setActiveTab(index));
  };
  return (
    <Grid container justifyContent="center">
      <Grid item xs={10}>
        <Tabs value={activeTab} handleChange={handleChange} />
      </Grid>
      {activeTab === 0 && <UniversityOverview setPrevTabFilter={setPrevTabFilter} />}
      {activeTab === 1 && <EmployerOverview setPrevTabFilter={setPrevTabFilter} />}
      {activeTab === 2 && <AllAssociates setPrevTabFilter={setPrevTabFilter} prevTabfilter={prevTabfilter}/>}
      {activeTab === 3 && (
        <AllProfessionals setPrevTabFilter={setPrevTabFilter} prevTabfilter={prevTabfilter} />
      )}
      {activeTab === 4 && (
        <MyAssociates setPrevTabFilter={setPrevTabFilter} prevTabfilter={prevTabfilter} />
      )}
    </Grid>
  );
};

export default ProgramCoordinatorView;
