import { useSelector } from 'react-redux';
import { selectUser } from 'redux/auth/selectors';
import { Message, User } from 'types';

type Props = {
    message: Message;
};

const LikeMessage = ({ message }: Props) => {
    const user: User | null = useSelector(selectUser);

    if (message.sender === "SYSTEM") return null;

    return (
        <div>
            <span className="body-17 font-weight-bolder color-gulf-blue">
                {user?.id === message.sender.id ? "You sent an appreciation." : message.sender.firstName + " sent you an appreciation."}
            </span>
        </div>
    )
}

export default LikeMessage;
