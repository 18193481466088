import { Dispatch, SetStateAction, useEffect, useState, useRef} from 'react';
import { useReactQuery } from 'utils/hooks/useReactQuery';
import { Filter, Pagination } from 'app/components';
import { CircularProgress, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import useDebounce from 'utils/hooks/useDebounce';
import { TextField } from 'app/components';
import getAxiosInstance from 'utils/axios';
import { University } from 'types';
// import OverviewChart from 'app/containers/HomePage/OverviewChart';
import { useStyles } from 'app/containers/AdminView/Associates/style';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/auth/selectors';
import { sortColumnToAlgoliaFieldMapping } from 'utils/constants/sortColumnToAlgoliaFieldMapping';
import AssociatesTable from 'app/containers/AdminView/Associates/AssociatesTable';

import { CSVLink } from 'react-csv';
import { CSVDataType, DashboardDataType } from './types';
import { AiOutlineDownload } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { showToast, ToastType } from 'app/components/Toast/slice';
import {Button} from '@mui/material';
import { format } from 'date-fns';

type PropsType = {
  prevTabfilter: string;
  setPrevTabFilter: Dispatch<SetStateAction<string>>;
};

const MyAssociates = ({ prevTabfilter, setPrevTabFilter }: PropsType) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [query, setQuery] = useState<string>('');
  const [universities, setUniversities] = useState<University[]>([]);
  const [selectedUniversity, setSelectedUniversity] = useState<any>('');
  // const [initialDataLoading, setInitialDataLoading] = useState<boolean>(false);
  // const [chartData, setChartData] = useState<any>();
  const [sortColumn, setSortColumn] = useState('NAME');
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedPageSize, setSelectedPageSize] = useState<any>(10);
  const debouncedQuery = useDebounce(query, 300);
  const classes = useStyles();
  const user = useSelector(selectUser);

  const csvButtonRef: any = useRef();
  const dispatch = useDispatch();
  const [csvData, setCSVData] = useState<CSVDataType[]>([]);

  useEffect(() => {
    fetchAllUniversities();
    const tempUniversity = prevTabfilter ? { name: prevTabfilter } : '';
    // console.log('tempUniversity', prevTabfilter);
    setSelectedUniversity(tempUniversity);
    setCurrentPage(1);
    setPrevTabFilter('');
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // getting values from session storage
      const getUniversityDetail = sessionStorage.getItem('selectedUniversity1');
      getUniversityDetail && setUniversityDetail1(JSON.parse(getUniversityDetail));
  }, [prevTabfilter,setPrevTabFilter]);

  // useEffect(() => {
  //   (async () =>
  //     selectedUniversity?.name
  //       ? await getChartData(selectedUniversity?.name)
  //       : await getChartData(prevTabfilter))();
  // }, [selectedUniversity, prevTabfilter]);

  const { data, isLoading, error } = useReactQuery(
    ['myAssociates', currentPage, debouncedQuery, selectedUniversity, sortColumn, sortOrder, selectedPageSize, prevTabfilter],
    {
      url: '/dashboard/fetchStudents',
      params: {
        query: debouncedQuery,
        pageNo: currentPage,
        pageSize: selectedPageSize,
        filters: selectedUniversity?.name ? `university.name:"${selectedUniversity.name}" AND programCoordinatorId:"${user.id}"` : `programCoordinatorId:"${user.id}"`,
        sortColumn: sortColumnToAlgoliaFieldMapping[sortColumn as string],
        sortOrder: sortOrder.toUpperCase(),
      },
    },
  );
  const csvdata: DashboardDataType[] = data?.completeData;
  const handleDownloadCSVClicked = async () => {
    try {
      let CSVData: CSVDataType[] = [];
      csvdata.forEach((csv: DashboardDataType) => {
        CSVData.push({
          'Email': csv.email,
          'First Name': csv.firstName,
          'Last Name': csv.lastName,
          'Professionals Engaged': csv.engagedUsers,
          'University Name': csv.university,
          'createdAt/_seconds':csv.createdAt && csv?.createdAt?._seconds ? format(new Date(csv?.createdAt?._seconds * 1000), 'dd.MM.yyyy'):'--',
          'lastInteractionDate/_seconds': csv.lastInteractionDate && csv?.lastInteractionDate?._seconds ? format(new Date(csv?.lastInteractionDate?._seconds * 1000), 'dd.MM.yyyy')
          : '--',
          'interactionStateInfo/TOTAL': csv.interactionStateInfo.TOTAL,
        })
      })
      setCSVData((prevData: CSVDataType[]) => {
        setTimeout(() => {
          csvButtonRef.current.link.click();
          dispatch(
            showToast({
              type: ToastType.SUCCESS,
              message: 'CSV Downloaded Successfully',
            }),
          );
        }, 500);
        return CSVData;
      });
    } catch (error) {
      console.log(error);
      dispatch(
        showToast({
          type: ToastType.ERROR,
          message: 'Error occurred while downloading csv',
        }),
      );
    }
  };

  const fetchAllUniversities = async () => {
    try {
      const axios = await getAxiosInstance();
      const { data: universitiesData } = await axios.get('/dashboard/fetchUniversities');
      setUniversities(universitiesData.allUniversities);
    } catch (error) {
      console.log(error);
    }
  };

  // const getChartData = async (universityName: string) => {
  //   const axios = await getAxiosInstance();
  //   try {
  //     setInitialDataLoading(true);
  //     const { data } = await axios.get('/dashboard/getChartData', {
  //       params: {
  //         orgType: 'UNIVERSITY',
  //         universityName,
  //       },
  //     });
  //     // setChartData(data);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setInitialDataLoading(false);
  //   }
  // };

  const handlePageSizeClick = (event: SelectChangeEvent<string>) => {
    setSelectedPageSize(event.target.value as string);
  };

  const handlePageChange = (_event: React.ChangeEvent<unknown>, pageNo: number) => {
    setCurrentPage(pageNo);
  };

  const handleSorting = (column: string) => {
    if (sortColumn !== column) {
      setSortColumn(column);
      setSortOrder('asc');
    } else {
      const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
      setSortOrder(newSortOrder);
    }
  };

  //Session clear on individual filter
  const handleClearFilter =()=>{
    setSelectedUniversity('');
    sessionStorage.removeItem("selectedUniversity1")
  }
      // setting values to session storage for filter
      const setUniversityDetail1 = (university : any) => {
        sessionStorage.setItem('selectedUniversity1',JSON.stringify(university))
        setSelectedUniversity(university)
      }
  if (error)
    return (
      <Typography variant='h6' className={classes.error}>
        Error fetching records
      </Typography>
    );
  return (
    <Grid item xs={10} className={classes.mb}>
      <Filter
        placeholder={'University'}
        filterTitle='Filter students by'
        options={universities}
        value={selectedUniversity}
        onChange={(university) => (university ? setUniversityDetail1(university) : undefined)}
        onClear={() => handleClearFilter()}
      />
      <Grid item xs={3}>
        <TextField
          onChange={(e) => setQuery(e.target.value)}
          value={query}
          label={`Search Students`}
        />
      </Grid>
      <Grid><></></Grid>

      {(isLoading) && (
        <Grid container className={classes.tableReplaceGrid} item xs={12}>
          <Grid item xs={1}>
            <CircularProgress className='no-user-text'/>
          </Grid>
        </Grid>
      )}
      <div>
        <InputLabel id="demo-simple-select-outlined-label" className={classes.mt2}>Number of records to display</InputLabel>
        <Select
          className={classes.pageSizeWidth}
          variant="outlined"
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          defaultValue={selectedPageSize}
          onChange={handlePageSizeClick}
        >
          {[10, 25, 50, 75, 100].map((value) => (
            <MenuItem value={value}>{value}</MenuItem>
          ))}
        </Select>
      </div>
      <Grid container item xs={12} justifyContent='flex-end'>
        {!isLoading &&
          data &&
          data.dashboardData &&
          data.dashboardData.length !== 0 && (
          <>
            <CSVLink
              style={{ visibility: 'hidden' }}
              data={csvData}
              filename={'myAssociate.csv'}
              ref={csvButtonRef}
            ></CSVLink>
            <Button className={classes.csvButton} onClick={handleDownloadCSVClicked}>
              <AiOutlineDownload className={classes.downloadIcon} /> Download as CSV
            </Button>
            <Grid item xs={12} className={classes.mt3}>
              <AssociatesTable
                tableData={data ? data.dashboardData : []}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
                onSort={handleSorting}
              />
            </Grid>
            <Pagination
              onChange={handlePageChange}
              page={currentPage}
              count={data.noOfPagesAvailable}
            />
          </>
        )}
      </Grid>
      {!isLoading && data && data.dashboardData && data.dashboardData.length === 0 && (
        <Grid item xs={12}>
          <Typography variant='h6' className={classes.noRecords} align='center'>
            {' '}
            No records present
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default MyAssociates;
