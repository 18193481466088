import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectUser } from 'redux/auth/selectors';
import { getFilterForQuery, getURLForUserType } from 'utils/functions/getRequestParams';
import useDebounce from 'utils/hooks/useDebounce';
import { useReactQuery } from 'utils/hooks/useReactQuery';
import { TextField } from 'app/components';
import { useStyles } from './styles';
import AddIcon from '@mui/icons-material/Add';
import { Steps } from '../ParticipatingUserPage/steps';
import { UserType } from 'types';
import TableContainer from './TableContainer';
import ProfessionalsStatusTabs from '../EmployerView/Tabs';
import PendingTable from '../EmployerView/PendingProfessionalsTable';
import { sortColumnToAlgoliaFieldMapping } from 'utils/constants/sortColumnToAlgoliaFieldMapping';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

type PropsType = {
  setStepperStep: Dispatch<SetStateAction<string | undefined>>;
};

const DashboardMainView = ({ setStepperStep }: PropsType) => {
  const [query, setQuery] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const classes = useStyles();
  const user = useSelector(selectUser);
  const { type } = useParams<any>();
  const debouncedQuery = useDebounce(query, 300);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [selectedPageSize, setSelectedPageSize] = useState<any>(10);
  const [pendingLoader, setPendingLoader] = useState<boolean>(false);
  const [sortColumn, setSortColumn] = useState('Total');
  const [sortOrder, setSortOrder] = useState('desc');

  // useEffect(() => {
  //   setCurrentPage(1);
  //   if (type === 'alumni') setIsAlumni(true)
  //   else setIsAlumni(false);
  //   setIsAlumni(false);
  // }, [type]);

  const { data, isLoading, error } = useReactQuery(
    ['dashboardData', currentPage, debouncedQuery, type, sortColumn, sortOrder, selectedPageSize],
    {
      url: type !== "alumni" ? getURLForUserType(user.roles, '/dashboard', type) : '/dashboard/fetchAlumnis',
      params: {
        query: debouncedQuery,
        pageNo: currentPage,
        pageSize: selectedPageSize,
        filters: getFilterForQuery(user),
        sortColumn: sortColumnToAlgoliaFieldMapping[sortColumn as string],
        sortOrder: sortOrder.toUpperCase(),
        status: type === 'employees' ? 'onboarded' : undefined,
      },
    },
    {
      enabled: !!type,
    },
  );

  const handlePageChange = (event: React.ChangeEvent<unknown>, pageNo: number) => {
    setCurrentPage(pageNo);
  };

  const handlePageSizeClick = (event: SelectChangeEvent<string>) => {
    setSelectedPageSize(event.target.value as string);
  };

  const handleSorting = (column: string) => {
    if (sortColumn !== column) {
      setSortColumn(column);
      setSortOrder('asc');
    } else {
      const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
      setSortOrder(newSortOrder);
    }
  };

  const handleTabChange = (tabIndex: number) => {
    setActiveTabIndex(tabIndex);
  };

  if (error)
    return (
      <Typography variant='h6' className={classes.error}>
        Error fetching records
      </Typography>
    );

  return (
    <Grid container justifyContent='center'>
      <Grid container item xs={12} justifyContent='flex-end' className={classes.addEmpButtonGrid}>
        {user.roles.includes(UserType.EMPLOYER) && (
          <Grid item xs={3}>
            <Button
              color='primary'
              variant='contained'
              onClick={() => setStepperStep(Steps.ADD_MULTIPLE_EMPLOYEES_BY_EMAILS)}
              className={classes.addEmpButton}
            >
              <AddIcon /> Add New Employee
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} justifyContent='flex-end' className={classes.addEmpButtonGrid}>
        {user.roles.includes(UserType.EMPLOYER) && (
          <Grid item xs={3}>
            <Button
              color='primary'
              variant='contained'
              onClick={() => setStepperStep(Steps.ADD_MULTIPLE_COMPANY)}
              className={classes.addEmpButton}
            >
              <AddIcon /> Add New Employer
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={11} justifyContent='space-between' alignItems='flex-end'>
        <Grid item xs={3}>
          <TextField
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            value={query}
            label={`Search ${type === 'students' ? 'Student' : 'Employee'}`}
          />
        </Grid>
        {user.roles.includes(UserType.EMPLOYER) && (
          <>
            <Grid item xs={9}>
              <ProfessionalsStatusTabs value={activeTabIndex} handleChange={handleTabChange} />
            </Grid>
            <Grid className={classes.pageSize} item xs={3}>
              <InputLabel id="demo-simple-select-outlined-label">Number of records to display</InputLabel>
              <Select
                className={classes.pageSizeWidth}
                variant="outlined"
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                defaultValue={selectedPageSize}
                onChange={handlePageSizeClick}
              >
                {[10, 25, 50, 75, 100].map((value) => (
                  <MenuItem value={value}>{value}</MenuItem>
                ))}
              </Select>
            </Grid>
          </>
        )}
      </Grid>
      { activeTabIndex === 0 && <TableContainer
        data={data}
        routeParam={type}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        sortOrder={sortOrder}
        sortColumn={sortColumn}
        onSort={handleSorting}
      />}
      {(!isLoading || !pendingLoader) && activeTabIndex === 1 && (
        <PendingTable
          setLoader={setPendingLoader}
          query={debouncedQuery}
          companyName={user.company?.name}
          selectedPageSize={selectedPageSize}
        />
      )}
      {(isLoading || pendingLoader) && (
        <Grid container className={classes.tableReplaceGrid} item xs={12}>
          <Grid item xs={1}>
            <CircularProgress className='no-user-text' />
          </Grid>
        </Grid>
      )}

      {!isLoading && data && data.dashboardData && data.dashboardData.length === 0 && (
        <Typography variant='h6' className={classes.noRecords}>
          {' '}
          No records present
        </Typography>
      )}
    </Grid>
  );
};

export default DashboardMainView;