import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type graphSlice = {
  dateIntervalFrom: Date | null;
  dateIntervalTo: Date | null;
};

export const initialState: graphSlice = {
  dateIntervalFrom: null,
  dateIntervalTo: new Date()
};

const slice = createSlice({
  name: "graph",
  initialState,
  reducers: {
    setDateIntervalFrom: (state: graphSlice, action: PayloadAction<any>) => {
      state.dateIntervalFrom = action.payload;
    },
    setDateIntervalTo: (state: graphSlice, action: PayloadAction<any>) => {
      state.dateIntervalTo = action.payload;
    },
  },
});

export const { setDateIntervalFrom, setDateIntervalTo } = slice.actions;

export default slice.reducer;
