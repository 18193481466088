import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import InteractionStateRow from './InteractionStateRow';
import InteractionTypeRow from './InteractionTypeRow';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

type Props = {
  data: any;
  index: number;
  studentTable: boolean;
};

function TableDataCell({ data, index, studentTable }: Props) {
  const {
    id,
    engagedUsers,
    firstName,
    lastName,
    company,
    jobTitle,
    interactionTypeInfo,
    interactionStateInfo,
    interactionTypeInfo_PROFESSIONAL,
    interactionTypeInfo_ASSOCIATE,
    interactionStateInfo_PROFESSIONAL,
    interactionStateInfo_ASSOCIATE,
    createdAt,
    lastInteractionDate,
  } = data;
  const styleObject = index % 2 !== 0 ? { backgroundColor: '#F2F3FF' } : undefined;
  return (
    <TableRow key={+index}>
      <TableCell>
        <Link className='user-name-link' to={`/users/${id}`}>
          {firstName ? `${firstName} ${lastName ? lastName : ''}` : '--'}
        </Link>
      </TableCell>
      <TableCell>
          {company}
      </TableCell>
      <TableCell>
          {jobTitle}
      </TableCell>
      {!data?.roles?.includes('ALUMNI') && interactionStateInfo && <InteractionStateRow {...interactionStateInfo} index={index} />}
      {!data?.roles?.includes('ALUMNI') && interactionStateInfo_ASSOCIATE && <InteractionStateRow {...interactionStateInfo_ASSOCIATE} index={index} />}
      {data?.roles?.includes('ALUMNI') && data.company && <InteractionStateRow {...interactionStateInfo_PROFESSIONAL} index={index} />}
      {data?.roles?.includes('ALUMNI') && !data.company && <InteractionStateRow {...interactionStateInfo_ASSOCIATE} index={index} />}
      <TableCell style={styleObject}>{!isNaN(engagedUsers) ? engagedUsers : '--'}</TableCell>
      {studentTable && (
        <>
          <TableCell style={styleObject}>
            {createdAt ? format(new Date(createdAt._seconds * 1000), 'dd.MM.yyyy') : '--'}
          </TableCell>
          <TableCell style={styleObject}>
            {lastInteractionDate
              ? format(new Date(lastInteractionDate._seconds * 1000), 'dd.MM.yyyy')
              : '--'}
          </TableCell>
        </>
      )}
      {!data?.roles?.includes('ALUMNI') && <InteractionTypeRow {...interactionTypeInfo} index={index} />}
      {data?.roles?.includes('ALUMNI') && data.company && <InteractionTypeRow {...interactionTypeInfo_PROFESSIONAL} index={index} />}
      {data?.roles?.includes('ALUMNI') && !data.company && <InteractionTypeRow {...interactionTypeInfo_ASSOCIATE} index={index} />}
    </TableRow>
  );
}

export default TableDataCell;
