import { UserType } from '../../types';

export const getFilterForQuery = (user: any) => {
  let filters = '';
  if (user) {
    if (user.roles.includes(UserType.EMPLOYER)) {
      return `company.name:"${user.company.name}"`;
    }
    if (user.roles.includes(UserType.UNIVERSITY_ADMIN)) {
      return `university.name:"${user.university.name}"`;
    }
  }
  return filters;
};

export const getURLForUserType = (userRoles: string, baseUrl: string, urlParam?: string) => {
  if (userRoles) {
    if (userRoles.includes(UserType.EMPLOYER)) {
      return `${baseUrl}/fetchEmployees`;
    }
    if (userRoles.includes(UserType.UNIVERSITY_ADMIN)) {
      return `${baseUrl}/fetchStudents`;
    }
  }
  return baseUrl;
};
