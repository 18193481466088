import { InteractionState, InteractionType, UserType } from "types";

const TypeBasedStateComponent: any = {
    [InteractionState.REQUESTED]: {
        [UserType.STUDENT]: <p className="text-capitalize">Requested</p>,
        [UserType.PROFESSIONAL]: <p>View Request</p>,
    },
    [InteractionState.ACCEPTED]: {
        [UserType.STUDENT]: <p>Request accepted, awaiting suggested times</p>,
        [UserType.PROFESSIONAL]: <p>Request accepted</p>,
    },
    [InteractionState.DECLINED]: {
        [UserType.STUDENT]: <p>Declined</p>,
        [UserType.PROFESSIONAL]: <p>Declined</p>,
    },
    [InteractionState.SCHEDULING_TIMES_REQUESTED]: {
        [UserType.STUDENT]: <p>Scheduling times requested</p>,
        [UserType.PROFESSIONAL]: <p>Scheduling times requested</p>,
    },
    [InteractionState.SCHEDULING_TIMES_SUGGESTED]: {
        [UserType.STUDENT]: <p>Times suggested - select a time</p>,
        [UserType.PROFESSIONAL]: <p>Scheduling times suggested</p>,
    },
    [InteractionState.SCHEDULING_ADDITIONAL_TIMES_REQUESTED]: {
        [UserType.STUDENT]: <p>Scheduling additional times requested</p>,
        [UserType.PROFESSIONAL]: <p>Scheduling additional times requested</p>,
    },
    [InteractionState.SCHEDULED]: {
        [UserType.STUDENT]: <p>Scheduled</p>,
        [UserType.PROFESSIONAL]: <p>Scheduled</p>,
    },
    [InteractionState.CALL_DETAILS_SET]: {
        [UserType.STUDENT]: <p>Call details set</p>,
        [UserType.PROFESSIONAL]: <p>Call details set</p>,
    },
    [InteractionState.RESCHEDULE_REQUESTED]: {
        [UserType.STUDENT]: <p>Requested Reschedule</p>,
        [UserType.PROFESSIONAL]: <p>Requested Reschedule</p>,
    },
    [InteractionState.COMPLETED]: {
        [UserType.STUDENT]: <p>Completed</p>,
        [UserType.PROFESSIONAL]: <p>Completed</p>,
    },
    [InteractionState.EXPIRED]: {
        [UserType.STUDENT]: <p>Expired</p>,
        [UserType.PROFESSIONAL]: <p>Expired</p>,
    },
    // created new for interactionComplete
    [InteractionState.INTERACTIONCOMPLETED]: {
        [UserType.STUDENT]: (
            <p className="status-para">Complete</p>
        ),
        [UserType.PROFESSIONAL]: <p className="status-para">Complete</p>,
    },
    // created new for interactionComplete
    [InteractionState.INTERACTION_NOT_OVER]: {
        [UserType.STUDENT]: (
            <p className="status-para">Not Over</p>
        ),
        [UserType.PROFESSIONAL]: <p className="status-para">Not Over</p>,
    },
    [InteractionState.INT_NO_SHOW]: {
        [UserType.STUDENT]: <p className="status-para">Complete</p>,
        [UserType.PROFESSIONAL]: <p className="status-para">Complete</p>,
      },
      [InteractionState.INT_NO_SHOW_COMPLETE]: {
        [UserType.STUDENT]: <p className="status-para">Complete</p>,
        [UserType.PROFESSIONAL]: <p className="status-para">Complete</p>,
      },
      [InteractionState.SORRY_MODAL_CLOSE]: {
        [UserType.STUDENT]: <p className="status-para">Complete</p>,
        [UserType.PROFESSIONAL]: <p className="status-para">Complete</p>,
      },
      [InteractionState.APPOLOGY_MODAL_CLOSE]: {
        [UserType.STUDENT]: <p className="status-para">Complete</p>,
        [UserType.PROFESSIONAL]: <p className="status-para">Complete</p>,
      },
}

const TypeBasedStateComponentForExpertAdvice: any = {
    [InteractionType.EXPERT_ADVICE]: {
        [InteractionState.REQUESTED]: {
            [UserType.STUDENT]: <p className="text-capitalize">Requested</p>,
            [UserType.PROFESSIONAL]: <p> Request received, please respond</p>,
        },
        [InteractionState.ACCEPTED]: {
            [UserType.STUDENT]: <p>Accepted - awaiting response</p>,
            [UserType.PROFESSIONAL]: <p>Response sent, awaiting response</p>,
        },
        [InteractionState.DECLINED]: {
            [UserType.STUDENT]: <p>Rejected</p>,
            [UserType.PROFESSIONAL]: <p>Completed - Rejected</p>,
        },
        [InteractionState.COMPLETED]: {
            [UserType.STUDENT]: <p>Completed</p>,
            [UserType.PROFESSIONAL]: <p>Completed</p>,
        },
        [InteractionState.EXPIRED]: {
            [UserType.STUDENT]: <p>Expired</p>,
            [UserType.PROFESSIONAL]: <p>Expired</p>,
        },
        [InteractionState.INTERACTIONCOMPLETED]: {
            [UserType.STUDENT]: <p className="status-para">Completed</p>,
            [UserType.PROFESSIONAL]: <p className="status-para">Completed</p>,
        },

        // not over
        [InteractionState.INTERACTION_NOT_OVER]: {
            [UserType.STUDENT]: <p className="status-para">Not Over</p>,
            [UserType.PROFESSIONAL]: <p className="status-para">Not Over</p>,
        },
    },
}

type Props = {
    interactionState: InteractionState;
    userType: UserType;
    interactionType: InteractionType;
};

const InteractionStateWidgetFactory = ({ interactionState, userType, interactionType }: Props) => {
    if (interactionType !== InteractionType.EXPERT_ADVICE) {
        return TypeBasedStateComponent[interactionState][userType];
    } else {
        return TypeBasedStateComponentForExpertAdvice[interactionType][interactionState][userType]
    }
}

export default InteractionStateWidgetFactory;