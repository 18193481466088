import { Tab } from '@mui/material';
import { Children } from 'react';
import { useStyle } from './style';

type TabPanelProps = {
  children?: React.ReactNode;
  value: number;
  handleChange: (value: number) => void;
};

const ProfessionalsStatusTabs = ({ value, children, handleChange }: TabPanelProps) => {
  const classes = useStyle();
  return (
    <>
      {Children.toArray(
        ['ONBOARDED', 'PENDING'].map((tab, index) => (
          <Tab
            label={tab}
            className={value === index ? classes.activeTab : classes.tab}
            onClick={() => handleChange(index)}
          />
        )),
      )}
    </>
  );
};

export default ProfessionalsStatusTabs;
