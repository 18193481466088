import { Grid } from '@mui/material';
import { ChangeEvent } from 'react';
import { Pagination } from 'app/components';
import { useStyles } from '../Professionals/styles';
import InteractionStatsTable from '../../InteractionStatsTable';
import SupportReceivedTable from './SupportReceivedTable';
import { AlumniAvailableFilters } from '.';

type PropsType = {
  data: any;
  currentPage: number;
  handlePageChange: (event: ChangeEvent<unknown>, pageNo: number) => void;
  sortOrder?: string;
  sortColumn?: string;
  onSort: (c: string) => void;
  selectedAlumniFilter?: string;
};

const TableContainer = ({
  data,
  currentPage,
  handlePageChange,
  sortOrder,
  sortColumn,
  onSort,
  selectedAlumniFilter,
}: PropsType) => {
  const classes = useStyles();
  return (
    <Grid item xs={10} className={classes.mt2}>
      {selectedAlumniFilter === AlumniAvailableFilters.SUPPORT_GIVEN ?
        <InteractionStatsTable
          firstColumnTitle={'EMPLOYEE DETAILS'}
          engagedColumnTitle={'STUDENTS ENGAGED'}
          tableData={data && data.dashboardData ? data.dashboardData : []}
          studentTable={false}
          sortOrder={sortOrder}
          sortColumn={sortColumn}
          onSort={onSort}
        /> : <SupportReceivedTable
          tableData={data && data.dashboardData ? data.dashboardData : []}
          studentTable={false}
          sortOrder={sortOrder}
          sortColumn={sortColumn}
          onSort={onSort} />}
      <Pagination onChange={handlePageChange} page={currentPage} count={data.noOfPagesAvailable} />
    </Grid>
  );
};

export default TableContainer;
