import { useSelector } from 'react-redux';
import { selectUser } from 'redux/auth/selectors';
import { UserType } from 'types';
import EntityEngagedTable from '../TopEngaged';

const employerTableHead = ['EMPLOYER NAME', 'TOTAL INTERACTIONS', 'NO. OF PROFESSIONALS'];

const EmployersEngagedTable = () => {
  const user = useSelector(selectUser);

  if (!user) return null;
  return (
    <EntityEngagedTable
      tableHead={employerTableHead}
      backLinkTitle={'TOP EMPLOYERS ENGAGED'}
      userRoles={[UserType.UNIVERSITY_ADMIN]}
      url={'/dashboard/fetchEmployersEngaged'}
      organizationType={'UNIVERSITY'}
      organizationId={user.university.id}
    />
  );
};

export default EmployersEngagedTable;
