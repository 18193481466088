import { Box, Button, Grid, Typography } from '@mui/material';
import { format } from 'date-fns/esm';
import { useStyles } from './style';

type PendingProfessional = {
  id: string;
  createdAt: any;
};

type Props = {
  pendingProfessional: any;
  isLoading: boolean;
  handleDelete: (x: string) => void;
};

const PendingProfessinalCard = ({ pendingProfessional, isLoading, handleDelete }: Props) => {
  const { boxPadding, dateText, deleteButton, nameText, mt1 } = useStyles();
  const { id, createdAt }: PendingProfessional = pendingProfessional;

  return (
    <Grid item xs={11} className={mt1}>
      <Box boxShadow={0} bgcolor='background.paper' className={boxPadding}>
        <Grid container alignItems='center'>
          <Grid item xs={5}>
            <Typography className={nameText}>{id}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography className={dateText}>
              Request sent on{'   '}
              {createdAt ? format(new Date(createdAt._seconds * 1000), 'PPP') : '--'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant='outlined'
              disabled={isLoading}
              className={deleteButton}
              onClick={() => handleDelete(id)}
            >
              Cancel Invite
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default PendingProfessinalCard;
