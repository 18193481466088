import { Dispatch, SetStateAction } from 'react';
import { Avatar, Grid } from '@mui/material';
import genericProfileLogo from 'assets/avatar.svg';

type PropsType = {
  setProfileDropdownOpen: Dispatch<SetStateAction<boolean>>;
};

const ProfileImage = ({ setProfileDropdownOpen }: PropsType) => {
  return (
    <Grid
      item
      sm={1}
      md={1}
      className='nav-links'
      onMouseEnter={() => setProfileDropdownOpen(true)}
      onMouseLeave={() => setProfileDropdownOpen(false)}
    >
      <Avatar
        onClick={() => setProfileDropdownOpen((prevVal) => !prevVal)}
        className='cursor-pointer avatar-white'
        alt=''
        src={genericProfileLogo}
      />
    </Grid>
  );
};

export default ProfileImage;
