import { CircularProgress, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useReactQuery } from '../../../utils/hooks/useReactQuery';
import Pagination from '../../components/Pagination';
import TopEngagedTable from './TopEngagedTable';
import { useStyles } from './styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from 'react-router-dom';

type PropsType = {
  tableHead: string[];
  backLinkTitle: string;
  url: string;
  userRoles: string[];
  organizationType?: string;
  organizationId?: string;
};

const EntityEngagedTable = ({
  tableHead,
  backLinkTitle,
  url,
  userRoles,
  organizationType,
  organizationId,
}: PropsType) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const classes = useStyles();

  useEffect(() => {
    setCurrentPage(1);
  }, [url]);

  const { data, isLoading, error } = useReactQuery(['EngagedData', currentPage, url], {
    url,
    params: {
      query: '',
      pageNo: currentPage,
      pageSize: 15,
      filters: '',
      organizationType,
      organizationId,
    },
  });
  const handlePageChange = (_event: React.ChangeEvent<unknown>, pageNo: number) => {
    setCurrentPage(pageNo);
  };

  if (error)
    return (
      <Typography variant='h6' className={classes.error}>
        Error fetching records
      </Typography>
    );

  return (
    <Grid container justifyContent='center' className={classes.mt5}>
      <Grid item xs={8} alignItems='stretch' justifyContent='flex-start' className={classes.mb}>
        <ArrowBackIosIcon fontSize='small' />
        <Link to='/home' className={classes.back}>
          {backLinkTitle}
        </Link>
      </Grid>
      {!isLoading && (
        <Grid item xs={8} className={classes.mb}>
          <TopEngagedTable
            userRoles={userRoles}
            tableHeadTitles={tableHead}
            tableData={data ? data.dashboardData : []}
          />
          <Pagination
            onChange={handlePageChange}
            page={currentPage}
            count={data.noOfPagesAvailable}
          />
        </Grid>
      )}
      {isLoading && (
        <Grid container className={classes.tableReplaceGrid} item xs={12}>
          <Grid item xs={1}>
            <CircularProgress className='no-user-text'/>
          </Grid>
        </Grid>
      )}

      {!isLoading && data && data.dashboardData && data.dashboardData.length === 0 && (
        <Typography variant='h6' className={classes.noRecords}>
          {' '}
          No records present
        </Typography>
      )}
    </Grid>
  );
};

export default EntityEngagedTable;
