import React, { Children } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableDataCell from './TableDataCell';
import { useStyles } from './styles';
import { Table } from 'app/components';
const employeeDetails: string[] = ['Employee Name', 'Company', 'Job Title', "Date", "Action"];
type PropsType = {
  tableData: any[];
};
function DeactivateInteractionStatsTable({
  tableData = [],
}: PropsType) {
  const classes: any = useStyles();
  return (
    <>
      <Table
        tableHeader={
          <>
            <TableRow>
              {React.Children.toArray(
                employeeDetails.map((employeeDetails: any) => (
                  <TableCell
                    align='center'
                    className={`${classes[employeeDetails.toLowerCase()]} ${classes.Task}`}
                  >
                    {employeeDetails}
                    {employeeDetails === 'Employee Name'}
                  </TableCell>
                )),
              )}
            </TableRow>
          </>
        }
        tableBody={
          <>
            {tableData && Children.toArray(
              tableData
                .map((data: any, index: number) => (
                  <TableDataCell data={data} index={index} />
                )),
            )}
          </>
        }
      />
    </>
  );
}
export default DeactivateInteractionStatsTable;