import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectOnLoginRedirectRoute } from 'redux/auth/selectors';
import { setAuthUser, setIsDashboardLoading } from 'redux/auth/slice';
import firebase from 'utils/firebase';

const AuthGateway = ({ children }: any) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onLoginRedirectRoute: string | null = useSelector(selectOnLoginRedirectRoute);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        dispatch(
          setAuthUser({
            id: user.uid,
            email: user.email ?? '',
            emailVerified: user.emailVerified,
          }),
        );
        dispatch({
          type: 'TRIGGER_ON_LOGIN_SYNC',
          payload: { id: user.uid, email: user.email },
        });
      } else {
        dispatch(setAuthUser(null));
        dispatch(setIsDashboardLoading(false));
      }
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onLoginRedirectRoute) history.push(onLoginRedirectRoute);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLoginRedirectRoute]);

  return <>{children}</>;
};

export default AuthGateway;
