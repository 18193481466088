import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Company, Sector, UserType } from 'types';
import getAxiosInstance from 'utils/axios';
import useDebounce from 'utils/hooks/useDebounce';
import TableContainer from './TableContainer';
import OverviewChart from 'app/containers/HomePage/OverviewChart';
import { useReactQuery } from 'utils/hooks/useReactQuery';
import { Filter, TextField } from 'app/components';
import OverviewSection from 'app/containers/HomePage/OverviewTab';
import { AiOutlineDownload } from 'react-icons/ai';
import { useStyles } from './styles';
import { showToast, ToastType } from 'app/components/Toast/slice';
import { useDispatch } from 'react-redux';
import { sortColumnToAlgoliaFieldMapping } from 'utils/constants/sortColumnToAlgoliaFieldMapping';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { CSVDataType, DashboardDataType } from './types';
import { useHistory } from 'react-router-dom';

type PropsType = {
  prevTabfilter: string;
  setPrevTabFilter: Dispatch<SetStateAction<string>>;
};
const AllProfessionals = ({ prevTabfilter, setPrevTabFilter }: PropsType) => {
  const [query, setQuery] = useState<string>('');
  const [overviewData, setOverviewData] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [employers, setEmployers] = useState<Company[]>([]);
  const [sectors, setSectors] = useState<Sector[]>([]);
  const [selectedEmployer, setSelectedEmployer] = useState<any>();
  const [selectedSector, setSelectedSector] = useState<any>();
  const [initialDataLoading, setInitialDataLoading] = useState<boolean>(false);
  const [professionalsWithNoInteractions, setProfessionalsWithNoInteractions] = useState<number>()
  const [professionalsJoinedMoreThanAMonthAgoWithNoInteractions, setProfessionalsJoinedMoreThanAMonthAgoWithNoInteractions] = useState<number>()
  const [chartData, setChartData] = useState<any>();
  const [csvData, setCSVData] = useState<CSVDataType[]>([]);
  const [sortColumn, setSortColumn] = useState('Total');
  const [sortOrder, setSortOrder] = useState('desc');
  const [selectedPageSize, setSelectedPageSize] = useState<any>(10);
  const csvButtonRef: any = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  const debouncedQuery = useDebounce(query, 300);
  const classes = useStyles();
  useEffect(() => {
    fetchAllEmployers();
    const tempEmployer = prevTabfilter ? { name: prevTabfilter } : '';
    setCurrentPage(1);
    setSelectedEmployer(tempEmployer);
    setPrevTabFilter('');
    // eslint-disable-next-line react-hooks/exhaustive-deps    
    // getting values from session storage    
    const getEmployerDetail = sessionStorage.getItem('selectedEmployer');
    getEmployerDetail && setSelectedEmployer(JSON.parse(getEmployerDetail));
  }, [prevTabfilter,setPrevTabFilter]);
  useEffect(() => {
    fetchAllSectors();
    const tempSector = prevTabfilter ? { name: prevTabfilter } : '';
    setCurrentPage(1);
    setSelectedSector(tempSector);
    setPrevTabFilter('');
    // eslint-disable-next-line react-hooks/exhaustive-deps    
    // getting values from session storage    
    const getSectorDetail = sessionStorage.getItem('selectedSector');
    getSectorDetail && setSelectedSector(JSON.parse(getSectorDetail));
  }, [prevTabfilter,setPrevTabFilter]);
  useEffect(() => {
    (async () => {
      const employerName = selectedEmployer?.name ? selectedEmployer.name : prevTabfilter;
      await Promise.all([getOverviewData(employerName), getChartData(employerName)]);
    })();
  }, [selectedEmployer, prevTabfilter]);
  const { data, isLoading, error } = useReactQuery(
    ['allProfessionals', currentPage, debouncedQuery, selectedEmployer,selectedSector, sortColumn, sortOrder, selectedPageSize],
    {
      url: '/dashboard/fetchEmployees',
      params: {
        query: debouncedQuery,
        pageNo: currentPage,
        pageSize: selectedPageSize,
        filters  : (selectedEmployer?.name && selectedSector?.name) ? `(company.name:"${selectedEmployer.name}") AND  (sectors.name:"${selectedSector?.name}")` : (selectedEmployer?.name && !selectedSector?.name) ? `(company.name:"${selectedEmployer.name}")` : (!selectedEmployer?.name && selectedSector?.name) ? `sectors.name:"${selectedSector?.name}"` : '',
        sortColumn: sortColumnToAlgoliaFieldMapping[sortColumn as string],
        sortOrder: sortOrder.toUpperCase(),
        status: 'onboarded',
      },
    },
  );
  useEffect(() => {
    const aMonthAgo = new Date();
    aMonthAgo.setMonth(aMonthAgo.getMonth() - 1);
    let professionalsWithZeroInteractions = 0    
    let professionalsJoinedMoreThanAMonthAgo = 0   
     data?.completeData?.forEach((user: any) => {
      if(user.interactionStateInfo['TOTAL'] === 0){
        professionalsWithZeroInteractions += 1        
        const createdAt = new Date(user?.createdAt?._seconds * 1000)
        if(+aMonthAgo > +createdAt) professionalsJoinedMoreThanAMonthAgo += 1      }
    })
    setProfessionalsWithNoInteractions(professionalsWithZeroInteractions)
    setProfessionalsJoinedMoreThanAMonthAgoWithNoInteractions(professionalsJoinedMoreThanAMonthAgo)
  },[data])
  const csvdata: DashboardDataType[] = data?.completeData;
  const handlePageChange = (event: React.ChangeEvent<unknown>, pageNo: number) => {
    setCurrentPage(pageNo);
  };
  const handleDownloadCSVClicked = async () => {
    try {
      let CSVData: CSVDataType[] = [];
      csvdata.forEach((csv: DashboardDataType) => {
        CSVData.push({
          'Email': csv.email,
          'First Name': csv.firstName,
          'Last Name': csv.lastName,
          'Company':csv.company,
          'Job Title':csv.jobTitle,
          'interactionStateInfo/TOTAL': csv.interactionStateInfo.TOTAL,
          'interactionStateInfo/LIVE': csv.interactionStateInfo.LIVE,
          'interactionStateInfo/COMPLETED': csv.interactionStateInfo.COMPLETED,
          'interactionStateInfo/REQUESTED': csv.interactionStateInfo.REQUESTED,
          'interactionStateInfo/REJECTED': csv.interactionStateInfo.DECLINED,
          'interactionStateInfo/EXPIRED': csv.interactionStateInfo.EXPIRED,
          'Students Engaged': csv.engagedUsers,
          'interactionTypeInfo/CAREER_CHAT': csv.interactionTypeInfo.CAREER_CHAT,
          'interactionTypeInfo/EXPERT_ADVICE': csv.interactionTypeInfo.EXPERT_ADVICE,
          'interactionTypeInfo/MOCK_INTERVIEW': csv.interactionTypeInfo.MOCK_INTERVIEW,
        })
      })
      setCSVData((prevData: CSVDataType[]) => {
        setTimeout(() => {
          csvButtonRef.current.link.click();
          dispatch(
            showToast({
              type: ToastType.SUCCESS,
              message: 'CSV Downloaded Successfully',
            }),
          );
        }, 500);
        return CSVData;
      });
    } catch (error) {
      console.log(error);
      dispatch(
        showToast({
          type: ToastType.ERROR,
          message: 'Error occurred while downloading csv',
        }),
      );
    }
  };
  const fetchAllEmployers = async () => {
    try {
      setInitialDataLoading(true);
      const axios = await getAxiosInstance();
      const { data: employerData } = await axios.get('/dashboard/fetchEmployers');
      setEmployers(employerData.allEmployers);
      } catch (error) {
      console.log(error);
    } finally {
      setInitialDataLoading(false);
    }
  };
  const fetchAllSectors = async () => {
    try {
      setInitialDataLoading(true);
      const axios = await getAxiosInstance();
      const { data: sectorData } = await axios.get('/dashboard/fetchSectors');
      setSectors(sectorData.allSectors);
    } catch (error) {
      console.log(error);
    } finally {
      setInitialDataLoading(false);
    }
  };
  const getOverviewData = async (employerName: string) => {
    try {
      const axios = await getAxiosInstance();
      const { data: employerOverviewData } = await axios.get('/dashboard/homePageData', {
        params: {
          userRoles: [UserType.UPREACH_ADMIN],
          companyName: employerName,
        },
      });
      setOverviewData(employerOverviewData.overview.professional);
    } catch (error) {
      console.log(error);
    }
  };
  const getChartData = async (companyName: string) => {
    const axios = await getAxiosInstance();
    try {
      setInitialDataLoading(true);
      const { data } = await axios.get('/dashboard/getChartData', {
        params: {
          orgType: 'COMPANY',
          companyName,
        },
      });
      setChartData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setInitialDataLoading(false);
    }
  };
  const handleSorting = (column: string) => {
    if (sortColumn !== column) {
      setSortColumn(column);
      setSortOrder('asc');
    } else {
      const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
      setSortOrder(newSortOrder);
    }
  };
  const handlePageSizeClick = (event: SelectChangeEvent<string>) => {
    setSelectedPageSize(event.target.value as string);
  };
  //setting values to local storage for filter   
  const setEmployerDetail = (employer : any) => {
    sessionStorage.setItem('selectedEmployer',JSON.stringify(employer))
    setSelectedEmployer(employer)
  }
  const setSectorDetail = (sector:any) => {
    sessionStorage.setItem('selectedSector',JSON.stringify(sector))
    setSelectedSector(sector)
  }
  //Session clear on individual filter  
  const handleClearFilterForEmployer = ()=>{
    setSelectedEmployer('');
    sessionStorage.removeItem("selectedEmployer")
  } 
  const handleClearFilterForSector =()=>{
    setSelectedSector('');
    sessionStorage.removeItem("selectedSector")
  }
  
  if (error)
    return (
      <Typography variant='h6' className={classes.error}>        
       Error fetching records      
      </Typography>    
      );
  
    const handleRoute=()=>{
      history.push("/activate");
     }

  return (
    <Grid container justifyContent='center' className={classes.pd5}>
      <Grid container item xs={10} justifyContent='space-between' alignItems='flex-end'>
        <Filter
          filterTitle={'Filter professionals working in'}
          placeholder={'Company'}
          value={selectedEmployer}
          options={employers}
          onChange={(employer) => (employer ? setEmployerDetail(employer) : undefined)}
          onClear={() => handleClearFilterForEmployer()}
        />
        <Filter
          filterTitle={'Filter professionals working in'}
          placeholder={'Sector'}
          value={selectedSector}
          options={sectors}
          onChange={(sector) => (sector ? setSectorDetail(sector) : undefined)}
          onClear={() => handleClearFilterForSector()}
        />
        <Grid item xs={3}>
          <TextField
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            value={query}
            label={`Search Employee`}
          />
        </Grid>
      </Grid>
      {!isLoading && !initialDataLoading && chartData?.chartDataset && (
        <Grid item xs={10} className={classes.mt2}>
          <OverviewChart chartData={chartData?.chartDataset} />
        </Grid>
      )}
      {!isLoading && !initialDataLoading && overviewData && data && data.dashboardData && data.dashboardData.length > 0 && (
        <>
          <Grid item xs={10} className={classes.mt2}>
            <OverviewSection data={{ ...overviewData, professionalsWithNoInteractions, professionalsJoinedMoreThanAMonthAgoWithNoInteractions }} />
          </Grid>
          <Grid container item xs={10} justifyContent='flex-end'>
            <CSVLink
              style={{ visibility: 'hidden' }}
              data={csvData}
              filename={'professionals.csv'}
              ref={csvButtonRef}
            ></CSVLink>
            <div className={classes.buttonPageSizeContainer}>
              <div>
                <InputLabel id="demo-simple-select-outlined-label">Number of records to display</InputLabel>
                <Select
                  className={classes.pageSizeWidth}
                  variant="outlined"
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  defaultValue={selectedPageSize}
                  onChange={handlePageSizeClick}
                >
                  {[10, 25, 50, 75, 100].map((value) => (
                    <MenuItem value={value}>{value}</MenuItem>
                  ))}
                </Select>
              </div>
              <div>
                <Button className={classes.csvButton} onClick={handleRoute}>
                  Deactivated Professionals
                </Button>
                <Button className={classes.csvButton} onClick={handleDownloadCSVClicked}>
                  <AiOutlineDownload className={classes.downloadIcon} /> Download as CSV
                </Button>
              </div>
            </div>
          </Grid>
          <TableContainer
            data={data}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            sortOrder={sortOrder}
            sortColumn={sortColumn}
            onSort={handleSorting}
          />        
        </>
      )}
      {data && data.dashboardData && data.dashboardData.length === 0 &&
        <>
          <Grid container item xs={10} justifyContent='flex-end'>
            <Button className={classes.csvButton} onClick={handleRoute}>
              Deactivated Professionals
            </Button>
          </Grid>
        </>
        
      }
      {(isLoading || initialDataLoading) && (
        <Grid container className={classes.tableReplaceGrid} item xs={12}>
          <Grid item xs={1}>
            <CircularProgress className='no-user-text'/>
          </Grid>
        </Grid>
      )}
      {!isLoading && data && data.dashboardData && data.dashboardData.length === 0 && (
        <Grid item xs={12}>
          <Typography variant='h6' className={classes.noRecords} align='center'>
            {' '}
            No records present
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
export default AllProfessionals;