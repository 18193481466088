import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { UserType } from 'types';

type PropsType = {
  data: any;
  user: any;
};

const ImpactDataCard = ({ data, user }: PropsType) => {
  const classes = useStyle();

  return (
    <>
      <Typography variant='subtitle1' className={classes.typography}>
        <strong>Impact To Date</strong>
      </Typography>
      <Typography variant='subtitle1' className={classes.typography}>
        {data.studentsRegistered} Students Registered
      </Typography>
      <Typography variant='subtitle1' className={classes.typography}>
        {data.professionalsRegistered} Professionals Registered
      </Typography>
      {user.roles.includes(UserType.UNIVERSITY_ADMIN) && (
        <>
          <Typography variant='subtitle1' className={classes.typography}>
            {data.userBelongingToOrg} Students Registered From {user?.university.name}
          </Typography>
          <Typography variant='subtitle1' className={classes.typography}>
            {data.totalInteractions} Interactions By Students From {user?.university.name}
          </Typography>
        </>
      )}
      {user.roles.includes(UserType.EMPLOYER) && (
        <>
          <Typography variant='subtitle1' className={classes.typography}>
            {data.userBelongingToOrg} Employees Registered From {user?.company.name}
          </Typography>
          <Typography variant='subtitle1' className={classes.typography}>
            {data.totalInteractions} Total Interactions With {user?.company.name} Employees
          </Typography>
        </>
      )}
    </>
  );
};

export default ImpactDataCard;

const useStyle = makeStyles(() => ({
  typography: {
    marginBottom: '0.8em',
  },
}));
