import React from 'react';
// import styled from 'styled-components';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import styled from '@emotion/styled';

type PropsType = {
  title?: string;
  isOpen: boolean;
  onClose: any;
  children?: React.ReactNode;
};

const Modal = ({ title, isOpen, onClose, children }: PropsType) => {
  const classes = useStyle();
  return (
    <Dialog fullWidth maxWidth='sm' open={isOpen} onClose={onClose}>
      <DialogTitle className='text-capitalize request-modal-header'>
        <StyledHeader
          style={title ? { justifyContent: 'space-between' } : { justifyContent: 'flex-end' }}
        >
          {title}
          <button onClick={onClose} className={classes.button}>
            Close
          </button>
        </StyledHeader>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default Modal;

const StyledHeader = styled.div`
  display: flex;
  width: 100%;
`;

const useStyle = makeStyles(() => ({
  marginTop: {
    marginTop: '1em',
  },
  left: {
    marginLeft: '0.3em',
  },
  button: {
    border: '0px',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    color: '#464FD0',
    fontSize: '0.9em',
  },
}));
