import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  tableReplaceGrid: {
    height: '35vh',
    width: '100%',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
  noRecords: {
    marginTop: '6em',
  },
  error: {
    marginLeft: '45%',
    marginTop: '10%',
  },
  mt3: {
    marginTop: '0.5em',
  },
  mt5: {
    marginTop: '5em',
  },
  mb: {
    marginBottom: '3em',
  },
  back: {
    textDecoration: 'none !important',
    fontWeight: 'bolder',
    marginTop: '2em',
  },
  mt2: {
    marginTop: '2em',
  },
  pageSizeWidth: {
    width: '100%',
    maxWidth: '300px',
    marginTop: '0.5em',
  },
  toggleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  toggleDiv: {
    marginLeft: 'auto',
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  recordsDiv: {
    marginRight: 'auto',
  },
  toggleHeading: {
    fontSize: '18px',
    color : 'black'
  },
  csvButton: {
    color: '#464FD0',
    textTransform: 'none',
    textDecoration: 'underline',
    fontFamily: 'Nunito Sans',
    fontSize: '1.1em',
    paddingTop:'20px',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  },
  downloadIcon: {
    fontSize: '1.5em',
    marginRight: '0.2em',
  },


}));
