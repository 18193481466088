import React from 'react';
import styled from 'styled-components/macro';
import { Pagination as MaterialPagination } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AlgoliaBanner } from '..';

export interface Props {
  count: number;
  page: number;
  onChange: (event: React.ChangeEvent<unknown>, pageNo: number) => void;
}

const Pagination = ({ count, page, onChange }: Props) => {
  const classes = useStyles();
  return (
    <PaginationWrapper>
      <MaterialPagination
        count={count}
        page={page}
        onChange={onChange}
        shape='rounded'
        classes={{ ul: classes.ul }}
      />
      <AlgoliaBanner />
    </PaginationWrapper>
  );
};

export default Pagination;

const PaginationWrapper = styled.div`
  margin-top: 1.5em;

  .algolia-logo {
    margin: 30px auto auto auto;
    display: block;
  }
`;

const useStyles = makeStyles(() => ({
  ul: {
    '& .Mui-selected': {
      backgroundColor: '#304563',
      color: '#fff',
    },
  },
  mt: {
    marginTop: '3em',
  },
}));
