export const universityTableHeaderHomePage = [
  'UNIVERSITY NAME',
  'TOTAL INTERACTIONS',
  'NO. OF STUDENTS',
];
export const employerTableHeaderHomePage = [
  'EMPLOYER NAME',
  'TOTAL INTERACTIONS',
  'NO. OF PROFESSIONAL',
];
