import { Message } from "types";

type Props = {
  message: Message;
};

const ThankYouMessage = ({ message }: Props) => {
  if (message.sender === "SYSTEM") return null;
  return (
    <div className="interaction-texts">{message.payload}</div>
  )
};

export default ThankYouMessage;