import React from 'react';
import { Button, Card, CircularProgress, Grid, TextField } from '@mui/material';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { Company } from 'types';
import { ToastMessage, ToastType, showToast } from 'app/components/Toast/slice';
import getAxiosInstance from 'utils/axios';
import firebase from 'firebase';


type EmployerType = {
  name: string;
  professionalInviteLimit: string;
  description: string;
  company?: Company | null;
};

const TextFieldsData = [
  { label: 'Employer Name', disabled: false, field: 'name' },
  { label: 'Employer Description', disabled: false, field: 'description' },
  { label: 'Invite Limit', disabled: false, field: 'professionalInviteLimit' },
];


const AddCompany = () => {
  const [employers, setEmployers] = useState<EmployerType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [profileUrl, setProfileUrl] = useState<any>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [profileError, setProfileError] = useState<any>("");
  const [imageError, setImageError] = useState<boolean>(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: boolean }>({
    name: false,
    description: false,
    professionalInviteLimit: false,
  });

  useEffect(() => {
    const employer = [];
    for (let i = 0; i < 1; i++)
      employer.push({
        name: '',
        professionalInviteLimit: '',
        description: '',
      });
    setEmployers(employer);
  }, []);


  const handleFieldValueChange = (value: string | Company | File, index: number, field: string) => {
    const tempEmployersArray = [...employers];
    const tempEmployer = {
      ...employers[index],
      [field]: value,
    };
    tempEmployersArray[index] = tempEmployer;
    setEmployers(tempEmployersArray);
  };


const uploadPhoto = async (file: any, name: string) => {
  setIsLoading(true);

  try {
      if (employers[0].name !== null || employers[0].name !== "") {
          const name = employers[0].name;

          const img = new Image();
          img.src = URL.createObjectURL(file[0]);

          await new Promise(resolve => {
              img.onload = resolve;
          });

          const canvas = document.createElement('canvas');

          const ctx = canvas.getContext('2d');

          if (!ctx) {
              throw new Error("Unable to get 2D rendering context");
          }

          // Set canvas dimensions 250x100
          const desiredWidth = 250;
          const desiredHeight = 100;

          canvas.width = desiredWidth;
          canvas.height = desiredHeight;

          ctx.drawImage(img, 0, 0, desiredWidth, desiredHeight);

          canvas.toBlob(
              async (blob) => {
                  if (!blob) {
                      throw new Error("Unable to create Blob from canvas");
                  }

                  const firebaseStorageRef = firebase.storage().ref("companies/" + name + '/profile.jpg');
                  await firebaseStorageRef.put(blob);
                  console.log("Resized image successfully uploaded");
                  setProfileError(null);

                  const downloadURL = await firebaseStorageRef.getDownloadURL();

                  setIsLoading(false);
                  setProfileUrl(downloadURL);

                  return downloadURL;
              },
              'image/png, image/jpeg, image/jpg',
          );
      }
  } catch (error) {
      console.log("Error uploading image", error);
      setIsLoading(false);
      throw error;

  }
};


  const CheckValidPayload = () => {
    let isValid = true;

    const errors: { [key: string]: boolean } = {};

    for (let i = 0; i < employers.length; i++) {
      const employer = employers[i];

      if (!employer.name || !employer.description || !employer.professionalInviteLimit) {
        isValid = false;

        errors.name = !employer.name;
        errors.description = !employer.description;
        errors.professionalInviteLimit = !employer.professionalInviteLimit;

        break;
      }
    }

    setFieldErrors(errors);

    return isValid;
  };

  const resetFieldValues = () => {
    const tempEmployers: EmployerType[] = [];
    employers.forEach(() => {
      tempEmployers.push({
        name: '',
        description: '',
        professionalInviteLimit: '',
      });
    });
    setEmployers(tempEmployers);
  };

  const handleAddMembers = async () => {
    try {
      const isValidPayload = CheckValidPayload();

      if (!isValidPayload || !profileUrl) {
        const errors: { [key: string]: boolean } = {};

        for (let i = 0; i < employers.length; i++) {
          if (!employers[i].name) {
            errors.name = true;
          }

          if (!employers[i].description) {
            errors.description = true;
          }

          if (!employers[i].professionalInviteLimit) {
            errors.professionalInviteLimit = true;
          }
          setFieldErrors(errors);
          setImageError(true)
          return;
        }
      }

      const filteredEmployers = employers.filter((employer) => employer.name.trim() !== '');

      const axios = await getAxiosInstance();
      await axios.post('/companies/newemployer', {
        employers: filteredEmployers,
        imageUrl: profileUrl,
      });
      const toast: ToastMessage = {
        type: ToastType.SUCCESS,
        message: 'Employer added successfully',
      };
      dispatch(showToast(toast));
      resetFieldValues();
      history.push('/home');
    } catch (error: any) {
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: error?.response?.data?.message,
      };
      dispatch(showToast(toast));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Grid container justifyContent='flex-start' className={classes.container}>
      <Grid item xs={7} alignItems='stretch' justifyContent='flex-start'>
        <ArrowBackIosIcon fontSize='small' style={{ margin: '-0.2em' }} />
        <button
          onClick={() => history.push('/home')}
          className={`${classes.plainButtons} ${classes.back}`}
        >
          EMPLOYERS
        </button>
      </Grid>
      <Grid item xs={10} className={classes.marginTop3}>
        <Typography variant='subtitle1'>Add Employer</Typography>
      </Grid>
      <Grid item xs={11} className={classes.marginTop1}>
        <Card className={classes.shadow}>
          <Grid container className={classes.paddingContainer} justifyContent='space-evenly'>
            <Grid item xs={12} className={classes.marginTop1}>
              <Typography variant='subtitle1'>Employer Details</Typography>
            </Grid>
            {React.Children.toArray(
              employers.map((employer: EmployerType, index: number) => (
                <Grid container item xs={12} justifyContent='space-evenly' alignItems='center'>
                  <Grid item xs={6} className={classes.marginTop1}>
                    {/* Name */}
                    <TextField
                      required={true}
                      error={fieldErrors.name}
                      helperText={fieldErrors.name && "Company Name is required"}
                      disabled={TextFieldsData[0].disabled}
                      value={(employer as any)[TextFieldsData[0].field]}
                      onChange={(e) =>
                        handleFieldValueChange(
                          e.target.value
                            .replace(/^\s+/, '')
                            .replace(/\s+$/, ' '),
                          index,
                          TextFieldsData[0].field
                        )
                      }
                      id='outlined-multiline-static'
                      label={TextFieldsData[0].label}
                      variant='outlined'
                      className={classes.textFieldWidth}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.marginTop1}>
                    {/* Professional Invite Limit */}
                    <TextField
                      required={true}
                      error={fieldErrors.professionalInviteLimit}
                      helperText={fieldErrors.professionalInviteLimit && "Invite Limit is required"}
                      disabled={TextFieldsData[2].disabled}
                      value={(employer as any)[TextFieldsData[2].field]}
                      onChange={(e) =>
                        handleFieldValueChange(e.target.value, index, TextFieldsData[2].field)
                      }
                      id='outlined-multiline-static'
                      label={TextFieldsData[2].label}
                      variant='outlined'
                      className={classes.textFieldWidth}
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        onKeyPress: (e) => {
                          const isNumeric = /^[0-9]*$/;
                          if (!isNumeric.test(e.key)) {
                            e.preventDefault();
                          }
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              )),
            )}

            <Grid item xs={12} className={classes.marginTop3}>
              <Typography variant='subtitle1'>Student Resources Overview</Typography>
            </Grid>
            {React.Children.toArray(
              employers.map((employer: EmployerType, index: number) => (
                <Grid container item xs={12} justifyContent='space-evenly' alignItems='center'>
                  <Grid item xs={6} className={classes.marginTop1}>
                    {/* Description */}
                    <TextField
                      required={true}
                      error={fieldErrors.description}
                      helperText={fieldErrors.description && "Company Description is required"}
                      disabled={TextFieldsData[1].disabled}
                      value={(employer as any)[TextFieldsData[1].field]}
                      onChange={(e) =>
                        handleFieldValueChange(
                          e.target.value
                            .replace(/^\s+/, '')
                            .replace(/\s+$/, ' '),
                          index,
                          TextFieldsData[1].field
                        )
                      }
                      id='outlined-multiline-static'
                      label={TextFieldsData[1].label}
                      variant='outlined'
                      className={classes.textFieldWidth}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.marginTop1}>
                    {/* Image Upload */}
                    <div className="upload-section">
                      {isLoading ? (
                        <CircularProgress className="no-user-text" />
                      ) : (
                        <div className={classes.uploadSection}>
                          <Button
                            color="primary"
                            component="label"
                            className={classes.chooseFileButton}
                            disabled={!employers[0].name}
                          >
                            Choose File
                              <input
                                type="file"
                                hidden
                                accept="image/png, image/jpeg, image/jpg"
                                onChange={async (e) => {
                                  const selectedFile = e.target.files?.[0];

                                  if (selectedFile) {

                                    if (selectedFile.size > 5 * 1024 * 1024) {
                                      setProfileError("File size must be under 5MB.");
                                      setSelectedFile(null);
                                      setImageError(true); 
                                      return;
                                    }

                                    setSelectedFile(selectedFile);
                                    uploadPhoto(e.target.files, e.target.name);
                                    setProfileError("");
                                  }
                                }}
                              />
                          </Button>
                          <p className={`text-danger ${(!selectedFile && (!profileUrl && imageError)) ? classes.errorHighlight : ''}`}>
                            {profileError || (!selectedFile && 'no file selected (Ideal 250px x 100px)')}
                          </p>
                          <p className={`text-danger ${(!profileUrl && imageError && selectedFile) ? classes.errorHighlight : ''}`}>
                            {selectedFile ? selectedFile.name : ''}
                          </p>
                        </div>
                      )
                      }
                    </div>
                  </Grid>
                </Grid>
              )),
            )}
            
            <Grid item xs={12} className={classes.buttons}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleAddMembers}
                disabled={isLoading}
                className={classes.buttonMarginLeft}
              >
                Add Employer
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AddCompany;