import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  .filter-heading {
    margin: 0;
  }
  .filter-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 2px solid #464fd0;
    margin-bottom: 10px;
    margin-left: 10px;
  }
  .filter-name {
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 5px;
    font-size: 16px;
    color: #464fd0;
    font-weight: 600;
  }

  .caret-icon {
    margin-left: 15px;
    margin-top: 15px;
    border-left: 1px solid #c4c4c4;
  }

  .dropdown {
    position: absolute;
    top: 60px;
    width: 300px;
    min-height: 100px;
    max-height: 300px;
    z-index: 10;
    transform: translateX(-47.5%);
    padding: 1rem;
    overflow-y: scroll;
    padding: 15px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    transition: height 500ms ease;
  }
  .dropdown-item {
    cursor: pointer;
    background: #ffffff;
    border-bottom: 1px solid #c4c4c4;
    &:hover {
      background: #dee1ff;
      border-bottom: 1px solid #c4c4c4;
    }
    display: flex;
    align-items: center;
  }
  .dropdown-checkbox {
    margin-left: 10px;
  }
  .dropdown-text {
    margin-left: 15px;
    font-size: 15px;
  }
  .expand-btn {
    margin-left: auto;
    margin-right: 10px;
  }
  .goback-btn {
    margin-left: 10px;
  }
  .menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
  }
  .menu-primary-enter-active {
    transform: translateX(0%);
    transition: all 500ms ease;
  }
  .menu-primary-exit {
    position: absolute;
  }
  .menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all 500ms ease;
  }

  .menu-secondary-enter {
    transform: translateX(110%);
  }
  .menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all 500ms ease;
  }
  .menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all 500ms ease;
  }
`;
