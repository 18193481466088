import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
// import styled from 'styled-components';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import { InputType, LoginFormType } from './types';
import { useDispatch, useSelector } from 'react-redux';
import PasswordResetModal from './PasswordResetModal';
import { selectIsDashboardLoading } from 'redux/auth/selectors';
import styled from '@emotion/styled';

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm<LoginFormType>();
  const [passwordResetModalOpen, setPasswordResetModalOpen] = useState(false);
  const isDashboardLoading = useSelector(selectIsDashboardLoading);
  const [loginDisabled, setLoginDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (!isDashboardLoading) {
      setLoginDisabled(false);
    }
  }, [isDashboardLoading]);

  const onSubmit = (data: LoginFormType) => {
    setLoginDisabled(true);
    dispatch({ type: 'LOGIN_ATTEMPT', payload: data });
  };

  const openPasswordResetModal = () => {
    setPasswordResetModalOpen(true);
  };

  return (
    <StyledWrapper className='background-white'>
      <div className='w-100-percent'>
        <Container className='w-100-percent' maxWidth='xl'>
          <h1 className='center-align'>Login to admin dashboard.</h1>
          <Grid className='center-content' container>
            <Grid className='network-section login-section content-center' item xs={12} md={3}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  variant='outlined'
                  className='email-textfield'
                  name='email'
                  inputRef={register}
                  type={InputType.TEXT}
                  label='email'
                />{' '}
                <br />
                <TextField
                  variant='outlined'
                  name='password'
                  className='password-textfield'
                  inputRef={register}
                  type={InputType.PASSWORD}
                  label='password'
                />{' '}
                <br />
                <div className='forgot-password'>
                  <p onClick={() => openPasswordResetModal()}>Forgot your password?</p>
                </div>
                <Button
                  variant='contained'
                  size='large'
                  color='primary'
                  type='submit'
                  fullWidth
                  className='submit-btn'
                  disabled={loginDisabled}
                >
                  Login
                </Button>
              </form>
            </Grid>
          </Grid>
        </Container>
      </div>
      <PasswordResetModal
        passwordResetModalOpen={passwordResetModalOpen}
        setPasswordResetModalOpen={setPasswordResetModalOpen}
      />
    </StyledWrapper>
  );
};

export default Dashboard;

const StyledWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;

  .email-textfield {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .email-textfield,
  .password-textfield {
    width: 100%;
    max-width: 376px;
  }

  .submit-btn {
    height: 70px;
    width: 100%;
    max-width: 376px;
    border-radius: 4px;
    margin-top: 50px;
    margin-bottom: 50px;
    background-color : #3f51b5;
  }

  .submit-btn:hover{
    background-color: #303f9f
  }

  .center-content {
    justify-content: center;
  }

  .forgot-password {
    float: right;
    cursor: pointer;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
  }

  .forgot-password:hover {
    text-decoration: underline;
  }

  .center-align {
    text-align: center;
    color: #304563;
  }

  form {
    width: 100%;
  }
`;
