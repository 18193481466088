import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from '@mui/material';

type PropsType = {
  data: any;
};

const OverviewSection = ({ data }: PropsType) => {
  const classes = useStyles();
  const {
    limitToInviteProfessional,
    remainingLimitToInviteProfessional,
    invitedToJoin,
    onboarded,
    pending,
    professionalsWithNoInteractions,
    professionalsJoinedMoreThanAMonthAgoWithNoInteractions,
  } = data;

  return (
    <Grid container className={classes.mb}>
      <Grid item xs={12}>
        <Typography variant='h6'>Employee Overview</Typography>
      </Grid>
      <Grid container item xs={10} className={classes.mt1}>
        <Grid container item xs={2} className={classes.firstColumn}>
          <Grid item xs={6}>
            <Typography variant='body1' className={`${classes.columnData} ${classes.color1}`}>
              {!isNaN(invitedToJoin) ? invitedToJoin : '--'}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.columnTitle}>
            Invited to Join
          </Grid>
        </Grid>
        <Grid container item xs={3} className={classes.secondColumn}>
          <Grid item xs={6}>
            <Typography variant='body1' className={`${classes.columnData} ${classes.color2}`}>
              {!isNaN(onboarded) ? onboarded : '--'}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.columnTitle}>
            Onboarded members
          </Grid>
        </Grid>
        <Grid container item xs={3} className={classes.secondColumn}>
          <Grid item xs={6}>
            <Typography variant='body1' className={`${classes.columnData} ${classes.color3}`}>
              {!isNaN(pending) ? pending : '--'}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.columnTitle}>
            Pending Registration
          </Grid>
        </Grid>
        <Grid container item xs={3} className={classes.secondColumn}>
          <Grid item xs={6}>
            <Typography variant='body1' className={`${classes.columnData} ${classes.color2}`}>
              {!isNaN(professionalsWithNoInteractions) ? professionalsWithNoInteractions : '--'}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.columnTitle}>
            Professionals <br/> with 0 Interactions
          </Grid>
        </Grid>
        <Grid container item xs={3} className={classes.secondColumn}>
          <Grid item xs={6}>
            <Typography variant='body1' className={`${classes.columnData} ${classes.color3}`}>
              {!isNaN(professionalsJoinedMoreThanAMonthAgoWithNoInteractions) ? professionalsJoinedMoreThanAMonthAgoWithNoInteractions : '--'}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.columnTitle}>
            Professionals <br/> with 0 Interactions <br/> ( &gt; 1 Month onboard)
          </Grid>
        </Grid>
        {limitToInviteProfessional !== undefined &&
          remainingLimitToInviteProfessional !== undefined && (
            <Grid container item xs={3}>
              <Grid item xs={6}>
                <Typography variant='body1' className={`${classes.columnData} ${classes.color4}`}>
                  {!isNaN(remainingLimitToInviteProfessional)
                    ? remainingLimitToInviteProfessional
                    : '--'}
                  / {!isNaN(limitToInviteProfessional) ? limitToInviteProfessional : '--'}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.columnTitle}>
                SPACES REMAINING
              </Grid>
            </Grid>
          )}
      </Grid>
    </Grid>
  );
};

export default OverviewSection;

const useStyles = makeStyles(() => ({
  mt2: {
    marginTop: '2em',
  },
  mt1: {
    marginTop: '2em',
    maxWidth: '90%',
    flexBasis: '90%'
  },
  mb: {
    marginBottom: '3em',
  },
  columnTitle: {
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 1)',
  },
  columnData: {
    textAlign: 'center',
    fontSize: '2em',
  },
  firstColumn: {
    borderRight: '3px solid #9EA5EE',
    marginRight: '1.2em',
  },
  secondColumn: {
    maxWidth: '20%',
    flexBasis: '20%'
  },
  color1: {
    color: 'rgba(70, 79, 208, 1)',
  },
  color2: {
    color: 'rgba(0, 157, 144, 1)',
  },
  color3: {
    color: 'rgba(108, 99, 255, 1)',
  },
  color4: {
    color: '#0077B7',
  },
}));
