import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: #F6FAFF;
    font-family: Nunito Sans, sans-serif;
  }

  .App {
    min-height: 100%;
  }

  // spacing

  .mt-0 {
    margin-top: 0;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  .mt-2 {
    margin-top: 20px;
  }

  .mb-2 {
    margin-bottom: 20px;
  }

  .my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mx-2 {
    margin-left: 2px;
    margin-right: 2px;
  }
 
  .mx-4 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .mx-6 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .mr-auto {
    margin-right: auto;
  }

  .ml-auto {
    margin-left: auto !important;
  }

  .mx-auto {
    margin: auto;
  }

  // display

  .d-flex {
    display: flex;
  }

  .d-block {
    display: block;
  }


  // Typography

  h1, .heading-1 {
    font-size: 38px;
    line-height: 47px;

    @media (max-width: 600px) {
      font-size: 28px;
      line-height: normal;
    }
  }

  h2 {

    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
  }

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 110%;
    }

  .body-11 {
    font-size: 11px;
    line-height: 15px;
  }

  .body-12 {
    font-size: 12px;
    line-height: 18px;
  }

  .body-13 {
    font-size: 13px;
    line-height: 18px;
  }

  .body-14 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
  }

  .body-17 {
    font-weight: 600;
    font-size: 17px;
    line-height: 27px;
  }

  .body-18 {
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
  }

  .body-22 {
    font-size: 22px;
    font-weight: 600;
    line-height: 44px;
  }

  .body-24 {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;

    @media (max-width: 600px) {
      font-size: 18px;
      line-height: normal;
    }
  }

  .header-links {
    font-size: 15px;
    font-weight: 500;
    line-height: 50px;
    letter-spacing: 0px;
  }

  .interaction-title {
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0.5px;
    text-align: left;
  }

  .font-family-nunito {
    font-family: Nunito Sans, sans-serif !important;
  }

  // font-weight

  .font-weight-bold {
    font-weight: bold;
  }
    
  .font-weight-bolder {
    font-weight: bolder;
  }

  .font-weight-normal {
    font-weight: normal;
  }

  .font-weight-lighter {
    font-weight: lighter;
  }

  // cursor

  .cursor-pointer {
      cursor: pointer;
  }

  // space around even between

  .space-evenly {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
    
  .space-around {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
    
  .space-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .justify-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .float-left {
    float: left;
  }

  .float-right {
    float: right;
  }
  
  .content-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  // background colors

  .background-dark-blue {
    background-color: #464FD0;
  }

  .background-black {
    background-color: #000000;
  }

  .background-white {
    background-color: #FFFFFF;
  }

  .background-light-navy {
    background-color: #9EA5EE;
  }

  .background-card-blue {
    background-color: #DEE1FF;
  }
  
  .background-sea-blue {
    background-color: #5EB8D9;
  }

  .background-persian-green {
    background-color: #009D90;
  }
  
  .background-gulf-blue {
    background-color: #304563;
  }

  .background-ceru {
    background-color: #0077B7;
  }

  .background-white-smoke {
    background-color: #F7F7F7;
  }

  // image fluid

  .img-fluid {
    width: 100%;
    height: auto;
  }


  // text colors 

  .color-dark-blue {
    color: #464FD0;
  }

  .color-black {
    color: #000000;
  }

  .color-white {
    color: #FFFFFF;
  }

  .color-dark-blue {
    color: #464FD0;
  }

  .color-light-navy {
    color: #9EA5EE;
  }

  .color-card-blue {
    color: #DEE1FF;
  }

  .color-grey {
    color: #9F9D9D;
  }

  .color-gulf-blue {
    color: #304563;
  }

  

  //text alignment

  .text-center {
    text-align: center;
  }

  //text tranform

  .text-uppercase {
    text-transform: uppercase;
  }

  .text-capitalize {
    text-transform: capitalize;
  }

  // border radius

  .border-radius-5 {
    border-radius: 5px;
  }

  .MuiDrawer-paperAnchorLeft {
    width: 100%;
    background-color: #F6FAFF !important;
  }

  .request-modal-header {
    font-size: 1.25rem;
    line-height: 111.3%;
    color: #304563;
  }

  .request-modal-input .MuiFilledInput-multiline, .request-modal-input:hover .MuiFilledInput-multiline:focus {
    background: #F7F7F7 !important;
  }

  .action-section {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .no-interaction-message {
    font-weight: bold;
    font-size: 18px;
    line-height: 110%;
    color: #304563;
  }

  .object-fit-cover {
    object-fit: cover;
  }

  // User card styles 

    .usercard-link {
      text-decoration: none;
    }
  
    .profile-avatar {
      width: 60px;
      height: 60px;
    }
  
    .profile-info-spacing {
      margin-top: 10px;
      margin-bottom: 5px;
    }
  
    .usercard-name {
      line-height: 18px;
    }
  
    .available-for {
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 11px;
      letter-spacing: 0.5px;
      padding-right: 10px;
    }
  
    .available-for-details {
      font-size: 12px;
      line-height: 14px;
      /* display: flex; */
      align-items: center;
      color: #304563;
      margin: 10px 0px;
    }
  
    .view-btn {
      font-size: 13px !important;
      display: block !important;
      margin: 5px auto 0 auto !important;
      text-transform: capitalize !important;
    }
  
    .request-card {
      min-height: 150px;
      .MuiCardContent-root {
        padding-bottom: 8px !important;
      }
    }
  
    .divider {
      margin-top: 20px;
    }
  
    .bookmark {
      position: absolute;
      top: 10px;
      right: 15px;
      width: 12px;
    }

    .username-spacing {
      .card-height {
        height: 100%;
      }
    }
    .pwd-reset-dialog {
      .MuiDialog-paperWidthSm {
        padding: 30px 50px;

        @media (max-width: 600px) {
          padding: 30px 0px;
        }
      }

      #form-dialog-title h2, .MuiDialogContent-root p {
        font-family: Nunito Sans;
        font-weight: 400;
        color: #304563;
        letter-spacing: 0px;

      }

      #form-dialog-title h2 {
        font-size: 40px;
        line-height: 45px;
      }

      .MuiDialogContent-root p{
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 50px;
      }

      .reset-email {
        width: 100%;
        max-width: 375px;
        height: 50px;

        .MuiOutlinedInput-marginDense {
          height: 50px;
        }
      }


      .MuiOutlinedInput-notchedOutline .PrivateNotchedOutline-legendLabelled-7 {
          span {
            position: relative;
            top: 3px;
          }
        }

        .submitEmail {
          margin-right: auto;
          margin-left: 15px !important;
          width: 100%;
          max-width: 375px;
          height: 50px;
          background: #464FD0;

          @media (max-width: 600px) {
            margin-right: 15px !important;
          }
        }

        .cancel-popup {
          position: absolute;
          top: 20px;
          right: 20px;
        }
    }

    .profile-section {
      position: relative;
      left: 50px;

      .profile-avatar {
        position: relative;
        top: 18px;
      }

      .text-decoration-none {
        text-decoration: underline solid white;
        text-transform: capitalize;
      }

      .participant-details {
        margin-left: 20px;
      }
    }

    .interaction-modal {
      max-width: 60% !important;

      @media (max-width: 992px) {
        max-width: 100% !important;
      }
    }

    // Professional User registration form

    .registration-box-shadow {
      box-shadow: 0px 0px 20px -5px rgba(0,0,0,1);
      background-color: white;

    .MuiFormLabel-root {
      margin-bottom: 1rem;
    }

    .MuiGrid-item{
      margin-bottom: 1rem;
      padding: 0 3rem;
      @media (max-width: 600px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

      .prof-registration-heading {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0px;
      }
    }

    .user-name-link {
      color: black;
    }

    .user-name-link:hover {
      color: #464FD0;
    }

    .rhf-errors {
      font-size: 0.85rem;
      color: #f44336;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      line-height: 1.66;
      letter-spacing: 0.03333em;
    }

    .css-1aquho2-MuiTabs-indicator{
      background-color : #3f51b5 !important;
    }
    .css-1at62qq{
      padding-bottom : 17px !important
    }
    .edit-section{
      color:black !important; 
    }
    .no-user-text{
      color: #3f51b5 !important;
    }
    .makeStyles-plainButtons-242{
      background-color:white!important;
    }
    .MuiOutlinedInput-notchedOutline{
      border-color : #3f51b5 !important;
    }
    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
      text-transform: lowercase ! important;
    }
    .css-znsk8e-MuiButtonBase-root-MuiButton-root{
      border : 1px solid #3f51b5 !important;
      color: #3f51b5 !important;
    }
    .css-q4p5ko-MuiButtonBase-root-MuiButton-root{
      background-color : #3f51b5 !important;
    }
    .makeStyles-plainButtons-37{
      background-color: white !important;
    }
`;

export default GlobalStyle;
