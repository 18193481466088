import { useSelector } from "react-redux";
import { selectUser } from "redux/auth/selectors";
import { Message, User } from "types";
type Props = {
    message: Message;
};

const RequestAdditionalTime = ({ message }: Props) => {
    const user: User | null = useSelector(selectUser);

    if (message.sender === "SYSTEM") return null;
    return (
        <div className="color-gulf-blue interaction-texts">
            <span>{user?.id === message.sender.id ? "You have" : message.sender.firstName + " has"}</span>
            <span>&nbsp;requested additional times</span>
        </div>
    )
}

export default RequestAdditionalTime;
