import { useSelector } from 'react-redux';
import { selectDateIntervalFrom, selectDateIntervalTo } from 'redux/graph/selectors';
import { UserType } from 'types';

export const useParamsForHomePageData = (user: any) => {

  const { roles, university, company } = user!;
  const dateIntervalFrom = useSelector(selectDateIntervalFrom);
  const dateIntervalTo = useSelector(selectDateIntervalTo);

  return user.roles.includes(UserType.EMPLOYER)
    ? {
      userRoles: roles,
      companyName: company?.name,
      companyId: company?.id,
      dateIntervalFrom: dateIntervalFrom,
      dateIntervalTo: dateIntervalTo,
    }
    : {
      userRoles: roles,
      universityName: university?.name,
      universityId: university?.id,
    };
};
