import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';

type Props = {
    data: any;
    index: number;
};

function SupportReceivedTableDataCell({ data, index }: Props) {

    const {
        id,
        engagedUsers,
        firstName,
        lastName,
        interactionStateInfo_ASSOCIATE,
        university
    } = data;

    return (
        <TableRow key={+index}>
            <TableCell>
                <Link className='user-name-link' to={`/users/${id}`}>
                    {firstName ? `${firstName} ${lastName ? lastName : ''}` : '--'}
                </Link>
            </TableCell>
            <TableCell>
                {university?.name}
            </TableCell>
            <TableCell>
                {engagedUsers}
            </TableCell>
            <TableCell>
                {interactionStateInfo_ASSOCIATE['TOTAL']}
            </TableCell>
            <TableCell>
                --
            </TableCell>
        </TableRow>
    );
}

export default SupportReceivedTableDataCell;
