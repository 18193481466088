import styled from 'styled-components';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  mt2: {
    marginTop: '0.5em',
  },
  mt3: {
    marginTop: '3em',
  },
  rowName: {
    fontSize: '12px',
  },
  rowHead: {
    fontWeight: 'bold',
    padding: '0.5em',
  },
  values: {
    color: "rgb(70, 79, 208)",
    fontWeight: 500,
  },
  pd5: {
    paddingBottom: '5em',
  },
  pd5px: {
    padding: '5px',
  },
  tableReplaceGrid: {
    height: '35vh',
    width: '100%',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
  noRecords: {
    marginTop: '6em',
  },
  error: {
    marginLeft: '45%',
    marginTop: '10%',
  },
  addEmpButtonGrid: {
    marginTop: '3em',
  },
  addEmpButton: {
    fontSize: "1.1em",
    backgroundColor: "#464FD0",
    '&:hover': {
      backgroundColor: "#3f51b5 !important",  
    },
  },
  csvButton: {
    color: '#464FD0',
    textTransform: 'none',
    textDecoration: 'underline',
    fontFamily: 'Nunito Sans',
    fontSize: '1.1em',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  },
  downloadIcon: {
    fontSize: '1.5em',
    marginRight: '0.2em',
  },
  buttonPageSizeContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  pageSizeDiv: {
    marginTop: "40px",
  },
  pageSizeWidth: {
    width: "100%",
    marginTop: "0.5em",
    maxWidth: "300px",
  },
  cardContainer: {
    width: "500px !important",
    margin: "2em 0 0 auto",
    background: "rgb(241, 242, 255)",
    border: "1px solid rgba(70, 79, 208, 0.1)",
    borderRadius: "4px",
  },
  tableHeading: {
    fontWeight: "bold",
    textTransform: "uppercase",
    fontSize: "14px",
    textAlign: "center",
    marginBottom: 0,
  },
  deactivateButton: {
    color: '#464FD0',
    textTransform: 'none',
    textDecoration: 'underline',
    fontFamily: 'Nunito Sans',
    fontSize: '1.1em',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  },
  back: {
    marginTop: '3em !important',
    color: 'rgba(70, 79, 208, 1)',
  },
  backButton: {
    textDecoration: 'none !important',
    fontWeight: 'bolder',
    marginBottom: '0.9em',
    color: 'rgba(70, 79, 208, 1)',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  backDeactivated: {
    marginBottom: '3em',
    color: 'rgba(70, 79, 208, 1)',
  },

}));

export const StyledWrapper = styled.div`
  width: 80%;
  .filter-section {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .advanced-filters {
    display: flex;
  }
  .advanced-filter-section {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .clear-filter {
    display: block;
    height: 40px;
    margin-top: 0.63em;
    margin-left: 2em;
  }

  .filter-dropdown {
    width: 100%;
    max-width: 300px;
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
    top: 10px;
    .css-yk16xz-control {
      border-top: none;
      border-right: none;
      border-bottom: 2px solid #464fd0;
      border-left: none;
      border-radius: 0;
      background-color: #f6faff;
    }

    svg {
      fill: #464fd0;
    }

    .css-1wa3eu0-placeholder,
    .css-1uccc91-singleValue {
      color: #464fd0;
      font-weight: 600;
    }
  }

  .filter-section-mobile {
    @media (min-width: 960px) {
      display: none;
    }
  }
  .advanced-filter-section-mobile {
    @media (min-width: 960px) {
      display: none;
    }
  }

  .filter-section-desktop {
    @media (max-width: 960px) {
      display: none;
    }
  }

  .filter-dropdown-sm {
    margin: auto;
    padding: 0 20px 20px 20px;
    @media (max-width: 600px) {
      padding-left: unset;
      padding-right: unset;
      max-width: 100%;
    }
  }

  .clear-filter-sm {
    @media (min-width: 600px) {
      margin-right: 20px;
      padding-right: 20px;
      margin-left: auto;
      margin-top: 20px;
      display: block;
    }
  }

  .filter-text {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px;
    letter-spacing: 0px;
    margin: 0;
  }

  .spacing {
    margin-bottom: 60px;
  }

  .m-0 {
    margin: 0;
  }

`;
