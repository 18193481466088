import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Line } from 'react-chartjs-2';
import { Grid } from '@mui/material';

type PropsType = {
  chartTitle: string;
  labels: string[];
  datasets: any;
  options: any;
};

const Chart = ({ chartTitle, labels, datasets, options }: PropsType) => {
  const classes = useStyles();

  const refreshKey = new Date().getTime();

  return (
    <Grid container key={refreshKey} className={classes.mb}>
      <Grid item xs={10}>
        <Typography variant='h6'>{chartTitle}</Typography>
      </Grid>
      <Grid container item xs={10} className={classes.mt1}>
        <Line
          data={{
            labels,
            datasets: datasets.map((dataset: any) => ({ ...dataset, type: 'line' })),
          }}
          options={options}
        />
      </Grid>
    </Grid>
  );
};

export default Chart;

const useStyles = makeStyles(() => ({
  mt1: {
    marginTop: '2em',
  },
  mb: {
    marginBottom: '3em',
  },
}));
