import AcceptDeclineMessage from "./AcceptDeclineMessage";
import RequestMessage from "./RequestMessage";
import TextMessage from "./TextMessage";
import SuggestTimesMessage from "./SuggestTimesMessage";
import TimeSelectedMessage from "./TimeSelectedMessage";
import RequestAdditionalTime from "./RequestAdditionalTime";
import CallDetailsMessage from "./CallDetailsMessage";
import CallDetailsMessageNoLink from "./CallDetailsMessageNoLink";
import SystemMessage from "./SystemMessage";
import LikeMessage from "./LikeMessage";
import NoShowMessage from "./NoShowMessage";
import ThankYouMessage from "./ThankYouMessage";
import RequestRescheduleMessage from "./RequestRescheduleMessage";
import RescheduleMessage from "./RescheduleMessage";
import RescheduleAcceptMessage from "./RescheduleAcceptMessage";
import RescheduleDeclineMessage from "./RescheduleDeclineMessage";
import InterviewDateMessage from "./InterviewDateMessage";
import { MessageType } from "types";
import UnabletoAttendMessage from "./UnableToAttendMessage";
import InteractionComplete from "./InteractionComplete";
import InteractioNotOver from "./InteractioNotOver";
import AppologyMessage from "./AppologyMessage";
import SorryMessage from "./SorryMessage";
import AppologyMessageClose from "./AppologyMessageClose";
import RatingMessage from "./RatingMessage";
import DontWantToSendMessage from "./DontWantToSendMessage";
import RemindMeLater from "./remindMeLater";

const threadMessageMap: any = {
  [MessageType.REQUEST]: (props: any) => <RequestMessage {...props} />,
  [MessageType.EXPECTED_INTERVIEW_DATE]: (props: any) => <InterviewDateMessage {...props} />,
  [MessageType.ACCEPT]: (props: any) => <AcceptDeclineMessage {...props} />,
  [MessageType.DECLINE]: (props: any) => <AcceptDeclineMessage {...props} />,
  [MessageType.TEXT]: (props: any) => <TextMessage {...props} />,
  [MessageType.SUGGEST_TIMES]: (props: any) => <SuggestTimesMessage {...props} />,
  [MessageType.SELECT_TIME]: (props: any) => <TimeSelectedMessage {...props} />,
  [MessageType.REQUEST_ADDITIONAL_TIMES]: (props: any) => <RequestAdditionalTime {...props} />,
  [MessageType.SET_CALL_DETAILS]: (props: any) => <CallDetailsMessage {...props} />,
  [MessageType.SET_CALL_DETAILS_WITHOUT_LINK]: (props: any) => (
    <CallDetailsMessageNoLink {...props} />
  ),
  [MessageType.RESCHEDULE_REQUEST]: (props: any) => <RequestRescheduleMessage {...props} />,
  [MessageType.RESCHEDULE_ACCEPT]: (props: any) => <RescheduleAcceptMessage {...props} />,
  [MessageType.RESCHEDULE_DECLINE]: (props: any) => <RescheduleDeclineMessage {...props} />,
  [MessageType.RESCHEDULE]: (props: any) => <RescheduleMessage {...props} />,
  [MessageType.SET_EXPIRED]: (props: any) => <SystemMessage {...props} />,
  [MessageType.SET_COMPLETED]: (props: any) => <SystemMessage {...props} />,
  [MessageType.LIKE]: (props: any) => <LikeMessage {...props} />,
  [MessageType.NO_SHOW]: (props: any) => <NoShowMessage {...props} />,
  [MessageType.THANKS_NOTE]: (props: any) => <ThankYouMessage {...props} />,
  [MessageType.FEEDBACK_NOTE]: (props: any) => <ThankYouMessage {...props} />,
  [MessageType.STUD_RATING]: (props: any) => <RatingMessage {...props} />,
  [MessageType.PROF_RATING]: (props: any) => <RatingMessage {...props} />,
  [MessageType.FEEDBACK_DONT_SEND]: (props: any) => <DontWantToSendMessage {...props} />,
  [MessageType.THANKS_DONT_SEND]: (props: any) => <DontWantToSendMessage {...props} />,
  [MessageType.FEEDBACK_REMIND]: (props: any) => <RemindMeLater {...props} />,
  [MessageType.THANKS_REMIND]: (props: any) => <RemindMeLater {...props} />,
  [MessageType.STUDENT_NO_SHOW]:(props: any) => <UnabletoAttendMessage {...props}/>,
  [MessageType.PROFESSIONAL_NO_SHOW]:(props: any) => <UnabletoAttendMessage {...props}/>,
  [MessageType.SET_INTERACTIONCOMPLETED]:(props:any)=><InteractionComplete {...props} />,
  // not over
  [MessageType.SET_INTERACTION_NOT_OVER]: (props: any) => <InteractioNotOver {...props} />,
  // For Appology Message Modal
  [MessageType.PROF_APPOLOGY]:(props:any) => <AppologyMessage {...props}/>,
  [MessageType.STUD_APPOLOGY]:(props:any) => <AppologyMessage {...props}/>,

  // For Close Sorry Modal Message
  [MessageType.PROF_SORRY_MODAL_CLOSE]:(props:any) => <SorryMessage {...props}/>,
  [MessageType.STUD_SORRY_MODAL_CLOSE]:(props:any) => <SorryMessage {...props}/>,

   // For Close Appology Modal Message
   [MessageType.PROF_APPOLOGY_MODAL_CLOSE]:(props:any) => <AppologyMessageClose {...props}/>,
   [MessageType.STUD_APPOLOGY_MODAL_CLOSE]:(props:any) => <AppologyMessageClose {...props}/>,
};

const threadMessageFactory = (messageType: MessageType) => {
  return threadMessageMap[messageType];
};

export default threadMessageFactory;
