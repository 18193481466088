import React, { Children } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableDataCell from './TableDataCell';
import { useStyles } from './styles';
import { Table } from 'app/components';
import { FaSort } from 'react-icons/fa';

const interactionStates = ['Total', 'Live', 'Completed', 'Requested', 'Rejected', 'Expired'];
const interactionType: string[] = ['Career', 'Expert', 'Mock'];

// SMN 6 adding emplyee details like employee name ,company name and job title
const employeeDetails:string[] = ['Employee Name','Company','Job Title'];


type PropsType = {
  firstColumnTitle: string;
  engagedColumnTitle: string;
  tableData: any[];
  studentTable: boolean;
  sortOrder?: string;
  sortColumn?: string;
  onSort?: (c: string) => void;
};

function InteractionStatsTable({
  // firstColumnTitle,
  engagedColumnTitle,
  tableData = [],
  studentTable,
  sortOrder,
  sortColumn,
  onSort,
}: PropsType) {
  const classes: any = useStyles();
  const studentRelatedColumns = studentTable
    ? [
      { name: 'DATE REGISTERED', props: { rowSpan: 2 } },
      { name: 'INTERACTION DATE', props: { rowSpan: 2 } },
    ]
    : [];

  return (
    <>
      <Table
        tableHeader={
          <>
            <TableRow className={classes.topTableHeader}>
              {Children.toArray(
                [
                  {name:'Employee Details',props:{colSpan:3}},
                  { name: 'INTERACTION STATUS', props: { colSpan: 6 } },
                  { name: engagedColumnTitle, props: { rowSpan: 2 } },
                  ...studentRelatedColumns,
                  { name: 'INTERACTION TYPES', props: { colSpan: 3 } },
                  
                ].map((column, index: number) => (
                  <TableCell
                    align='center'
                    {...column.props}
                  >
                    <strong>{column.name}</strong>{' '}
                  </TableCell>
                )),
              )}
            </TableRow>
            <TableRow>
            {React.Children.toArray(
                employeeDetails.map((employeeDetails: any) => (
                  <TableCell
                    align='center'
                    className={`${classes[employeeDetails.toLowerCase()]} ${classes.CursorPointerUnderlined} ${classes.CursorPointer} ${classes.Task}`}
                    onClick={
                      () => onSort && onSort('firstName')
                    }
                  >
                    {employeeDetails}
                    {employeeDetails === 'Employee Name' && sortColumn && <FaSort />}
                  </TableCell>
                )),
              )}
              {React.Children.toArray(
                interactionStates.map((interactionState) => (
                  <TableCell
                    align='center'
                    className={`${classes.state}  ${classes.CursorPointerUnderlined} ${classes.CursorPointer}`}
                    onClick={
                      () => onSort && onSort(interactionState)
                    }
                  >
                    {interactionState}{' '}
                    {sortColumn && <FaSort />}
                  </TableCell>
                )),
              )}
              {React.Children.toArray(
                interactionType.map((interactionType: any) => (
                  <TableCell
                    align='center'
                    className={`${classes[interactionType.toLowerCase()]} ${classes.CursorPointerUnderlined} ${classes.CursorPointer}`}
                    onClick={
                      () => onSort && onSort(interactionType)
                    }
                  >
                    {interactionType}
                    {sortColumn && <FaSort />}
                  </TableCell>
                )),
              )}
            </TableRow>
          </>
        }
        tableBody={
          <>
            {tableData && Children.toArray(
              tableData
                .filter((el: any) =>
                  (el.interactionTypeInfo || el.interactionStateInfo ||
                    el.interactionTypeInfo_PROFESSIONAL || el.interactionTypeInfo_ASSOCIATE ||
                    el.interactionStateInfo_PROFESSIONAL || el.interactionStateInfo_ASSOCIATE) ? true : false,
                )
                .map((data: any, index: number) => (
                  <TableDataCell studentTable={studentTable} data={data} index={index} />
                )),
            )}
          </>
        }
      />
    </>
  );
}

export default InteractionStatsTable;
