import TableCell from '@mui/material/TableCell';
import { Children } from 'react';
import { useStyles } from './styles';
interface InteractionsType {
  CAREER_CHAT: number;
  EXPERT_ADVICE: number;
  MOCK_INTERVIEW: number;
  index: number;
}

const InteractionTypeRow = ({
  CAREER_CHAT,
  EXPERT_ADVICE,
  MOCK_INTERVIEW,
  index,
}: InteractionsType) => {
  const classes: any = useStyles();
  return (
    <>
      {Children.toArray(
        [CAREER_CHAT, EXPERT_ADVICE, MOCK_INTERVIEW].map((state, code) => (
          <TableCell className={index % 2 !== 0 ? classes[code] : ''}>
            {!isNaN(state) ? state : '--'}
          </TableCell>
        )),
      )}
    </>
  );
};

export default InteractionTypeRow;
