import { createUserProfile } from './utils';
import { call, takeLatest, put } from 'redux-saga/effects';
import firebase from 'utils/firebase';
import { toggleRegistrationFormWorking } from 'redux/auth/slice';
import { showToast, ToastMessage, ToastType } from 'app/components/Toast/slice';

const ERROR_MESSAGES_FIREBASE: any = {
  "auth/email-already-in-use": "The email address is already in use by another account."
};

const createFirebaseUser = async (email: string, password: string) =>
  await firebase.auth().createUserWithEmailAndPassword(email, password);

function* registerUser(action: any): any {
  const { email, password } = action.payload;
  try {
    yield call(createFirebaseUser, email, password);
    yield createUserProfile(firebase.auth().currentUser?.uid as string, email);
    const toast: ToastMessage = {
      type: ToastType.SUCCESS,
      message: 'Congratulations, you have successfully created an account.',
    };
    yield put(showToast(toast));
  } catch (error: any) {
    const toast: ToastMessage = {
      type: ToastType.ERROR,
      message: ERROR_MESSAGES_FIREBASE[error.code] ||
        `Unable to register ${email}. Please contact the upReach team for support.`,
    };
    yield put(showToast(toast));
  }
  yield put(toggleRegistrationFormWorking());
}

export default function* registrationSaga() {
  yield takeLatest('TYPEFORM_REGISTRATION_ATTEMPT', registerUser);
}
