import makeStyles from '@mui/styles/makeStyles';
export const useStyles = makeStyles(() => ({
  bgBlue: {
    backgroundColor: '#F2F3FF',
  },
  tableBody: {
    '& .MuiTableCell-body': {
      textAlign: 'center',
    },
  },
    plainButtons: {
    background: 'none',
    border: 'none',
    color: 'rgba(70, 79, 208, 1)',
    fontSize: '1em',
    textDecoration: 'underline',
    padding: '0em',
  },
  CursorPointerUnderlined: {
  textDecorationLine: "underline",
    cursor: 'pointer',
  },
  CursorPointer: {
    cursor: 'pointer',
  },
  tableHead: {
    backgroundColor: '#DEE1FF',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    borderRight: '2px solid white'
  },
  topTableHeader: {
    '& .MuiTableCell-head': {
      borderBottom: '0px',
      borderRight: '2px solid white',
    },
  },
  state: {
    backgroundColor: '#464FD0',
    color: '#fff',
    borderRight: '1px solid white',
    borderTop: '2px solid white'
  },
  Task:{
    fontWeight: 'bolder',
    borderTop: '2px solid white',
    borderRight: '1px solid white',
  },
  career: {
    backgroundColor: '#009D90',
    color: '#fff',
    borderLeft: '2px solid white !important',
  },
  expert: {
    backgroundColor: '#5EB8D9',
    color: '#fff',
  },
  mock: {
    backgroundColor: '#0077B7',
    color: '#fff',
  },
  mentoring: {
    backgroundColor: '#304563',
    color: '#fff',
  },
  '0': {
    backgroundColor: '#E6F5F4',
  },
  '1': {
    backgroundColor: '#EFF8FB',
  },
  '2': {
    backgroundColor: '#EAECEF',
  },
  '3': {
    backgroundColor: '#E6F1F8',
  },
   button:{
     backgroundColor:'#b8e37f',
    borderColor:'#b8e37f',
   fontSize:20,
     Color:"white",
    borderRadius: 5, 
   },
   deactivateButton:{
    backgroundColor:'#7CFC00',
    color:'White',
   }
}));