import { useSelector } from "react-redux";
import { selectUser } from "redux/auth/selectors";
import { Message, User } from "types";
type Props = {
  message: Message;
};

const RequestMessage = ({ message }: Props) => {
  const user: User | null = useSelector(selectUser);

  if (message.sender === "SYSTEM") return null;
  return (
    <div className="color-gulf-blue body-17 font-weight-bolder">
      <span >{user?.id === message.sender.id ? "You have" : message.sender.firstName + " has"}</span>
      <span>&nbsp;sent a request</span>
    </div>
  );
};

export default RequestMessage;
