import TableCell from '@mui/material/TableCell';
import { Children } from 'react';
import { useStyles } from './styles';

interface InteractionStates {
  COMPLETED: number;
  DECLINED: number;
  EXPIRED: number;
  LIVE: number;
  REQUESTED: number;
  TOTAL: number;
  index: number;
}

const InteractionStateRow = ({
  COMPLETED,
  DECLINED,
  EXPIRED,
  LIVE,
  REQUESTED,
  TOTAL,
  index,
}: InteractionStates) => {
  const classes = useStyles();

  return (
    <>
      {Children.toArray(
        [TOTAL, LIVE, COMPLETED, REQUESTED, DECLINED, EXPIRED].map((state) => (
          <TableCell className={index % 2 !== 0 ? classes.bgBlue : ''}>
            {state !== undefined ? state : '--'}
          </TableCell>
        )),
      )}
    </>
  );
};

export default InteractionStateRow;
