import { useDispatch } from 'react-redux';
import { Button, Grid, Typography } from '@mui/material';
import { setOnLoginRedirectRoute } from 'redux/auth/slice';

type PropsTypes = {
  isDropdownOpen: boolean;
  setDropdownOpen: (value: React.SetStateAction<boolean>) => void;
  email: string;
};

const ProfileDropdown = ({ isDropdownOpen, setDropdownOpen, email }: PropsTypes) => {
  const dispatch = useDispatch();

  const handleLogoutClicked = () => {
    dispatch({ type: 'LOGOUT_ATTEMPT', payload: null });
    dispatch(setOnLoginRedirectRoute('/login'));
    setDropdownOpen(!isDropdownOpen);
  };

  if (!isDropdownOpen || !email) return null;

  return (
    <Grid
      className={`profile-dropdown`}
      style={{
        position: 'absolute',
        zIndex: 99,
        top: '4em',
      }}
      direction='column'
      lg={2}
      onMouseLeave={() => setDropdownOpen(false)}
      onMouseEnter={() => setDropdownOpen(true)}
    >
      <Typography
        className='user-email text-center box-height color-black font-family-nunito border-bottom'
        component='span'
      >
        {email}
      </Typography>
      <Button
        className='logout-button box-height font-family-nunito'
        color='inherit'
        onClick={handleLogoutClicked}
      >
        Logout
      </Button>
    </Grid>
  );
};

export default ProfileDropdown;
