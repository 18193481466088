import { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "app/App";
import reportWebVitals from "reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import store from "redux/store";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material";
import { materialTheme } from "styles/getMaterialUITheme";
import "styles/index.css";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnMount: false } },
});

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={materialTheme}>
            <App />
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </Provider>
  </StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
