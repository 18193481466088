import { UserType } from 'types';

export const NavigationLinks: any = {
  [UserType.EMPLOYER]: [
    {
      to: '/home',
      navigationText: 'HOME',
      gridSize: 2,
    },
    {
      to: '/participatingUsers/employees',
      navigationText: 'PARTICIPATING EMPLOYEES',
      gridSize: 8,
    },
  ],
  [UserType.UNIVERSITY_ADMIN]: [
    {
      to: '/home',
      navigationText: 'HOME',
      gridSize: 2,
    },
    {
      to: '/participatingUsers/students',
      navigationText: 'STUDENTS',
      gridSize: 2,
    },
    {
      to: '/participatingUsers/alumni',
      navigationText: 'ALUMNI',
      gridSize: 2,
    },
  ],
  [UserType.UPREACH_ADMIN]: [
    {
      to: '/home',
      navigationText: 'HOME',
      gridSize: 2,
    },
    {
      to: '/upreachStaff',
      navigationText: 'UPREACH STAFF',
      gridSize: 4,
    },
  ],
  [UserType.PROGRAM_COORDINATOR]: [
    {
      to: '/home',
      navigationText: 'HOME',
      gridSize: 2,
    },
  ],
};
