import getAxiosInstance from "utils/axios";

export const performOnLoginSync = async (id: string, email: string) => {
  const axios = await getAxiosInstance();
  const { data } = await axios.post(`/users/onLoginSync`, {
    id,
    email,
  });
  return data;
};
