import { useState } from 'react';
import { Typography } from '@mui/material';
import CaretDown from '@mui/icons-material/KeyboardArrowDown';
import { StyledWrapper } from './styles';
import DropDown from './Dropdown';

type Props = {
  options: [][];
  selectedCount: number;
  filterTitle: string;
  placeholder: string;
  handleOptionChange: (idx: any, itemText: any) => void;
};

const NestedFilter = ({ options, selectedCount, handleOptionChange , filterTitle, placeholder}: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  return (
    <StyledWrapper>
      <Typography variant='h6' className='filter-heading'>
        {/* And / Or */}
        {filterTitle}
      </Typography>
      <div className='filter-wrapper' onClick={() => setDropdownOpen((open) => !open)}>
        <h4 className='filter-name'>
          {/* Programme Type  */}
          {placeholder}{selectedCount > 0 && `( ${selectedCount} )`}{' '}
        </h4>
        <CaretDown color='primary' className='caret-icon' />
      </div>
      <div className='dropdown-div'>
        {dropdownOpen && (
          <DropDown
          options={options}
            handleOptionSelect={(idx: number, itemText: string) =>
              handleOptionChange(idx, itemText)
            }
          />
        )}
      </div>
    </StyledWrapper>
  );
};

export default NestedFilter;
