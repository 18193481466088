import { useEffect, useState } from "react";
import AddMultipleEmployees from "../EmployerView/AddMultipleEmployeesPage";
import DashboardMainView from "../Dashboard";
import { Steps } from "./steps";

const PartincipatingUsersStepper = () => {
  const [stepperStep, setStepperStep] = useState<string>();
  useEffect(() => {
    setStepperStep(Steps.DISPLAY_PARCIPATING_USERS);
  }, []);

  if (stepperStep === Steps.DISPLAY_PARCIPATING_USERS) {
    return <DashboardMainView setStepperStep={setStepperStep} />;
  }
  if (stepperStep === Steps.ADD_MULTIPLE_EMPLOYEES_BY_EMAILS) {
    return <AddMultipleEmployees setStepperStep={setStepperStep} />;
  }
  return null;
};

export default PartincipatingUsersStepper;
