import { Button } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DeactivateModal from 'app/containers/UserProfile/DeactivateModal';
import { format } from 'date-fns';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/auth/selectors';
import { useStyles } from './styles';
type Props = {
  data: any;
  index: number;
};
function TableDataCell({ data, index }: Props) {
  const {
    firstName,
    lastName,
    company,
    jobTitle,
    deactivatedAt,
    action,
    id,
  } = data;
  const classes: any = useStyles();
  const user = useSelector(selectUser);
  const [profile, setProfile] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>('');
  const styleObject = index % 2 !== 0 ? { backgroundColor: '#F2F3FF' } : undefined;
  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const activateProf = () => {
    setIsModalOpen(true);
    setModalType('ACTIVATE');
  }
  useEffect(() => {
    const fetchUserProfile = async (id: string) => {
      try {
        setProfile({ ...data });
      } catch (error: any) {
        if (error.response.status === 404) 
        console.log(error)
      } 
    };
    if (id) fetchUserProfile(id);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <TableRow key={+index} style={styleObject}>
      <TableCell >
        {firstName ? `${firstName} ${lastName ? lastName : ''}` : '--'}
      </TableCell>
      <TableCell>
        {company}
      </TableCell>
      <TableCell>
        {jobTitle}
      </TableCell>
      <TableCell>
        {deactivatedAt ? format(new Date(deactivatedAt._seconds * 1000), 'dd.MM.yyyy') : '--'}
      </TableCell>
      <TableCell>
        {action}
        {user.roles.includes('UPREACH_ADMIN') &&
          (
            <Button
              className={classes.deactivateButton}
              onClick={() => {
                activateProf()
              }} >Activate</Button>)}
      </TableCell>
      {profile && user.roles.includes('UPREACH_ADMIN') && (
        <DeactivateModal
          userEmail={profile.email}
          isOpen={isModalOpen}
          onClose={handleModalClose}
          userId={profile.id}
          modalType={modalType}
          isProfileDisabled={profile?.metadata?.isDisabled}
          isProfileGhost={profile?.metadata?.isGhost}
        />
      )}
    </TableRow>
  );
}
export default TableDataCell;