import { ComponentType } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsDashboardLoading, selectUser } from 'redux/auth/selectors';
import { Loader, Navbar, SecondaryNavbar } from '..';
import { UserType } from 'types';

type Props = {
  component: ComponentType<any>;
  path: string;
};

const PrivateRoute = ({ component: Component, path, ...rest }: Props) => {
  const user = useSelector(selectUser);
  const isDashboardLoading = useSelector(selectIsDashboardLoading);

  if (isDashboardLoading) return <Loader />;
  if (!user) return <Redirect to='/login' />;

  const { roles, company, university } = user;
  const page = (
    <>
      <Navbar />
      {roles.includes(UserType.EMPLOYER) && <SecondaryNavbar orgName={company?.name} />}
      {roles.includes(UserType.UNIVERSITY_ADMIN) && <SecondaryNavbar orgName={university?.name} />}
      <Component {...rest} />
    </>
  );
  return <Route path={path} {...rest} render={() => page} />;
};

export default PrivateRoute;
