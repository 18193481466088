import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import makeStyles from '@mui/styles/makeStyles';

type Props = {
  organizationName: string;
  totalInteractions: string;
  noOfUser: string;
  index: number;
  showAverageInteractions: boolean;
  clickHandler?: any;
};

function TableDataCell({
  organizationName,
  totalInteractions,
  noOfUser,
  index,
  showAverageInteractions,
  clickHandler,
}: Props) {
  const classes = useStyles();

  return (
    <TableRow key={+index} className={index % 2 !== 0 ? classes.bgBlue : ""}>
      <TableCell>
        <div
          onClick={clickHandler ? () => clickHandler(organizationName) : undefined}
          className={clickHandler ? classes.divHover : ""}
        >
          {organizationName ? organizationName : "--"}
        </div>
      </TableCell>
      <TableCell align="center">
        {isNaN(Number(totalInteractions)) ? "--" : totalInteractions}
      </TableCell>
      <TableCell align="center">{isNaN(Number(noOfUser)) ? "--" : noOfUser}</TableCell>
      {showAverageInteractions && (
        <TableCell align="center">
          {totalInteractions && noOfUser
            ? ((Number(totalInteractions)) / Number(noOfUser)).toFixed(2)
            : "--"}
        </TableCell>
      )}
    </TableRow>
  );
}

export default TableDataCell;

const useStyles = makeStyles(() => ({
  bgBlue: {
    backgroundColor: "#F2F3FF",
  },
  divHover: {
    cursor: "pointer",
  },
}));
