import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { initialState } from "./slice";

const interactionSlice = (state: RootState) =>
  state.interactions || initialState;

export const selectInteractions = createSelector(
  [interactionSlice],
  (state) => state.interactions
);

export const selectActiveInteractionId = createSelector(
  [interactionSlice],
  (state) => state.activeInteractionId
);