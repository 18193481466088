import getAxiosInstance from 'utils/axios';

export const createUserProfile = async (userId: string, email: string) => {
  const axios = await getAxiosInstance();
  const response = await axios.post('/dashboard/createUserProfile', {
    userId,
    email,
  });
  return response;
};
