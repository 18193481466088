import makeStyles from '@mui/styles/makeStyles';
import styled from "styled-components";

export const StyledWrapper = styled.div`
  color: white;

  a {
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    &:focus {
      color: white;
    }

    margin-left: 4rem;

    @media (max-width: 650px) {
      margin-left: 3rem;
    }
  }

  .active-link {
    border-bottom: 2px solid white;
  }

  .header-links {
    display: inline-block;
    line-height: unset;
  }

  .header-links:after {
    display: block;
    content: "";
    border-bottom: solid 1px white;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }

  .header-links:hover:after {
    transform: scaleX(1);
  }

  .user-email {
    color: black;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .logout-button {
    font-weight: bold;
    width: 100%;
    background-color: white;
    border-radius: 0;
    color: black;
  }

  .logout-button:hover {
    background-color: #dee1ff;
  }
  .box-height {
    min-height: 50px;
  }
  img {
    padding: 0.5rem;
    max-width: 200px;
  }

  .toolbar {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .profile-dropdown {
    position: absolute;
    max-width: 300px;
    min-width: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    top: 70px;
    right: 20px;
    z-index: 1;
    background-color: white;
    border: 1px solid #c4c4c4;
  }

  .avatar-white {
    border: 1px solid white;
    background-color: white;
  }
  .border-bottom {
    border-bottom: 1px solid #c4c4c4;
  }

  .upreach-logo {
    margin-left: 20px;
    margin-right: auto;
  }

  .router-links {
    padding-left: 50px;
  }
`;


export const useStyles = makeStyles(() => ({
  toolbar: {
    paddingTop: '0.62em',
    paddingBottom: '0.62em',
    backgroundColor: '#304563',
  },
  image: {
    width: '100%',
    height: 'auto',
    padding: '0.5rem',
    maxWidth: '200px',
    position: 'relative',
    left: '0px',
  },
  activeLink: {
    paddingBottom: '0.2em',
    borderBottom: '2px solid white',
  },
}));

