
import { useSelector } from "react-redux";
import { selectUser } from "redux/auth/selectors";
import { Message, User } from "types";
type Props = {
    message: Message;
};
const RatingMessage = ({ message }: Props) => {
    const user: User | null = useSelector(selectUser);
    if (message.sender === "SYSTEM") return null;
    return (
        <div>
            <span style={{ textTransform: "capitalize" }} className="body-17 font-weight-bolder color-gulf-blue ">
                {user?.id === message.sender.id ? "You" : message.sender.firstName}
            </span>
            <span className="body-17 font-weight-bolder color-gulf-blue"> 
                &nbsp;submitted the interaction rating to upReach
            </span>
        </div>
    )
}
export default RatingMessage;