import { configureStore, getDefaultMiddleware, combineReducers } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import authReducer from "./auth/slice";
import interactionReducer from "./interactions/slice";
import graphReducer from "./graph/slice";
import rootSaga from "./rootSaga";
import toastReducer from "../app/components/Toast/slice";
import navigationReducer from "./navigation/slice";

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  auth: authReducer,
  toast: toastReducer,
  navigation: navigationReducer,
  graph: graphReducer,
  interactions: interactionReducer
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware({ thunk: false }), sagaMiddleware],
});

export type RootState = ReturnType<typeof store.getState>;

export default store;

sagaMiddleware.run(rootSaga);
