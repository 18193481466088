export const chartLabels: any = {
  TOTAL_INTERACTION: 'TOTAL INTERACTION',
  EXPERT_ADVICE: 'EXPERT ADVICE',
  CAREER_CHAT: 'CAREER CHAT',
  MOCK_INTERVIEW: 'MOCK INTERVIEW',
  STUDENTS_ENGAGED: 'STUDENTS ENGAGED',
  PROFESSIONALS_ENGAGED: 'PROFESSIONALS ENGAGED',
};

export const stylesForLine: any = {
  TOTAL_INTERACTION: '#464FD0',
  EXPERT_ADVICE: '#5EB8D9',
  CAREER_CHAT: '#009D90',
  MOCK_INTERVIEW: '#0077B7',
  STUDENTS_ENGAGED: '#F2B542',
  PROFESSIONALS_ENGAGED: '#F2B542',
};

export const chartOptions = {
  responsive: true,
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      min: 1,
    },
    y: {
      grid: {
        borderDash: [8, 4],
      },
      min: 0,
      ticks: {
        min: 1,
        max: 1000,
        stepSize: 1,
      },
      title: {
        display: true,
        text: 'NO. OF INTERACTIONS',
        padding: 5,
      },
    },
  },

  plugins: {
    legend: {
      position: 'right',
      labels: {
        padding: 30,
        boxHeight: 1,
      },
    },
  },
};
