import { Button, Card, Grid, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'redux/auth/selectors';
import getAxiosInstance from 'utils/axios';
import { showToast, ToastMessage, ToastType } from 'app/components/Toast/slice';
import { Steps } from '../ParticipatingUserPage/steps';
import { Loader } from 'app/components';

type PropsType = {
  setStepperStep: Dispatch<SetStateAction<string | undefined>>;
};

const AddMultipleEmployeesPage = ({ setStepperStep }: PropsType) => {
  const [emails, setEmails] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingQuota, setIsLoadingQuota] = useState<boolean>(false);
  const [limitToInviteProfessional, setLimitToInviteProfessional] = useState<number>(0);
  const [remainingLimitToInviteProfessional, setRemainingLimitToInviteProfessional] =
    useState<number>(0);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const classes = useStyle();

  useEffect(() => {
    (async () => {
      setIsLoadingQuota(true);
      await fetchQuota();
      setIsLoadingQuota(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchQuota = async () => {
    try {
      const axios = await getAxiosInstance();
      const { data } = await axios.get('/dashboard/getProfessionalsInviteLimit', {
        params: {
          companyId: user.company.id,
        },
      });
      setLimitToInviteProfessional(data.limitToInviteProfessional);
      setRemainingLimitToInviteProfessional(data.remainingLimitToInviteProfessional);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddMembers = async () => {
    try {
      setIsLoading(true);
      const axios = await getAxiosInstance();
      const { data } = await axios.post('/dashboard/addMultipleEmployeesByEmail', {
        emailIdsString: emails,
        company: user.company,
      });
      await fetchQuota();
      const toast: ToastMessage = {
        type: ToastType.SUCCESS,
        message: data.message,
      };
      setEmails('');
      dispatch(showToast(toast));
    } catch (error:any) {
      console.log(error);
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: error.response.data.message,
      };
      dispatch(showToast(toast));
    } finally {
      setIsLoading(false);
    }
  };
  return <>
    {!isLoadingQuota && (
      <Grid container justifyContent='center' className={classes.container}>
        {limitToInviteProfessional !== undefined &&
          remainingLimitToInviteProfessional !== undefined && (
            <Grid container justifyContent='flex-start' item xs={10} className={classes.limitContainer}>
              <Grid item xs={12}>
                <Typography variant='body1' className={`${classes.columnData} ${classes.color4}`}>
                  {!isNaN(remainingLimitToInviteProfessional)
                    ? remainingLimitToInviteProfessional
                    : '--'}
                  / {!isNaN(limitToInviteProfessional) ? limitToInviteProfessional : '--'}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.columnTitle}>
                SPACES REMAINING
              </Grid>
            </Grid>
          )}
        <Grid item xs={10}>
          <Typography variant='subtitle1'>Add Employees</Typography>
        </Grid>
        <Grid item xs={10} className={classes.marginTop1}>
          <Card className={classes.shadow}>
            <Grid container className={classes.paddingContainer}>
              <Grid item xs={12}>
                <Typography variant='subtitle2'>
                  Enter Email Address (comma separated for multiple)
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.marginTop1}>
                <TextField
                  value={emails}
                  onChange={(e) => setEmails(e.target.value)}
                  id='outlined-multiline-static'
                  label=''
                  multiline
                  rows={5}
                  defaultValue=''
                  variant='outlined'
                  className={classes.textFieldWidth}
                  placeholder='example@example.com,example2@example.com'
                />
              </Grid>
              <Grid item xs={6} className={classes.buttons}>
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={() => setStepperStep(Steps.DISPLAY_PARCIPATING_USERS)}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleAddMembers}
                  disabled={isLoading}
                  className={classes.buttonMarginLeft}
                >
                  Add Members
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    )}
    {isLoadingQuota && <Loader />}
  </>;
};

export default AddMultipleEmployeesPage;

const useStyle = makeStyles(() => ({
  container: {
    marginTop: '2em',
  },
  paddingContainer: {
    paddingLeft: '1em',
    paddingTop: '2em',
  },
  textFieldWidth: {
    width: '50%',
  },
  marginTop1: {
    marginTop: '1em',
  },
  buttons: {
    marginTop: '2em',
    marginBottom: '4em',
  },
  buttonMarginLeft: {
    marginLeft: '1em',
  },
  shadow: {
    boxShadow: '0px 0px 36px 13px rgba(0, 0, 0, 0.05)',
  },
  color4: {
    color: '#0077B7',
  },
  columnTitle: {
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 1)',
  },
  columnData: {
    fontSize: '2em',
  },
  limitContainer: {
    marginTop: '1em',
    marginBottom: '2em',
  },
}));
