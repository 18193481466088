import { Dispatch, SetStateAction, useEffect, useState , useRef} from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useReactQuery } from 'utils/hooks/useReactQuery';
import { setActiveTab } from 'redux/navigation/slice';
import { Pagination } from 'app/components';
import { TextField } from 'app/components';
import Modal from '../../../components/Modal'
import { useDispatch, useSelector } from 'react-redux';
import { UserType } from 'types';
import useDebounce from 'utils/hooks/useDebounce';
import TopEngagedTable from '../../TopEngaged/TopEngagedTable';
import EditEmployerLimitTable from './EditEmployerLimitTable';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useHistory } from 'react-router-dom';
import { selectUser } from 'redux/auth/selectors';
import { sortColumnToAlgoliaFieldMapping } from 'utils/constants/sortColumnToAlgoliaFieldMapping';
import { CSVLink } from 'react-csv';
import { CSVDataType, DashboardDataType } from './types';
import { AiOutlineDownload } from 'react-icons/ai';
import { showToast, ToastType } from 'app/components/Toast/slice';

const employerTableHead = ['EMPLOYER NAME', 'TOTAL INTERACTIONS', 'NO. OF PROFESSIONALS', 'AVERAGE INTERACTIONS PER PROFESSIONAL'];

type PropsType = {
  setPrevTabFilter: Dispatch<SetStateAction<string>>;
};

const EmployerOverview = ({ setPrevTabFilter }: PropsType) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isEmployeesModalOpen, setIsEmployeesModalOpen] = useState<boolean>(false)
  const [query, setQuery] = useState<string>('');
  const [sortColumn, setSortColumn] = useState('Total');
  const [sortOrder, setSortOrder] = useState('desc');
  const dispatch = useDispatch();
  const debouncedQuery = useDebounce(query, 300);
  const classes: any = useStyles();
  const history = useHistory();
  const user = useSelector(selectUser);

  const csvButtonRef: any = useRef();
  const [csvData, setCSVData] = useState<CSVDataType[]>([]);

  useEffect(() => {
    setCurrentPage(1);
  }, []);

  const { data, isLoading, error } = useReactQuery(
    ['employerOverview', currentPage, debouncedQuery, sortColumn, sortOrder],
    {
      url: '/dashboard/fetchEmployersEngaged',
      params: {
        query: debouncedQuery,
        pageNo: currentPage,
        pageSize: 15,
        filters: '',
        forAdmin: true,
        sortColumn: sortColumnToAlgoliaFieldMapping[sortColumn as string],
        sortOrder: sortOrder.toUpperCase(),
      },
    },
  );

  const csvdata: DashboardDataType[] = data?.completeData;
  csvdata?.map((entry,index) => {
    csvdata[index].averageInteractionPerProfessional = entry.totalInteractions/entry.totalNoOfProfessionals;
    if(entry.totalNoOfProfessionals === 0){
      csvdata[index].averageInteractionPerProfessional = 0
    }
    return csvdata;
  });

  const handleDownloadCSVClicked = async () => {
    try {
      let CSVData: CSVDataType[] = [];
      csvdata.forEach((csv: DashboardDataType) => {
        CSVData.push({
            'Employer Name':csv.employer,
            'Total Interactions':csv.totalInteractions,
            'Total Number Of Professionals':csv.totalNoOfProfessionals,
            'Average Interaction Per Professional':csv.averageInteractionPerProfessional,
        })
      })
      setCSVData((prevData: CSVDataType[]) => {
        setTimeout(() => {
          csvButtonRef.current.link.click();
          dispatch(
            showToast({
              type: ToastType.SUCCESS,
              message: 'CSV Downloaded Successfully',
            }),
          );
        }, 500);
        return CSVData;
      });
    } catch (error) {
      console.log(error);
      dispatch(
        showToast({
          type: ToastType.ERROR,
          message: 'Error occurred while downloading csv',
        }),
      );
    }
  };

  const handlePageChange = (_event: React.ChangeEvent<unknown>, pageNo: number) => {
    setCurrentPage(pageNo);
  };

  const handleEmployeesModal = () => {
    setIsEmployeesModalOpen(false);
  };

  const handleCompanyClicked = (comapny: string) => {
    setPrevTabFilter(comapny);
    dispatch(setActiveTab(3));
  };

  const handleSorting = (column: string) => {
    if (sortColumn !== column) {
      setSortColumn(column);
      setSortOrder('asc');
    } else {
      const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
      setSortOrder(newSortOrder);
    }
  };

  if (error)
    return (
      <Typography variant='h6' className={classes.error}>
        Error fetching records
      </Typography>
    );

  return <>
  <Grid item xs={10} className={classes.mb}>
    {!user.roles.includes(UserType.PROGRAM_COORDINATOR) &&
      <Grid container item xs={12} justifyContent='flex-end' className={classes.addEmployerButtonGrid}>
        <Grid item xs={3}>
          <Button
            color='primary'
            variant='contained'
            onClick={() => history.push('/add/employer')}
            className={classes.addEmployerButton}
          >
            <AddIcon /> Add New Employee
          </Button>
        </Grid>
      </Grid>
    }
      {!user.roles.includes(UserType.PROGRAM_COORDINATOR) &&
        <Grid container item xs={12} justifyContent='flex-end' className={classes.addEmployerButtonGrid}>
          <Grid item xs={3}>
            <Button
              color='primary'
              variant='contained'
              onClick={() => history.push('/add/newemployer')}
              className={classes.addEmployerButton}
            >
              <AddIcon /> Add New Employer
            </Button>
          </Grid>
        </Grid>
      }
      <Grid container spacing={2} className='tempgrid'>
        <Grid item xs={9}>
          <TextField
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            value={query}
            label={`Search Employer`}
          />
        </Grid>
      { user.roles.includes('UPREACH_ADMIN') && <Grid item xs={3} className={classes.editButton}>
        <Button className="edit-section" variant="text" onClick={()=>setIsEmployeesModalOpen(true)} > <EditIcon /> Edit Employer Limit</Button>
      </Grid>}
    </Grid>
    {isLoading && (
      <Grid container className={classes.tableReplaceGrid} item xs={12}>
        <Grid item xs={1}>
          <CircularProgress className='no-user-text'/>
        </Grid>
      </Grid>
    )}
     
    <Grid item xs={12} className={classes.mt3}>
    {!isLoading  && data && data.dashboardData && data.dashboardData.length > 0 && (
      <>   
          <Grid container item xs={12} justifyContent='flex-end'>
            <CSVLink
                  style={{ visibility: 'hidden' }}
                  data={csvData}
                  filename={'employer.csv'}
                  ref={csvButtonRef}
            ></CSVLink>
              <Button className={classes.csvButton} onClick={handleDownloadCSVClicked}>
                <AiOutlineDownload className={classes.downloadIcon} /> Download as CSV
              </Button>
          </Grid>
            <TopEngagedTable
              userRoles={[UserType.UNIVERSITY_ADMIN,UserType.PROGRAM_COORDINATOR]}
              tableHeadTitles={employerTableHead}
              tableData={data ? data.dashboardData : []}
              clickHandler={handleCompanyClicked}
              showAverageInteractions={true}
              onSort={handleSorting}
            />
      </>
    )}
    </Grid>
    {data && (
      <Pagination
        onChange={handlePageChange}
        page={currentPage}
        count={data.noOfPagesAvailable}
      />
    )}
  </Grid>
  <Modal isOpen={isEmployeesModalOpen} title='Edit Employer Qouta Limit' onClose={handleEmployeesModal}>
      <EditEmployerLimitTable/>
    </Modal>
  </>;
};

export default EmployerOverview;

const useStyles = makeStyles(() => ({
  editButton:{
    display:'flex',
    flexDirection:'column-reverse',
    paddingRight:'11px',
    color:'black !important',
  },
  mt3:{
    marginTop:'30px'
  },
  addEmployerButton:{
    marginTop:'30px',
    marginLeft:'50px',
    display:'flex',
    justifyContent:'center',
    backgroundColor:'#3f51b5',
    '&:hover': {
      backgroundColor: '#303f9f !important',
    },
  },
  csvButton: {
    color: '#464FD0',
    textTransform: 'none',
    textDecoration: 'underline',
    fontFamily: 'Nunito Sans',
    fontSize: '1.1em',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  },
  downloadIcon: {
    fontSize: '1.5em',
    marginRight: '0.2em',
  },
}));