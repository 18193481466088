import styled from 'styled-components/macro';
import CircularProgress from '@mui/material/CircularProgress';

const Loading = () => {
  return (
    <StyledWrapper>
      <CircularProgress className='no-user-text' />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Loading;
