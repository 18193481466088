// import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
// import Table from '@material-ui/core/Table/Table';
// import TableContainer from '@material-ui/core/TableContainer/TableContainer';
import TableContainer from '@mui/material/TableContainer';
import { useStyles } from './Professionals/styles';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';

function createData(
    name: string,
    associate: number,
    alumni: number,
    total: number,
) {
    return {
        name,
        associate,
        alumni,
        total,
    };
}

export default function InteractionDetailCard(interactionData: any) {
    const classes = useStyles();

    const { studentInteractionData, alumniInteractionData } = interactionData.interactionData;

    const rows: any[] = [
        createData('Total Interaction', studentInteractionData.totalInteractions, alumniInteractionData.totalInteractions, studentInteractionData.totalInteractions + alumniInteractionData.totalInteractions),
        createData('Total Completed Interaction', studentInteractionData.totalCompletedInteractions, alumniInteractionData.totalCompletedInteractions, studentInteractionData.totalCompletedInteractions + alumniInteractionData.totalCompletedInteractions),
        createData('Number of Expert Advice', studentInteractionData.expertAdvice, alumniInteractionData.expertAdvice, studentInteractionData.expertAdvice + alumniInteractionData.expertAdvice),
        createData('Number of Career Chats', studentInteractionData.careerChat, alumniInteractionData.careerChat, studentInteractionData.careerChat + alumniInteractionData.careerChat),
        createData('Number of Mock Interviews', studentInteractionData.mockInterview, alumniInteractionData.mockInterview, studentInteractionData.mockInterview + alumniInteractionData.mockInterview),
        createData('Number of Expired Requests', studentInteractionData.expiredInteractions, alumniInteractionData.expiredInteractions, studentInteractionData.expiredInteractions + alumniInteractionData.expiredInteractions),
    ];

    return (
        <TableContainer className={classes.cardContainer}>
            <p className={classes.tableHeading}>All Support Received</p>
            <hr />
            <Table >
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.rowHead}></TableCell>
                        <TableCell className={classes.rowHead} align="center">Associate</TableCell>
                        <TableCell className={classes.rowHead} align="center">Alumni</TableCell>
                        <TableCell className={classes.rowHead} align="center">Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                        >
                            <TableCell className={`${classes.pd5px} ${classes.rowName}`}>
                                {row.name}
                            </TableCell>
                            <TableCell className={`${classes.pd5px} ${classes.values}`} align="center">{row.associate}</TableCell>
                            <TableCell className={`${classes.pd5px} ${classes.values}`} align="center">{row.alumni}</TableCell>
                            <TableCell className={`${classes.pd5px} ${classes.values}`} align="center">{row.total}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
