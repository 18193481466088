import { getLabelsFor30Days } from 'utils/functions/getLabelsForChart';
import { chartLabels, chartOptions, stylesForLine } from 'utils/constants/chartConstants';
import { Chart } from 'app/components';

type PropsType = {
  chartData: any;
};

const OverviewChart = ({ chartData }: PropsType) => {
  const { initialDateOfInteraction, lastDateOfInteraction, ...newChartData } = chartData;
  
  const dataForChart = {
    labels: getLabelsFor30Days(initialDateOfInteraction, lastDateOfInteraction),
    datasets: Object.keys(newChartData).map((label) => ({
      label: chartLabels[label],
      data: chartData[label],
      backgroundColor: stylesForLine[label],
      borderColor: stylesForLine[label],
      pointBackgroundColor: stylesForLine[label],
      pointBorderColor: stylesForLine[label],
    })),
  };
  return (
    <Chart
      chartTitle={'Activity Monitor'}
      labels={dataForChart.labels}
      datasets={dataForChart.datasets}
      options={chartOptions}
    />
  );
};

export default OverviewChart;
