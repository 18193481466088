import React from 'react';
import { Button, Card, Grid, TextField } from '@mui/material';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import getAxiosInstance from 'utils/axios';
import { showToast, ToastMessage, ToastType } from 'app/components/Toast/slice';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { UserType } from 'types';

type PCType = {
  email: string;
  prefEmail?: string | null;
  firstName?: string;
  lastName?: string;
}

const UpreachStaff = () => {
  const [programCoordinators, setProgramCoordinators] = useState<PCType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    const employers = [];
    for (let i = 0; i < 3; i++)
      employers.push({
        email: '',
        firstName: '',
        lastName: '',
        roles: UserType.PROGRAM_COORDINATOR
      });
    setProgramCoordinators(employers);
  }, []);

  const incrementInputFieldCount = () => {
    const tempEmployers = [...programCoordinators];
    tempEmployers.push({
      email: '',
      firstName: '',
      lastName: '',
    });
    setProgramCoordinators(tempEmployers);
  };

  const handleFieldValueChange = (value: string, index: number, field: string) => {
    const tempEmployersArray = [...programCoordinators];
    const tempEmployer = {
      ...programCoordinators[index],
      [field]: value,
    };
    tempEmployersArray[index] = tempEmployer;
    setProgramCoordinators(tempEmployersArray);
  };

  const CheckValidPayload = () => {
    for (let i = 0; i < programCoordinators.length; i++) {
      const proCoord = programCoordinators[i];
      if (
        (!proCoord.email && !proCoord.firstName && !proCoord.lastName) ||
        ((proCoord.email && proCoord.firstName && proCoord.lastName) && /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(proCoord.email))
      )
        continue;
      else return false;
    }
    return true;
  };

  const resetFieldValues = () => {
    const tempEmployers: PCType[] = [];
    programCoordinators.forEach(() => {
      tempEmployers.push({
        email: '',
        firstName: '',
        lastName: '',
      });
    });
    setProgramCoordinators(tempEmployers);
  };

  const handleAddPCs = async () => {
    try {
      const isValidPayload = CheckValidPayload();
      if (!isValidPayload) {
        const toast: ToastMessage = {
          type: ToastType.ERROR,
          message: 'Please complete all fields in the row to add members successfully.',
        };
        dispatch(showToast(toast));
        return;
      }

      const filteredPCs = programCoordinators.filter((pc) => pc.email.trim() !== '');

      setIsLoading(true);
      const axios = await getAxiosInstance();
      const { data } = await axios.post('/dashboard/addProgramCoordinators', {
        programCoordinators: filteredPCs,
      });
      const toast: ToastMessage = {
        type: ToastType.SUCCESS,
        message: data.message,
      };
      dispatch(showToast(toast));
      resetFieldValues();
      history.push('/home');
    } catch (error: any) {
      console.log(error);
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: error?.response?.data?.message,
      };
      dispatch(showToast(toast));
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Grid container justifyContent='flex-start' className={classes.container}>
      <Grid item xs={7} alignItems='stretch' justifyContent='flex-start'>
        <ArrowBackIosIcon fontSize='small' style={{ margin: '-0.2em' }} />
        <button
          onClick={() => history.push('/home')}
          className={`${classes.plainButtons} ${classes.back}`}
        >
          Home
        </button>
      </Grid>
      <Grid item xs={10} className={classes.marginTop3}>
        <Typography variant='subtitle1'>Add Programme Coordinator</Typography>
      </Grid>
      <Grid item xs={11} className={classes.marginTop1}>
        <Card className={classes.shadow}>
          <Grid container className={classes.paddingContainer} justifyContent='space-evenly'>
            {React.Children.toArray(
              programCoordinators.map((pc: PCType, index: number) => (
                <Grid container item xs={12} justifyContent='space-evenly'>
                  <Grid item xs={12} className={`${classes.marginTop3} ${classes.marginLeft}`}>
                    <Typography variant='h6'>Programme Coordinator {index + 1}</Typography>
                  </Grid>
                  <Grid container item xs={12} justifyContent='space-evenly'>
                    <Grid item xs={6} className={`${classes.marginTop1} ${classes.marginRight}`}>
                      <TextField
                        required={true}
                        value={pc.firstName}
                        onChange={(e) => handleFieldValueChange(e.target.value, index, 'firstName')}
                        id='outlined-multiline-static'
                        label={"First Name"}
                        variant='outlined'
                        className={classes.textFieldWidth}
                      />
                    </Grid>
                    <Grid item xs={5} className={classes.marginTop1}>
                      <TextField
                        required={true}
                        value={pc.lastName}
                        onChange={(e) => handleFieldValueChange(e.target.value, index, 'lastName')}
                        id='outlined-multiline-static'
                        label={"Last Name"}
                        variant='outlined'
                        className={classes.textFieldWidth}
                      />
                    </Grid>

                  </Grid>
                  <Grid container item xs={12} justifyContent='space-evenly'>
                    <Grid item xs={6} className={`${classes.marginTop1} ${classes.marginRight}`}>
                      <TextField
                        required={true}
                        value={pc.email}
                        type="email"
                        onChange={(e) => handleFieldValueChange(e.target.value, index, 'email')}
                        id='outlined-multiline-static'
                        label={"Email"}
                        variant='outlined'
                        className={classes.textFieldWidth}
                      />
                    </Grid>
                    <Grid item xs={5} className={classes.marginTop1}>
                      <TextField
                        required={true}
                        value={UserType.PROGRAM_COORDINATOR}
                        label={"Role"}
                        variant='outlined'
                        className={classes.textFieldWidth}
                        disabled
                      />
                    </Grid>

                  </Grid>
                </Grid>
              )),
            )}
            <Grid container item xs={12} className={classes.marginTop3}>
              <Grid item xs={3}>
                <button
                  onClick={incrementInputFieldCount}
                  className={`${classes.plainButtons}  ${classes.marginLeft}`}
                >
                  Add New
                </button>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.buttons}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleAddPCs}
                disabled={isLoading}
                className={classes.buttonMarginLeft}
              >
                Add Members
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default UpreachStaff;
