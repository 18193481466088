import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type NavigationSlice = {
  activeTab: number;
};

export const initialState: NavigationSlice = {
  activeTab: 0,
};

const slice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setActiveTab: (state: NavigationSlice, action: PayloadAction<any>) => {
      state.activeTab = action.payload;
    },
  },
});

export const { setActiveTab } = slice.actions;

export default slice.reducer;
