import makeStyles from '@mui/styles/makeStyles';
export const useStyle = makeStyles(() => ({
  container: {
    marginTop: '2em',
    marginLeft: '5em',
    paddingBottom: '5em',
  },
  paddingContainer: {
    paddingLeft: '1em',
    paddingTop: '2em',
  },
  textFieldWidth: {
    width: '80%',
  },
  marginTop1: {
    marginTop: '1em',
  },
  marginTop3: {
    marginTop: '3em',
  },
  marginTop5: {
    marginTop: '5em',
  },
  buttons: {
    marginTop: '2em',
    marginBottom: '4em',
  },
  buttonMarginLeft: {
    marginLeft: '1em',
    backgroundColor: '#3f51b5 !important',
    color: 'white !important'
  },
  shadow: {
    boxShadow: '0px 0px 36px 13px rgba(0, 0, 0, 0.05)',
  },
  plainButtons: {
    background: 'none',
    border: 'none',
    color: 'rgba(70, 79, 208, 1)',
    fontSize: '1em',
    textDecoration: 'underline',
    padding: '0em',
  },
  back: {
    textDecoration: 'none !important',
    fontWeight: 'bolder',
  },
  tab: {
    marginLeft: '2em',
    color: '#464FD0',
    background: '#FFFFFF',
    border: '1px solid #C4C4C4',
    boxShadow: ' 0px 4px 9px rgba(0, 0, 0, 0.1)',
    width: '40%',
    textAlign: 'left',
    maxWidth:'264px !important',
    maxHeight:'48px !important'
  },
  activeTab: {
    marginLeft: '2em',
    color: '#3f51b5',
    background: '#DEE1FF',
    border: '1px solid #464FD0',
    boxShadow: ' 0px 4px 9px rgba(0, 0, 0, 0.1)',
    width: '40%',
    textAlign: 'left',
    maxWidth:'264px !important',
    maxHeight:'48px !important'
  },
}));
