import styled from '@emotion/styled';
import { TextField as MaterialTextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
// import styled from 'styled-components';

export enum InputType {
  TEXT = 'text',
  PASSWORD = 'password',
  NUMBER = 'number',
  EMAIL = 'email',
  DATE = 'date',
}

type PropsType = {
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value?: string | number;
  disable?:boolean;
  label?: string;
  rest?: any;
  fullWidth?: boolean;
  name?: string;
  type?: InputType;
  inputRef?: any;
  error?: boolean;
  helperText?: string;
  size?: 'small' | 'medium';
  style?: any;
  formStyle?:any;
};

const TextField = ({
  onChange,
  value,
  disable,
  label,
  fullWidth,
  name,
  type,
  inputRef,
  error,
  helperText,
  style,
  formStyle,
  size = 'small',
  ...rest
}: PropsType) => {
  const classes = useStyle();
  return (
    <StyledForm noValidate autoComplete='off' style={formStyle} onSubmit={(e) => e.preventDefault()}>
      <MaterialTextField
        name={name}
        type={type}
        fullWidth={fullWidth}
        className={classes.textField}
        label={label}
        variant='outlined'
        disabled={disable}
        onChange={onChange}
        value={value}
        inputRef={inputRef}
        helperText={helperText}
        error={error}
        {...rest}
        size={size}
        style={style}
      />
    </StyledForm>
  );
};

export default TextField;

const StyledForm = styled.form`
  margin-top: 3em;
`;

const useStyle = makeStyles(() => ({
  textField: {
    backgroundColor: '#fff',
    '& input': {
      border: '1px solid #C4C4C4',
    },
    outline: 'none',
    width: '100%',
    maxWidth: '400px',
  },
}));
