import { Dispatch, SetStateAction, useEffect, useState, Children } from 'react';
import { Grid, GridSize, Typography } from '@mui/material';
import { PendingProfessionalService } from 'services/pendingProfessionals';
import { showToast } from 'app/components/Toast/slice';
import { useDispatch } from 'react-redux';
import { Pagination } from 'app/components';
import { useStyles } from './style';
import PendingProfessinalCard from './PendingProfessionalCard';

type PropsType = {
  setLoader: Dispatch<SetStateAction<boolean>>;
  query: string;
  companyName: string;
  selectedPageSize: string;
};

const headers = [
  { title: 'Email', gridSize: 5 },
  { title: 'Invited On', gridSize: 4 },
  { title: '', gridSize: 3 },
];

const PendingTable = ({ query, companyName, setLoader, selectedPageSize }: PropsType) => {
  const [pendingProfessionals, setPendingProfessionals] = useState<any[]>([]);
  const [totalNoOfPages, setTotalNoOfPAges] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (currentPage) {
      (async () => {
        setLoader(true);
        const { data }: any = await PendingProfessionalService.fetchPendingProfessionals(
          query,
          currentPage,
          companyName,
          selectedPageSize,
        );
        setPendingProfessionals(data.dashboardData);
        setTotalNoOfPAges(data.noOfPagesAvailable);
        setLoader(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, query, companyName, selectedPageSize]);

  const handleDelete = async (professionalId: string) => {
    setIsLoading(true);
    const response = await PendingProfessionalService.deletePendingProfessionals(professionalId);
    if (response?.valid) {
      const tempPendingProfessionals = [...pendingProfessionals];
      const filteredPendingProfessionals = tempPendingProfessionals.filter(
        (professional: any) => professional.id !== professionalId,
      );
      setPendingProfessionals(filteredPendingProfessionals);
    }
    dispatch(showToast(response.toastMessage));
    setIsLoading(false);
  };

  return (
    <Grid container item xs={11} className={classes.pendingContainer}>
      {pendingProfessionals.length > 0 && (
        <>
          <Grid container item xs={11}>
            {Children.toArray(
              headers.map((header) => (
                <Grid item xs={header.gridSize as GridSize} className={classes.header}>
                  <Typography>
                    <strong>{header.title}</strong>
                  </Typography>
                </Grid>
              )),
            )}
          </Grid>
          {Children.toArray(
            pendingProfessionals.map((pendingProfessional: any) => (
              <PendingProfessinalCard
                isLoading={isLoading}
                pendingProfessional={pendingProfessional}
                handleDelete={handleDelete}
              />
            )),
          )}
          <Pagination
            onChange={(e, pageNo) => setCurrentPage(pageNo)}
            page={currentPage}
            count={totalNoOfPages}
          />
        </>
      )}
    </Grid>
  );
};

export default PendingTable;
