import React, { Children } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import TableDataCell from './TableDataCell';
import { UserType } from 'types';
import { Table } from 'app/components';
import { FaSort } from 'react-icons/fa';

type PropsType = {
  tableHeadTitles: string[];
  tableData: any[];
  userRoles: string[];
  showAverageInteractions?: boolean;
  clickHandler?: (x: string) => void;
  onSort?: (c: string) => void;
};

function TopEngagedTable({
  tableHeadTitles,
  tableData = [],
  userRoles,
  showAverageInteractions = false,
  clickHandler,
  onSort
}: PropsType) {
  const classes: any = useStyles();

  return (
    <Table
      tableHeader={
        <TableRow>
          {React.Children.toArray(
            tableHeadTitles.map((title: string, index: number) => (
              <TableCell align={'center'} className={classes.state} 
              onClick={
                () => onSort && onSort(title)
              }>
                <strong> {title}</strong>
                { (title === 'TOTAL INTERACTIONS' || title === 'NO. OF STUDENTS' || title === 'NO. OF PROFESSIONALS') && <FaSort />}
              </TableCell>
            )),
          )}
        </TableRow>
      }
      tableBody={
        <>
          {Children.toArray(
            tableData.map((data: any, index: number) => (
              <TableDataCell
                index={index}
                organizationName={
                  userRoles.includes(UserType.EMPLOYER) ? data.university : data.employer
                }
                totalInteractions={data.totalInteractions}
                noOfUser={
                  userRoles.includes(UserType.EMPLOYER)
                    ? data.totalNoOfStudents
                    : data.totalNoOfProfessionals
                }
                showAverageInteractions={showAverageInteractions}
                clickHandler={clickHandler}
              />
            )),
          )}
        </>
      }
    />
  );
}

export default TopEngagedTable;

const useStyles = makeStyles(() => ({
  tableHead: {
    background: '#DEE1FF',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  topTableHeader: {
    '& .MuiTableCell-head': {
      borderBottom: '0px',
      borderRight: '2px solid white',
    },
  },
}));
