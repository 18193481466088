import { useSelector } from "react-redux";
import { selectUser } from "redux/auth/selectors";
import { selectInteractions } from "redux/interactions/selectors";
import { Message, Interaction, MessageType, User, UserType } from "types";

type Props = {
    message: Message;
    interaction: Array<Interaction>;
};
const UnabletoAttendMessage = ({ message, interaction }: Props) => {
    const user: User | null = useSelector(selectUser);
    const user1: any = useSelector(selectUser);
    const interactions = useSelector(selectInteractions);

    const currentInteraction = interactions[0]
    const userName = currentInteraction?.connectionProfile[user1.firstName];
    if (message.sender === "SYSTEM") return null;
    return (
        <div>
            {
                message.type.includes(MessageType.STUDENT_NO_SHOW) && message.sender.roles.includes(UserType.STUDENT) &&
                <>
                    <span style={{ textTransform: "capitalize" }} className="body-17 font-weight-bolder color-gulf-blue ">
                        {user?.id === message.sender.id ? "You" : message.sender.firstName}
                    </span>
                    <span className="body-17 font-weight-bolder color-gulf-blue">
                        &nbsp; reported
                    </span>
                    <span className="body-17 font-weight-bolder color-gulf-blue">
                        &nbsp; {user?.id === message.sender.id ? "yourself" : "themselves"}
                    </span>
                    <span className="body-17 font-weight-bolder color-gulf-blue">
                        &nbsp; as a no-show
                    </span>
                </>
            } {
                message.type.includes(MessageType.STUDENT_NO_SHOW) && message.sender.roles.includes(UserType.PROFESSIONAL) &&
                <>
                    <span style={{ textTransform: "capitalize" }} className="body-17 font-weight-bolder color-gulf-blue ">
                        {user?.id === message.sender.id ? "You" : message.sender.firstName}
                        <span style={{ textTransform: "none" }}>
                            {user?.id !== message.sender.id && " has"}
                        </span>
                    </span>
                    <span className="body-17 font-weight-bolder color-gulf-blue">
                        &nbsp;reported
                    </span>
                    <span className="body-17 font-weight-bolder color-gulf-blue">
                        &nbsp; {user?.id === message.sender.id ? userName : "you"}
                    </span>
                    <span className="body-17 font-weight-bolder color-gulf-blue">
                        &nbsp; as a no-show
                    </span>
                </>
            }
            {
                message.type.includes(MessageType.PROFESSIONAL_NO_SHOW) && message.sender.roles.includes(UserType.STUDENT) &&
                <>
                    <span style={{ textTransform: "capitalize" }} className="body-17 font-weight-bolder color-gulf-blue ">
                        {user?.id === message.sender.id ? "You" : message.sender.firstName}
                        <span style={{ textTransform: "none" }}>
                            {user?.id !== message.sender.id && " has"}
                        </span>
                    </span>
                    <span className="body-17 font-weight-bolder color-gulf-blue">
                        &nbsp;reported
                    </span>
                    <span className="body-17 font-weight-bolder color-gulf-blue">
                        &nbsp;{user?.id === message.sender.id ? userName : "you"}
                    </span>
                    <span className="body-17 font-weight-bolder color-gulf-blue">
                        &nbsp;as a no-show
                    </span>
                </>
            } {
                message.type.includes(MessageType.PROFESSIONAL_NO_SHOW) && message.sender.roles.includes(UserType.PROFESSIONAL) &&
                <>
                    <span style={{ textTransform: "capitalize" }} className="body-17 font-weight-bolder color-gulf-blue ">
                        {user?.id === message.sender.id ? "You" : message.sender.firstName}
                    </span>
                    <span className="body-17 font-weight-bolder color-gulf-blue">
                        &nbsp;reported
                    </span>
                    <span className="body-17 font-weight-bolder color-gulf-blue">
                        &nbsp; {user?.id === message.sender.id ? "yourself" : "themselves"}
                    </span>
                    <span className="body-17 font-weight-bolder color-gulf-blue">
                        &nbsp; as a no-show
                    </span>
                </>
            }
        </div>
    )
}
export default UnabletoAttendMessage;