import { Grid } from '@mui/material';
import { ChangeEvent } from 'react';
import { Pagination } from 'app/components';
import { useStyles } from '../styles';
import DeactivateInteractionStatsTable from 'app/containers/InteractionStatsTable/DeactivateInteractionStatsTable';
type PropsType = {
  data: any;
  currentPage: number;
  handlePageChange: (event: ChangeEvent<unknown>, pageNo: number) => void;
};
const ActiveTableContainer = ({
  data,
  currentPage,
  handlePageChange,
}: PropsType) => {
  const classes = useStyles();
  if (!data || !data.dashboardData || !(data.dashboardData.length > 0)) return null;
  return (
    <Grid item xs={10} className={classes.mt2}>
      <DeactivateInteractionStatsTable
        tableData={data && data.dashboardData ? data.dashboardData : []}
      />
      <Pagination onChange={handlePageChange} page={currentPage} count={data.noOfPagesAvailable} />
    </Grid>
  );
};
export default ActiveTableContainer;