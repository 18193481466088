import { Avatar } from "@mui/material";
// import styled from "styled-components";
import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import call from "../../../assets/call.svg";
import avatar from "../../../assets/avatar.svg";
import { Link, useParams } from "react-router-dom";
import { InteractionState, InteractionType, UserType } from "types";
import InteractionStateWidgetFactory from "./InteractionStateWidgetFactory";
import styled from "@emotion/styled";

const colorMap: any = {
    [InteractionType.EXPERT_ADVICE]: "background-sea-blue",
    [InteractionType.CAREER_CHAT]: "background-persian-green",
    [InteractionType.MOCK_INTERVIEW]: "background-ceru",
    [InteractionType.MENTORING]: "background-gulf-blue",
};

type Props = {
    interaction: any;
    handleClick: any;
};

const InteractionTile = ({ interaction, handleClick: handleOpen }: Props) => {
    const { id } = useParams<Record<string, string | undefined>>();
    const [participant, setParticipant] = useState<any>();

    useEffect(() => {
        if (id) setParticipant(interaction.connectionProfile[id]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const urlWithHttp = (url: any) => !/^https?:\/\//i.test(url) ? `http://${url}` : url;
    const videoCallUrl = interaction?.callDetails && urlWithHttp(interaction?.callDetails);

    return (
        <StyledWrapper
            className="border-radius-5 background-white"
        >
            <div className="grid-container">
                <div
                    onClick={handleOpen}
                    className={`justify-center active-highlight color-white cursor-pointer highlight border-radius-rtb-5 one ${colorMap[interaction.type]
                        }`}
                >
                    <span className="body-20 font-weight-bold text-capitalize text-center">
                        {interaction?.type.replaceAll("_", " ").toLowerCase()}
                    </span>
                </div>
                <div
                    className="active-highlight space-around cursor-pointer two"
                >
                    <div className="name-avatar">
                        <Link className="redirect-to" to={`/users/${participant?.id}`}>
                            <Avatar className="profile-avatar-tile mx-auto"
                                src={participant?.profilePicture || avatar}
                            /></Link>
                        <div>
                            <Link className="redirect-to" to={`/users/${participant?.id}`}>
                                <p className="body-17 font-weight-bold my-0 text-center color-black">{`${participant?.firstName} ${participant?.lastName}`}</p>
                            </Link>
                            {participant?.roles.includes(UserType.ALUMNI) && <span onClick={handleOpen} className="body-15 text-center d-block">{participant?.company && participant?.company}</span>}
                            <span onClick={handleOpen} className="body-15 text-center d-block">
                                {participant?.company?.name || participant?.university?.name}
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    onClick={handleOpen}
                    className="active-highlight space-around cursor-pointer three"
                >
                    {interaction?.scheduledFor && (

                        <div >
                            <p className="body-15 font-weight-bold my-0 mx-auto text-center">
                                {format(parseISO(interaction?.scheduledFor), "d.M.y - HH:mm")}
                            </p>
                        </div>
                    )}

                </div>
                <div onClick={!interaction?.callDetails && handleOpen} className={`cursor-pointer four ${interaction?.callDetails && "mx-auto"}`}>
                    {interaction?.callDetails && interaction.state !== InteractionState.COMPLETED && (
                        <a target="_blank" rel="noreferrer" href={videoCallUrl}>
                            <img width="30px" src={call} alt="" />
                        </a>
                    )}

                </div>
                <div
                    onClick={handleOpen}
                    className="justify-center cursor-pointer body-15  text-center mx-auto five"
                >
                    <InteractionStateWidgetFactory
                        interactionType={interaction.type}
                        interactionState={interaction.state}
                        userType={participant?.roles.includes(UserType.PROFESSIONAL) ? UserType.PROFESSIONAL : UserType.STUDENT}
                    />
                </div>
            </div>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    margin-top: 30px;
    .grid-container {
        display: grid;
        grid-template-columns: fit-content(20%) 1fr 1fr 1fr 1fr 1fr;
    }

    .one {
        grid-column: 1 / 3;
    }

    .five {
      width: 100%;
      height: 100%;
    }

    .two {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .body-15, .body-20, .body-17, .body-13 {
        @media (max-width: 600px) {
            font-size: 12px;
            line-height: 17px
        }
    }

    .profile-avatar-tile {
        margin: auto;
    }

    .border-radius-rtb-5 {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .redirect-to {
    color: black;
    text-decoration: none;
  }

  .redirect-to:hover {
    text-decoration: underline;
  }
`;

export default InteractionTile;
