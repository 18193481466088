export enum InputType {
    TEXT = "text",
    PASSWORD = "password",
    NUMBER = "number",
    EMAIL = "email",
    DATE = "date"
  }

export type LoginFormType = {
    email: string;
    password: string;
};
