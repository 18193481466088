import { Modal } from 'app/components';
import MyAssociatesTable from './MyAssociatesTable';
import { useEffect, useState } from 'react';

const ReferralSystem = ({ isOpen, title, onClose }: any) => {
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false)
    const [open, setOpen] = useState(false)
 
    useEffect(() => {
        setOpen(isOpen)
        setIsMessageModalOpen(false)
    }, [isOpen])
    const openModal = () => {
        setOpen(false)
        setIsMessageModalOpen(true)
       
    }
    return (
        <>
            <Modal isOpen={open} title={title} onClose={onClose}>
                <MyAssociatesTable onClose={onClose} modalOpen={openModal} />
            </Modal>
            <Modal isOpen={isMessageModalOpen} onClose={onClose}>
                <div style={{marginTop:'-32.5px',textAlign:'center',marginRight:'10px'}}>
                    <p style={{fontSize: "1.2em"}}>
                        Thank you for referring your Associate. They will have just received an email recommending them to engage with the professional you selected.
                        During your next 1-to-1, check in with the Associate and discuss their progress and whether they actioned the referral.
                    </p>
                </div>
            </Modal>
        </>
    )
}

export default ReferralSystem;
