import React from 'react';
import { Button, Card, Grid, TextField } from '@mui/material';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import getAxiosInstance from 'utils/axios';
import { showToast, ToastMessage, ToastType } from 'app/components/Toast/slice';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { DocumentPicker } from 'app/components';
import { Company, UserType } from 'types';

type EmployerType = {
  email: string;
  name: string;
  role: string;
  company?: Company | null;
};

const TextFieldsData = [
  { label: 'Company Representative Name', disabled: false, field: 'name' },
  { label: 'Representative’s Email', disabled: false, field: 'email' },
  { label: 'Role', disabled: true, field: 'role' },
];

const AddEmployers = () => {
  const [employers, setEmployers] = useState<EmployerType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    const employers = [];
    for (let i = 0; i < 4; i++)
      employers.push({
        email: '',
        name: '',
        role: UserType.EMPLOYER,
      });
    setEmployers(employers);
  }, []);

  const incrementInputFieldCount = () => {
    const tempEmployers = [...employers];
    tempEmployers.push({
      email: '',
      name: '',
      role: UserType.EMPLOYER,
    });
    setEmployers(tempEmployers);
  };

  const handleFieldValueChange = (value: string | Company, index: number, field: string) => {
    const tempEmployersArray = [...employers];
    const tempEmployer = {
      ...employers[index],
      [field]: value,
    };
    tempEmployersArray[index] = tempEmployer;
    setEmployers(tempEmployersArray);
  };

  const CheckValidPayload = () => {
    for (let i = 0; i < employers.length; i++) {
      const employer = employers[i];
      if (
        (!employer.company && !employer.email && !employer.name) ||
        (employer.company && employer.email && employer.name)
      )
        continue;
      else return false;
    }
    return true;
  };

  const resetFieldValues = () => {
    const tempEmployers: EmployerType[] = [];
    employers.forEach(() => {
      tempEmployers.push({
        email: '',
        name: '',
        company: null,
        role: UserType.EMPLOYER,
      });
    });
    setEmployers(tempEmployers);
  };

  const handleAddMembers = async () => {
    try {
      const isValidPayload = CheckValidPayload();
      if (!isValidPayload) {
        const toast: ToastMessage = {
          type: ToastType.ERROR,
          message: 'Please complete all fields in the row to add member successfully.',
        };
        dispatch(showToast(toast));
        return;
      }
      const filteredEmployers = employers.filter((employer) => employer.email.trim() !== '');
      setIsLoading(true);
      const axios = await getAxiosInstance();
      const { data } = await axios.post('/dashboard/addEmployers', {
        employers: filteredEmployers,
      });
      const toast: ToastMessage = {
        type: ToastType.SUCCESS,
        message: data.message,
      };
      dispatch(showToast(toast));
      resetFieldValues();
      history.push('/home');
    } catch (error: any) {
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: error?.response?.data?.message,
      };
      dispatch(showToast(toast));
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Grid container justifyContent='flex-start' className={classes.container}>
      <Grid item xs={7} alignItems='stretch' justifyContent='flex-start'>
        <ArrowBackIosIcon fontSize='small' style={{ margin: '-0.2em' }} />
        <button
          onClick={() => history.push('/home')}
          className={`${classes.plainButtons} ${classes.back}`}
        >
          EMPLOYERS
        </button>
      </Grid>
      <Grid item xs={10} className={classes.marginTop3}>
        <Typography variant='subtitle1'>Add Employees</Typography>
      </Grid>
      <Grid item xs={11} className={classes.marginTop1}>
        <Card className={classes.shadow}>
          <Grid container className={classes.paddingContainer} justifyContent='space-evenly'>
            {React.Children.toArray(
              employers.map((employer: EmployerType, index: number) => (
                <Grid container item xs={12} justifyContent='space-evenly'>
                  <Grid item xs={2} className={`${classes.marginTop1} ${classes.marginRight}`}>
                    <DocumentPicker
                      label='Company Name'
                      name='comapny name'
                      collection='companies'
                      handleChange={(value: Company) =>
                        handleFieldValueChange(value, index, 'company')
                      }
                    />
                  </Grid>
                  {React.Children.toArray(
                    TextFieldsData.map((textField) => (
                      <Grid item xs={3} className={classes.marginTop1}>
                        <TextField
                          required={true}
                          disabled={textField.disabled}
                          value={(employer as any)[textField.field]}
                          onChange={(e) =>
                            handleFieldValueChange(e.target.value, index, textField.field)
                          }
                          id='outlined-multiline-static'
                          label={textField.label}
                          variant='outlined'
                          className={classes.textFieldWidth}
                        />
                      </Grid>
                    )),
                  )}
                </Grid>
              )),
            )}
            <Grid container item xs={12} className={classes.marginTop3}>
              <Grid item xs={3}>
                <button
                  onClick={incrementInputFieldCount}
                  className={`${classes.plainButtons} ${classes.buttonMarginLeft}`}
                >
                  Add New
                </button>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.buttons}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleAddMembers}
                disabled={isLoading}
                className={classes.buttonMarginLeft}
              >
                Add Members
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AddEmployers;
