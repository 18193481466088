import { CircularProgress, Grid, Typography,InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState, useRef } from 'react';
import useDebounce from 'utils/hooks/useDebounce';
import TableContainer from './TableContainer';
import { useReactQuery } from 'utils/hooks/useReactQuery';
import { Filter, TextField } from 'app/components';
import { useStyles } from '../Professionals/styles';
import { sortColumnToAlgoliaFieldMapping } from 'utils/constants/sortColumnToAlgoliaFieldMapping';
import InteractionDetailCard from '../AlumniStudentInteractionDetailCard';

import { CSVLink } from 'react-csv';
import { CSVDataType, DashboardDataType } from './types';
import { AiOutlineDownload } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { showToast, ToastType } from 'app/components/Toast/slice';
import { Button} from '@mui/material';
type PropsType = {
  prevTabfilter: string;
  setPrevTabFilter: Dispatch<SetStateAction<string>>;
};

export enum AlumniAvailableFilters {
  SUPPORT_RECEIVED = 'Support Received',
  SUPPORT_GIVEN = 'Support Given',
}

const AllAlumnis = ({ prevTabfilter, setPrevTabFilter }: PropsType) => {
  const [query, setQuery] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedFilter, setSelectedFilter] = useState<any>({ name: AlumniAvailableFilters.SUPPORT_GIVEN });
  const [sortColumn, setSortColumn] = useState('Total');
  const [sortOrder, setSortOrder] = useState('desc');
  const [selectedPageSize, setSelectedPageSize] = useState<any>(10);

  const debouncedQuery = useDebounce(query, 300);
  const classes = useStyles();

  const csvButtonRef: any = useRef();
  const dispatch = useDispatch();
  const [csvData, setCSVData] = useState<CSVDataType[]>([]);

  useEffect(() => {
    const tempFilter = prevTabfilter ? { name: prevTabfilter } :
      { name: AlumniAvailableFilters.SUPPORT_GIVEN };
    setCurrentPage(1);
    setSelectedFilter(tempFilter);
    setPrevTabFilter('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data, isLoading, error } = useReactQuery(
    ['allAlumnis', currentPage, debouncedQuery, selectedFilter, sortColumn, sortOrder, selectedPageSize],
    {
      url: '/dashboard/fetchAlumnis',
      params: {
        query: debouncedQuery,
        pageNo: currentPage,
        pageSize: selectedPageSize,
        filters: selectedFilter?.name === AlumniAvailableFilters.SUPPORT_GIVEN ? `hasGraduateJob:"${true}"` : "",
        sortColumn: sortColumnToAlgoliaFieldMapping[sortColumn as string],
        sortOrder: sortOrder.toUpperCase(),
      },
    },
  );

  const csvdata: DashboardDataType[] = data?.completeData;
  const handleDownloadCSVClicked = async () => {
    try {
      let CSVData: CSVDataType[] = [];
      csvdata.forEach((csv: DashboardDataType) => {
        CSVData.push({
          'Email': csv.email,
          'First Name': csv.firstName,
          'Last Name': csv.lastName,
          'Company':csv.company,
          'Job Title':csv.jobTitle,
          'interactionStateInfo_PROFESSIONAL/TOTAL': csv.interactionStateInfo_PROFESSIONAL.TOTAL,
          'interactionStateInfo_PROFESSIONAL/LIVE': csv.interactionStateInfo_PROFESSIONAL.LIVE,
          'interactionStateInfo_PROFESSIONAL/COMPLETED': csv.interactionStateInfo_PROFESSIONAL.COMPLETED,
          'interactionStateInfo_PROFESSIONAL/REQUESTED': csv.interactionStateInfo_PROFESSIONAL.REQUESTED,
          'interactionStateInfo_PROFESSIONAL/REJECTED': csv.interactionStateInfo_PROFESSIONAL.DECLINED,
          'interactionStateInfo_PROFESSIONAL/EXPIRED': csv.interactionStateInfo_PROFESSIONAL.EXPIRED,
          'Students Engaged': csv.engagedUsers,
          'interactionTypeInfo_PROFESSIONAL/CAREER_CHAT': csv.interactionTypeInfo_PROFESSIONAL.CAREER_CHAT,
          'interactionTypeInfo_PROFESSIONAL/EXPERT_ADVICE': csv.interactionTypeInfo_PROFESSIONAL.EXPERT_ADVICE,
          'interactionTypeInfo_PROFESSIONAL/MOCK_INTERVIEW': csv.interactionTypeInfo_PROFESSIONAL.MOCK_INTERVIEW,
        })
      })

      setCSVData((prevData: CSVDataType[]) => {
        setTimeout(() => {
          csvButtonRef.current.link.click();
          dispatch(
            showToast({
              type: ToastType.SUCCESS,
              message: 'CSV Downloaded Successfully',
            }),
          );
        }, 500);
        return CSVData;
      });
    } catch (error) {
      console.log(error);
      dispatch(
        showToast({
          type: ToastType.ERROR,
          message: 'Error occurred while downloading csv',
        }),
      );
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, pageNo: number) => {
    setCurrentPage(pageNo);
  };

  const handlePageSizeClick = (event: SelectChangeEvent<string>) => {
    setSelectedPageSize(event.target.value as string);
  };

  const handleSorting = (column: string) => {
    if (sortColumn !== column) {
      setSortColumn(column);
      setSortOrder('asc');
    } else {
      const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
      setSortOrder(newSortOrder);
    }
  };

  if (error)
    return (
      <Typography variant='h6' className={classes.error}>
        Error fetching records
      </Typography>
    );

  return (
    <Grid container justifyContent='center' className={classes.pd5}>
      <Grid container item xs={10} justifyContent='space-between'>
        <Grid item xs={6}>
          <Filter
            filterTitle={'Filter alumni by'}
            placeholder=""
            value={selectedFilter}
            options={[{ name: AlumniAvailableFilters.SUPPORT_GIVEN }, { name: AlumniAvailableFilters.SUPPORT_RECEIVED }]}
            onChange={(filter) => setSelectedFilter(filter)}
            canClearFilters={false}
          />
          <TextField
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            value={query}
            label={`Search Alumni`}
          />
          <div className={classes.pageSizeDiv} >
            <InputLabel id="demo-simple-select-outlined-label">Number of records to display</InputLabel>
            <Select
              className={classes.pageSizeWidth}
              variant="outlined"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              defaultValue={selectedPageSize}
              onChange={handlePageSizeClick}
            >
              {[10, 25, 50, 75, 100].map((value) => (
                <MenuItem value={value}>{value}</MenuItem>
              ))}
            </Select>
          </div>
        </Grid>
        <Grid item xs={6}>
          {data?.interactionNumbers && <InteractionDetailCard interactionData={data?.interactionNumbers} />}
        </Grid>
      </Grid>
      {!isLoading && data && data.dashboardData && data.dashboardData.length > 0 && (
        <>
          <Grid container item xs={10} justifyContent='flex-end'>
            <CSVLink
              style={{ visibility: 'hidden' }}
              data={csvData}
              filename={'allAlumni.csv'}
              ref={csvButtonRef}
            >
            </CSVLink>
            <Button style={{paddingTop:'25px'}}  className={classes.csvButton} onClick={handleDownloadCSVClicked}>
              <AiOutlineDownload className={classes.downloadIcon} /> Download as CSV
            </Button>
          </Grid>
          <TableContainer
            data={data}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            selectedAlumniFilter={selectedFilter.name}
            sortOrder={sortOrder}
            sortColumn={sortColumn}
            onSort={handleSorting}
          />
        </>
      )}

      {isLoading && (
        <Grid container className={classes.tableReplaceGrid} item xs={12}>
          <Grid item xs={1}>
            <CircularProgress className='no-user-text' />
          </Grid>
        </Grid>
      )}

      {!isLoading && data && data.dashboardData && data.dashboardData.length === 0 && (
        <Grid item xs={12}>
          <Typography variant='h6' className={classes.noRecords} align='center'>
            No records present
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default AllAlumnis;
