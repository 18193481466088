import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  mt: {
    marginTop: "3em",
  },
  tableReplaceGrid: {
    height: "60vh",
    width: "100%",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
  noRecords: {
    marginTop: "6em",
  },
  error: {
    marginLeft: "45%",
    marginTop: "10%",
  },
  addEmpButtonGrid: {
    marginTop: "3em",
  },
  addEmpButton: {
    fontSize: "1.1em",
    backgroundColor: "#464FD0",
    '&:hover': {
      backgroundColor: "#3f51b5 !important",  
    },
  },
  pageSize: {
    marginTop: "2em",
  },
  pageSizeWidth: {
    width: "100%",
    marginTop: "0.5em"
  },
}));
