import DropDownItem from './DropdownItem';

type Props = {
  options: any;
  handleOptionSelect: (idx: any, itemText: any) => void;
};

const DropDown = ({ options, handleOptionSelect }: Props) => {
  return (
    <div className='dropdown'>
      {options?.map((type: any, idx: number) => (
        <div className='menu'>
          <DropDownItem
            idx={idx}
            itemText={type?.name}
            checked={type?.checked}
            handleOptionChecked={(idx: number, itemText: string) =>
              handleOptionSelect(idx, itemText)
            }
          />
        </div>
      ))}
    </div>
  );
};

export default DropDown;
