import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import GlobalStyle from 'styles/globalStyle';
import { PrivateRoute, AuthGateway, Toast } from './components';
import UpreachStaff from './containers/AdminView/ProgramCoordinator/AddProgramCoordinator';
import {
  PartincipatingUsersStepper,
  UniversitiesEngagedTable,
  EmployersEngagedTable,
  AccountCreationForm,
  MainSeaction,
  AddEmployers,
  UserProfile,
  Login,
} from './containers';
import Interaction from './components/Interaction/Interaction';
import { setActiveInteractionId } from 'redux/interactions/slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveInteractionId } from 'redux/interactions/selectors';
import Modal from './components/Interaction/InteractionModal';
import Active from './containers/AdminView/Professionals/DeactivatedEmployees';
import AddCompany from './containers/AdminView/EmployerOverview/AddCompany';


function App() {

  const dispatch = useDispatch();
  const activeInteractionId = useSelector(selectActiveInteractionId);

  return (
    <div className='App'>
      <GlobalStyle />
      <Modal
        open={activeInteractionId ? true : false}
        fullScreen
        handleClose={() => {
          dispatch(setActiveInteractionId(null));
        }}
        title=""
      >
        <Interaction />
      </Modal>
      <BrowserRouter>
        <AuthGateway>
          <Switch>
            <Route exact path='/login' component={Login} />
            <Route path='/hsJ9yEzeXj' component={AccountCreationForm} />
            <PrivateRoute path='/home' component={MainSeaction} />
            <PrivateRoute path='/participatingUsers/:type' component={PartincipatingUsersStepper} />
            <PrivateRoute path='/upreachStaff' component={UpreachStaff} />
            <PrivateRoute path='/universitiesEngaged' component={UniversitiesEngagedTable} />
            <PrivateRoute path='/employersEngaged' component={EmployersEngagedTable} />
            <PrivateRoute path='/users/:id' component={UserProfile} />
            <PrivateRoute path='/add/employer' component={AddEmployers} />
            <PrivateRoute path='/add/newemployer' component={AddCompany} />
            <PrivateRoute path='/activate/' component={Active} />
            <Redirect to='/home' />
          </Switch>
        </AuthGateway>
      </BrowserRouter>
      <Toast />
    </div>
  );
}

export default App;
