import { ToastType } from 'app/components/Toast/slice';
import { AxiosError } from 'axios';
import getAxiosInstance from 'utils/axios';

export const PendingProfessionalService = {
  deletePendingProfessionals: async (professionalId: string) => {
    try {
      const axios = await getAxiosInstance();
      const { data } = await axios.get('/dashboard/deletePendingProfessionals', {
        params: {
          professionalId,
        },
      });
      return {
        valid: true,
        message: data.message,
        toastMessage: {
          type: ToastType.SUCCESS,
          message: data.message,
        },
      };
    } catch (error) {
      console.error(error);
      return {
        valid: false,
        toastMessage: {
          type: ToastType.ERROR,
          message: (error as AxiosError)?.response?.data?.message,
        },
      };
    }
  },
  fetchPendingProfessionals: async (query: string, pageNo: number, companyName: string, selectedPageSize: string) => {
    try {
      const axios = await getAxiosInstance();
      const { data } = await axios.get('/dashboard/fetchEmployees', {
        params: {
          query,
          pageNo,
          pageSize: selectedPageSize,
          filters: `company.name:"${companyName}"`,
          status: 'pending',
        },
      });
      return {
        valid: true,
        data,
      };
    } catch (error) {
      console.log(error);
      if (error as AxiosError) {
        return {
          valid: false,
          message: (error as AxiosError)?.response?.data?.message,
        };
      }
    }
  },
};
