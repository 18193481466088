import { AppBar, Button, Toolbar, Typography, Avatar } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import avatar from "../../../assets/avatar.svg";
import { selectActiveInteractionId, selectInteractions } from "redux/interactions/selectors";
import { Interaction, User } from "types";
import { selectUser } from "redux/auth/selectors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
      backgroundColor: "#ffffff",
      boxShadow: "unset",
      color: "#464FD0",
      textDecoration: "underline",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    threadModal: {
      maxWidth: "1200px",
      marginLeft: "auto",
    },
    textCapitalize: {
      textTransform: "capitalize",
      position: "relative",
      top: "-20px",
      right: "20px",
    },
    divider: {
      maxWidth: "95%",
      marginLeft: "5%",
      marginRight: "5%",
    },
  })
);

type Props = {
  open: boolean;
  handleClose: any;
  title: string;
  children: any;
  fullScreen?: boolean;
};

const Modal = ({
  title,
  open,
  handleClose,
  children,
  fullScreen = false,
}: Props) => {
  const classes = useStyles();
  const [participant, setParticipant] = useState<any>();
  const activeInteractionId = useSelector(selectActiveInteractionId);
  const [activeInteraction, setActiveInteraction] = useState<any>();
  const interactions = useSelector(selectInteractions);

  useEffect(() => {
    setActiveInteraction(
      interactions
        ?.filter(
          (interaction: Interaction) => interaction.id === activeInteractionId
        )
        .pop()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInteractionId]);

  const user: User | null = useSelector(selectUser);

  useEffect(() => {
    if (user) setParticipant(activeInteraction?.connectionProfile[user.id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInteraction]);

  if (!user) return null;

  return (
    <Dialog
      fullScreen={fullScreen}
      className={`interaction-modal ${classes.threadModal}`}
      open={open}
      onClose={handleClose}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <div className="flex-column profile-section">
            <div className="d-flex">
              <div>
                <a className="redirect-to" href={`/users/${participant?.id}`}>
                  <Avatar className="profile-avatar"
                    src={participant?.profilePicture || avatar}
                  />
                </a>
              </div>
              <div>
                <a className="redirect-to" href={`/users/${participant?.id}`}>
                  <p className="body-17 mb-0 color-black text-decoration-none participant-details">
                    {participant?.firstName}
                  </p>
                </a>
              </div>
            </div>
            <p className="body-18 font-weight-bolder color-black text-decoration-none">
              {activeInteraction?.type?.toLowerCase().replaceAll("_", " ")}
            </p>
          </div>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <Button
            color="inherit"
            className={classes.textCapitalize}
            onClick={handleClose}
          >
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <Divider className={classes.divider} />
      <ModalBody>{children}</ModalBody>
    </Dialog>
  );
};

const ModalBody = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export default Modal;
