import { ReactNode } from 'react';
import MaterialTable from '@mui/material/Table';
import MaterialTableBody from '@mui/material/TableBody';
import MaterialTableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';

type PropsType = {
  tableHeader: ReactNode;
  tableBody: ReactNode;
};

function Table({ tableHeader, tableBody }: PropsType) {
  const classes: any = useStyles();
  return (
    <MaterialTableContainer component={Paper}>
      <MaterialTable aria-label='simple table'>
        <TableHead className={classes.tableHead}>{tableHeader}</TableHead>
        <MaterialTableBody className={classes.tableBody}>{tableBody}</MaterialTableBody>
      </MaterialTable>
    </MaterialTableContainer>
  );
}

export default Table;

export const useStyles = makeStyles(() => ({
  tableBody: {
    '& .MuiTableCell-body': {
      textAlign: 'center',
    },
  },

  tableHead: {
    backgroundColor: '#DEE1FF',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
}));
