import { AppBar, Tab, Tabs } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

type TabPanelProps = {
  children?: React.ReactNode;
  value: number;
  handleChange: (event: React.ChangeEvent<{}>, index: number) => void;
};

const ProfessionalsStatusTabs = ({ value, children, handleChange }: TabPanelProps) => {
  const classes = useStyle();
  const a11yProps = (index: any) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  return (
    <AppBar position="static" color="default" className={classes.AppBar}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="tabs for navigation"
      >
        <Tab
          label="University Overview"
          {...a11yProps(0)}
          className={value === 0 ? classes.activeTab : classes.tab}
        />
        <Tab
          label="Employer Overview"
          {...a11yProps(1)}
          className={value === 1 ? classes.activeTab : classes.tab}
        />
        <Tab
          label="All Students"
          {...a11yProps(2)}
          className={value === 2 ? classes.activeTab : classes.tab}
        />
        <Tab
          label="All Professionals"
          {...a11yProps(2)}
          className={value === 3 ? classes.activeTab : classes.tab}
        />
        <Tab
          label="All Alumni"
          {...a11yProps(4)}
          className={value === 4 ? classes.activeTab : classes.tab}
        />
      </Tabs>
    </AppBar>
  );
};

export default ProfessionalsStatusTabs;

const useStyle = makeStyles(() => ({
  AppBar: {
    background: "none",
    boxShadow: "none",
    marginTop: "5em",
    borderBottom: "1px solid #CBCBCB",
  },
  tab: {
    color: "rgba(0, 0, 0, 1)",
    fontWeight: "bolder",
    paddingLeft: "0em",
    paddingRight: "0em",
  },
  activeTab: {
    color: "#3f51b5 !important",
    fontWeight: "bold",
    paddingLeft: "0em",
    paddingRight: "0em",
  },
}));
