import { useSelector } from "react-redux";
import { selectUser } from "redux/auth/selectors";
import { Message, User } from "types";

type Props = {
    message: Message;
};

const NoShowMessage = ({ message }: Props) => {
    const user: User | null = useSelector(selectUser);
    if (message.sender === "SYSTEM") return null;
    return (
        <div>
            <span className="body-17 font-weight-bolder color-gulf-blue">
                {user?.id === message.sender.id ? "You" : message.sender.firstName} reported no show.
            </span>
        </div>
    )
}

export default NoShowMessage;
