import { Grid } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { UserType } from 'types';
import InteractionStatsTable from '../InteractionStatsTable';

type Props = {
    user: any;
    dateIntervalFrom: any;
    data: any;
    classes: any;
    isAlumni?: boolean;
    className?: string;
}

const TableSnippet = ({ user, dateIntervalFrom, data, classes, isAlumni = false, className }: Props) => {
    return (<div className={className}>
        <Typography variant='subtitle1'>
            <strong>
                {user.roles.includes(UserType.EMPLOYER)
                    ? 'Most Active Employees' : user.roles.includes(UserType.UNIVERSITY_ADMIN) && isAlumni ? 'Most Active Alumni' : 'Most Active Students'}
            </strong>
        </Typography>
        {
            dateIntervalFrom && <Toolbar className={classes.toolbar}>
                <Grid item sm={12}>
                    <Typography variant='subtitle1' className={classes.TypographyText}>
                        {"The table below is showing all data since launch"}
                    </Typography>
                </Grid>
            </Toolbar>
        }
        <InteractionStatsTable
            firstColumnTitle={user.roles.includes(UserType.EMPLOYER) ? 'Employee' : 'Student'}
            engagedColumnTitle={
                user.roles.includes(UserType.EMPLOYER)
                    ? 'Students Engaged'
                    : 'Professionals engaged'
            }
            tableData={
                data && user.roles.includes(UserType.EMPLOYER)
                    ? data.mostActiveEmployees
                    : (user.roles.includes(UserType.UNIVERSITY_ADMIN) && isAlumni) ? data.mostActiveAlumni : data.mostActiveStudents
            }
            studentTable={false}
        />
        <Typography className={classes.linkMargins}>
            <Link
                to={
                    user.roles.includes(UserType.EMPLOYER)
                        ? '/participatingUsers/employees'
                        : (user.roles.includes(UserType.UNIVERSITY_ADMIN) && isAlumni) ? '/participatingUsers/alumni' :
                            '/participatingUsers/students'
                }
            >
                View All
            </Link>
        </Typography>
    </div>
    )
}

export default TableSnippet;
