import makeStyles from '@mui/styles/makeStyles';
export const useStyles = makeStyles(() => ({
  container: {
    marginTop: '5em',
    marginLeft: '5em',
    paddingBottom: '5em',
  },
  paddingContainer: {
    paddingLeft: '2em',
    paddingTop: '2em',
  },
  textFieldWidth: {
    width: '80%',
  },
  marginTop1: {
    marginTop: '1em',
  },
  marginTop3: {
    marginTop: '3em',
  },
  marginTop5: {
    marginTop: '5em',
  },
  buttons: {
    marginTop: '2em',
    marginBottom: '2em',
  },
  buttonMarginLeft: {
    marginLeft: '1em',
    backgroundColor: '#3f51b5 !important'
  },
  shadow: {
    boxShadow: '0px 0px 36px 13px rgba(0, 0, 0, 0.05)',
  },
  plainButtons: {
    background: 'none',
    backgroundColor: 'white !important',
    border: 'none',
    color: 'rgba(70, 79, 208, 1)',
    fontSize: '1em',
    textDecoration: 'underline',
    padding: '0em',
  },
  back: {
    textDecoration: 'none !important',
    fontWeight: 'bolder',
  },
  mt2: {
    marginTop: '2em',
  },
  mt3: {
    marginTop: '3em',
  },
  mb: {
    marginBottom: '3em',
  },
  error: {
    marginLeft: '45%',
    marginTop: '10%',
  },
  tableReplaceGrid: {
    height: '60vh',
    width: '100%',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
  addEmployerButtonGrid: {
    marginTop: '3em',
  },
  addEmployerButton: {
    fontSize: '1.1em',
    backgroundColor: '#464FD0',
  },
  marginRight: {
    marginRight: '2.5em',
  },
  uploadSection: {
    display: 'flex',
    alignItems: 'center',
  },
  chooseFileButton :{
    color: 'black',
    paddingBottom: '1px',
    paddingTop :'1px',
    paddingLeft:'10px',
    paddingRight :'10px',
    borderRadius : '5px',
    marginRight:'10px',
    backgroundColor: 'white',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)', 
    border: '1px solid #ddd', 
    cursor: 'pointer',
    textTransform:'none'
    },
    errorHighlight : {
      color: 'red',
    }
}));
