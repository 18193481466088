
import { useSelector } from "react-redux";
import { selectUser } from "redux/auth/selectors";
import { Message, User } from "types";

type Props = {
  message: Message;
};

const CallDetailsMessage = ({ message }: Props) => {
  const user: User | null = useSelector(selectUser);

  if (message.sender === "SYSTEM") return null;
  const urlWithHttp = (url: any) => !/^https?:\/\//i.test(url) ? `http://${url}` : url;
  const videoCallUrl = urlWithHttp(message.payload);
  return (
    <div>
      <span className="body-17 font-weight-bolder color-gulf-blue">
        {user?.id === message.sender.id ? "You have" : message.sender.firstName + " has"} set the video conference&nbsp;
        <a target="_blank" rel="noreferrer" href={videoCallUrl}>
          link
        </a>
      </span>
    </div>
  );
};

export default CallDetailsMessage;
