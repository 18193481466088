import { useSelector } from "react-redux";
import { selectUser } from "redux/auth/selectors";
import { Message, MessageType, User } from "types";

type Props = {
  message: Message;
  interactionType: string;
};

const AcceptDeclineMessage = ({ message, interactionType }: Props) => {
  const user: User | null = useSelector(selectUser);

  if (message.sender === "SYSTEM") return null;
  return (
    <div className="color-gulf-blue body-17 font-weight-bolder">
      <span>{user?.id === message.sender.id ? "You have" : message.sender.firstName + " has"}</span>
      <span>
        &nbsp;{message.type === MessageType.ACCEPT ? "accepted" : "declined"}
      </span>
      <span>&nbsp;the request for {interactionType.replaceAll("_", " ").toLowerCase()}</span>
    </div>
  );
};

export default AcceptDeclineMessage;
