import { useSelector } from 'react-redux';
import { selectUser } from 'redux/auth/selectors';
import { UserType } from 'types';
import { HomePage, UpreachAdminView } from '..';
import ProgramCoordinatorView from '../ProgramCoordinatorView';

const MainSeaction = () => {
  const { roles } = useSelector(selectUser);
  if(roles.includes(UserType.PROGRAM_COORDINATOR)) return <ProgramCoordinatorView />; 
  if(roles.includes(UserType.UPREACH_ADMIN)) return <UpreachAdminView />; 
  return <HomePage />;
};

export default MainSeaction;
