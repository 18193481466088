import { eachDayOfInterval, set, sub } from "date-fns";

const getInterval = (initialDateOfInteraction: any,lastdateOfInteraction: any) => {
  const startDate = set(new Date(initialDateOfInteraction), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  const endDate = set(new Date(lastdateOfInteraction), {
    hours: 23,
    minutes: 59,
    seconds: 59,
    milliseconds: 0,
  });
  return { startDate, endDate };
};
export const getLabelsFor30Days = (initialDateOfInteraction: Date, lastdateOfInteraction: Date) => {
  const { startDate, endDate } = getInterval(initialDateOfInteraction,lastdateOfInteraction);
  const dateArray = eachDayOfInterval({ start: sub(startDate, { days: 1 }), end: endDate });  
  const dates = dateArray.map((date) => {
    const dateString = date.toDateString();
    const dateArray = dateString.split(" ");
    return `${dateArray[2]} ${dateArray[1]}`;
  });
  return dates;
};
