import { useRef } from 'react';
import { Button, Grid } from '@mui/material';
import Select, { ActionMeta } from 'react-select';
import { StyledWrapper } from './styles';

type PropsType = {
  value: { name: string };
  options: { name: string }[];
  onChange: (
    selectedOption: { name: string } | null,
    actionMeta: ActionMeta<{ name: string }>,
  ) => void;
  onClear?: any;
  placeholder: string;
  filterTitle: string;
  canClearFilters?: boolean;
};

const Filter = ({ filterTitle, placeholder, value, options, onChange, onClear, canClearFilters = true }: PropsType) => {
  const selectRef = useRef<any>();
  var sortedOptions = options.sort((a, b) => a.name.localeCompare(b.name));
  return (
    <StyledWrapper>
      <Grid className='filter-section filter-section-desktop' item xs={12}>
        <div className='d-flex'>
          <h3  className='filter-students-by'>{filterTitle}</h3>
          <Select
            value={value}
            ref={selectRef}
            placeholder={placeholder}
            options={sortedOptions}
            getOptionValue={(option: any) => option.name}
            getOptionLabel={(option: any) => option.name}
            onChange={onChange}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            className='filter-dropdown'
          />
          {canClearFilters &&
            <Button
              color='primary'
              className='clear-filter'
              onClick={() => {
                onClear();
                selectRef.current.select.clearValue();
              }}
            >
              Clear Filters
            </Button>}
        </div>
      </Grid>
    </StyledWrapper>
  );
};

export default Filter;
