import { Grid } from "@mui/material";
import { format, parseISO } from "date-fns";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { selectUser } from "redux/auth/selectors";
import { Message, User } from "types";


type Props = {
  message: Message;
};

const SuggestTimesMessage = ({ message }: Props) => {
  const user: User | null = useSelector(selectUser);

  if (message.sender === "SYSTEM") return null;
  return (
    <Wrapper>
      <p className="color-gulf-blue body-17 font-weight-bolder">{user?.id === message.sender.id ? "You have" : message.sender.firstName + " has"} suggested the following times: </p>
      <Grid className="timing-container" spacing={2} container>
        {message.payload &&
          message.payload.map((time: any) => (
            <Grid item xs={12} sm={4}>
              <Grid item lg={12} className={`timing-suggested font-weight-normal body-15`}>{format(parseISO(time), 'do LLL yyyy - hh:mm 	a')}</Grid>
            </Grid>
          ))}
      </Grid>
    </Wrapper>
  );
};

export default SuggestTimesMessage;

const Wrapper = styled.div`
    .timing-suggested {
      background: rgba(158, 165, 238, 0.1);
      border: 1px solid rgba(158, 165, 238, 0.2);
      box-sizing: border-box;
      border-radius: 5px;
      padding: 10px;
    }
`;
