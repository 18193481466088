import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { initialState } from './slice';

const authSlice = (state: RootState) => state.auth || initialState;

export const selectAuthUser = createSelector([authSlice], (state) => state?.authUser);
export const selectUser = createSelector([authSlice], (state) => state.user);

export const selectIsDashboardLoading = createSelector(
  [authSlice],
  (state) => state.isDashboardLoading,
);

export const selectRegistrationFormWorking = createSelector(
  [authSlice],
  (state) => state.registrationFormWorking,
);

export const selectOnLoginRedirectRoute = createSelector(
  [authSlice],
  (state) => state.onLoginRedirectRoute,
);
