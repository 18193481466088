import styled from "styled-components";
import { Box } from "@mui/material";
import videoCall from "assets/comment.svg";
import comment from "assets/comment.svg";
import { InteractionType } from "types";

const interactions: any = {
  [InteractionType.EXPERT_ADVICE]: {
    buttonLabel: "Expert Advice",
    backgroundColor: "background-sea-blue",
    logo: comment,
  },
  [InteractionType.CAREER_CHAT]: {
    buttonLabel: "Career Chat",
    backgroundColor: "background-persian-green",
    logo: videoCall,
  },
  [InteractionType.MOCK_INTERVIEW]: {
    buttonLabel: "Mock Interview",
    backgroundColor: "background-ceru",
    logo: videoCall,
  },
  [InteractionType.MENTORING]: {
    buttonLabel: "Request Mentoring",
    backgroundColor: "background-gulf-blue",
    logo: videoCall,
  },
};

type Props = {
  profile: any;
};

const InitiateInteraction = ({ profile }: Props) => {
  return (
    <>
      <StyledWrapper>
        <h3 className=" text-uppercase font-weight-bold body-13 click-here">CLICK HERE FOR:</h3>
        {profile?.participatingInteractionTypes?.map((interaction: any) => (
          <>
            {interaction !== InteractionType.MENTORING && (
              <Box
                my={2}
                justifyContent="space-between"
                className={`interaction-button border-radius-5 ${interactions[interaction].backgroundColor}`}
              >
                <Box>
                  <p className="interaction-title text-uppercase">
                    {" "}
                    {interactions[interaction].buttonLabel}{" "}
                  </p>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center">
                  <img src={interactions[interaction].logo} alt="" />
                </Box>
              </Box>
            )}
          </>
        ))}
      </StyledWrapper>
    </>
  );
};

export default InitiateInteraction;

const StyledWrapper = styled.div`
  .interaction-button {
    display: flex;
    color: white;
    padding: 10px 15px;
    height: 38px;
    min-width: 110px;
    width: 100%;
    @media (max-width: 960px) {
      min-width: unset;
      width: unset;
    }
  }

  .new-interaction {
    margin: 18px 0 18px 0;
  }

  .modal-body {
    padding: 3rem;
  }

  .request-input {
    width: 100% !important;
    border: none;
  }
`;
