import { Grid, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Logo from 'assets/logo.svg';

const PreLoginHeader = () => {
  return (
    <StyledWrapper>
      <Toolbar className='toolbar background-gulf-blue'>
        <Grid item sm={12}>
          <Link to='/'>
            <img className='img-fluid' src={Logo} alt='' />
          </Link>
        </Grid>
      </Toolbar>
    </StyledWrapper>
  );
};

export default PreLoginHeader;

const StyledWrapper = styled.div`
  img {
    padding: 0.5rem;
    width: 200px;
    position: relative;
    left: 0px;
  }

  .toolbar {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;
