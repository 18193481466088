import styled from 'styled-components';

export const StyledWrapper = styled.div`
  width: 80%;
  .filter-section {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .advanced-filters {
    display: flex;
  }
  .advanced-filter-section {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .clear-filter {
    display: block;
    height: 40px;
    margin-top: 0.63em;
    margin-left: 2em;
    color:#3f51b5;
  }

  .filter-dropdown {
    width: 100%;
    max-width: 300px;
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
    top: 10px;
    .css-yk16xz-control {
      border-top: none;
      border-right: none;
      border-bottom: 2px solid #464fd0;
      border-left: none;
      border-radius: 0;
      background-color: #f6faff;
    }

    svg {
      fill: #464fd0;
    }

    .css-1wa3eu0-placeholder,
    .css-1uccc91-singleValue {
      color: #464fd0;
      font-weight: 600;
    }
  }
  .filter-students-by{
    white-space: nowrap; 
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px;
    letter-spacing: 0px;
    margin: 0;
  }
  .filter-section-mobile {
    @media (min-width: 960px) {
      display: none;
    }
  }
  .advanced-filter-section-mobile {
    @media (min-width: 960px) {
      display: none;
    }
  }

  .filter-section-desktop {
    @media (max-width: 960px) {
      display: none;
    }
  }

  .filter-dropdown-sm {
    margin: auto;
    padding: 0 20px 20px 20px;
    @media (max-width: 600px) {
      padding-left: unset;
      padding-right: unset;
      max-width: 100%;
    }
  }

  .clear-filter-sm {
    @media (min-width: 600px) {
      margin-right: 20px;
      padding-right: 20px;
      margin-left: auto;
      margin-top: 20px;
      display: block;
    }
  }

  .filter-text {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px;
    letter-spacing: 0px;
    margin: 0;
  }

  .spacing {
    margin-bottom: 60px;
  }

  .m-0 {
    margin: 0;
  }
`;
