export const sortColumnToAlgoliaFieldMapping: any = {
  Total: 'TOTAL_INTERACTIONS',
  Live: 'LIVE_INTERACTIONS',
  Completed: 'COMPLETED_INTERACTIONS',
  Requested: 'REQUESTED_INTERACTIONS',
  Rejected: 'REJECTED_INTERACTIONS',
  Expired: 'EXPIRED_INTERACTIONS',
  Career: 'CAREER_INTERACTIONS',
  Expert: 'EXPERT_INTERACTIONS',
  Mock: 'MOCK_INTERACTIONS',
  firstName: 'NAME',
  NAME: 'NAME',
  'TOTAL INTERACTIONS': 'TOTAL_INTERACTIONS',
  'DATE REGISTERED': 'CREATEDAT',
  'NO. OF STUDENTS': 'STUDENTS',
  'NO. OF PROFESSIONALS': 'PROFESSIONALS'
};
