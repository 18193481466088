import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AuthSlice = {
  authUser: AuthUser | null;
  isDashboardLoading: boolean;
  onLoginRedirectRoute: string | null;
  registrationFormWorking: boolean;
  user: any;
};

export type AuthUser = {
  id: string;
  email: string;
  emailVerified: boolean;
};

export const initialState: AuthSlice = {
  authUser: null,
  isDashboardLoading: true,
  onLoginRedirectRoute: null,
  registrationFormWorking: false,
  user: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthUser: (state: AuthSlice, action: PayloadAction<AuthUser | null>) => {
      state.authUser = action.payload;
    },
    setIsDashboardLoading: (state: AuthSlice, action: PayloadAction<boolean>) => {
      state.isDashboardLoading = action.payload;
    },
    setOnLoginRedirectRoute: (state: AuthSlice, action: PayloadAction<string | null>) => {
      state.onLoginRedirectRoute = action.payload;
    },
    toggleRegistrationFormWorking: (state: AuthSlice) => {
      state.registrationFormWorking = !state.registrationFormWorking;
    },
    login: (state: AuthSlice, action: PayloadAction<any>): any => {
      state.user = action.payload;
    },
    logout: (state: AuthSlice): any => {
      state.user = null;
    },
  },
});

export const {
  setAuthUser,
  setIsDashboardLoading,
  setOnLoginRedirectRoute,
  toggleRegistrationFormWorking,
  login,
  logout,
} = slice.actions;

export default slice.reducer;
